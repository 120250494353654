import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Sticky from 'react-stickynode'
import Page from '../../components/Page'
import Wrapper from '../../components/Wrapper'
import Box from '../../components/Box'
import Notification from '../../components/Notification'
import Text from '../../components/Text'
import { track as trackEvent } from '../../global/eventTracking'
import eventConstants from '../../global/eventConstants'
import { axiosMethods as axios } from '../../global/axiosHelpers'
import { setCookieForUserAuthentication } from '../../global/cookies'
import DownloadMyData from '../../components/new/SettingsModal/DownloadMyData'
import { getUser as getUserRedux } from '../../redux/slice/app.slice'

const Styled = styled.div`
  font-size: 18px;

  .card-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold !important;
  }

  .card-subtitle {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    padding-top: 5px;
    color: #000 !important;
  }

  .card-text {
    padding: 5px;
    text-align: center;
  }

  .card {
    border: none;
    margin: 30px auto;
    width: 90%;
  }

  .manage-data .card-subtitle {
    width: 90%;
    margin: auto;
  }

  .manage-data .card-subtitle a {
    text-decoration: none;
    color: #00bcbb;
  }

  .manage-data label {
    width: 100%;
    text-align: start;
    font-size: 16px;
    font-weight: bold;
  }

  .manage-data .form-check-input:checked {
    background-color: #4ab9ba;
    border: 1px solid rgb(0, 0, 0, 0.25);
  }

  .manage-data .form-check {
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
  }

  .delivery-check label {
    width: 100%;
    text-align: start;
    font-size: 16px;
    font-weight: normal;
  }

  .delivery-check .form-check {
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
  }

  .delivery-check .selected label {
    font-weight: bold;
  }

  .delivery-check .selected input {
    background-image: none;
    background: #4ab9ba;
    border: none;
    font-weight: bold;
  }

  tbody > div {
    height: 90px !important;
  }

  .accordion-body > div {
    max-height: none !important;
    overflow-y: auto;
  }

  tbody b::before {
    content: ' ';
    display: block;
  }
`

const track = (eventName, eventProps) => {
  trackEvent(`ManageData: ${eventName}`, {
    ...eventProps,
    Page: eventConstants.manageData.pageName
  })
}

const renderNonWhitelistedContent = () => {
  return (
    <Page title="Data">
      <Box position="relative" zIndex={1}>
        <Sticky>
          <Notification />
        </Sticky>
      </Box>
      <Wrapper py={[5, 6]} maxWidth={4}>
        <Box>
          <Text fontSize={['18px']}>
            Data settings are not available at this moment.
            <a
              href="https://support.unroll.me/hc/en-us/requests/new"
              target="_blank"
              rel="noopener noreferrer">
              Contact support
            </a>
          </Text>
        </Box>
      </Wrapper>
    </Page>
  )
}

const ManageData = () => {
  const user = useSelector(state => state.app.user)

  const isCCPAWhitelisted = true
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()

  const loadUser = async () => {
    const user = await axios.get(
      `${process.env.REACT_APP_API}/users/me`,
      null,
      {
        Authorization: `Bearer ${searchParams.get('user_token')}`
      }
    )
    dispatch(getUserRedux({ user: user.data }))
  }

  useEffect(() => {
    setCookieForUserAuthentication(searchParams.get('user_token'))
    window.scrollTo(0, 0)
    loadUser()
  }, [])

  return !isCCPAWhitelisted ? (
    renderNonWhitelistedContent()
  ) : (
    <Styled>
      <Card className="manage-data">
        <Card.Body>
          <Card.Title>Manage My Data</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            Your data makes Unroll.Me work better for you and supports our
            measurement panel. We respect and acknowledge your right to privacy.
            You have full control of your own data here.{' '}
            <a
              href="https://unroll.me/your-data/"
              target="_blank"
              rel="noreferrer">
              Learn more
            </a>
          </Card.Subtitle>
          <Card.Text>
            <DownloadMyData isOpen={true} />
          </Card.Text>
        </Card.Body>
      </Card>
    </Styled>
  )
}

export default ManageData
