import React, { Fragment, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import map from 'lodash/map'
import styled from 'styled-components'
import { get } from 'lodash'
import Button from '../../components/Button'
import Box from '../../components/Box'
import { providerData } from '../../global/config'
import loginMiddleware from '../../global/loginMiddleware'
import { getCookie, setCookieForUserAuthentication } from '../../global/cookies'

const SignupForm = props => {
  const [showModal, setShowModal] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState(false)
  const [specifiedProvider] = useState(
    props.parsedSearch ? props.parsedSearch.provider : null
  )
  const [searchParams] = useSearchParams()

  const location = useLocation()
  const regularFlow = selectedProvider => {
    // const {
    //   location: {search},
    //   track
    // } = props

    // const {selectedProvider} = this.state
    // const parsedSearch = parse(search)
    // track(eventNames.tosModal.clickedAccept)
    // const selectedProvider = "yahoo";
    const isReauthenticating = location.pathname.includes('/reauthenticate')
    window.location = loginMiddleware(
      selectedProvider,
      searchParams.get('redirect') || '',
      null,
      null,
      isReauthenticating
    )
  }

  const multiAccountFlow = selectedProvider => {
    const decodedAccounts =
      selectedProvider === getCookie('activeAccount') &&
      JSON.parse(window.atob(getCookie('activeAccount')))

    if (decodedAccounts && decodedAccounts[searchParams.get('userId')]) {
      setCookieForUserAuthentication(
        decodedAccounts[searchParams.get('userId')]
      )
      window.location = location.pathname
    } else {
      window.location = loginMiddleware(
        selectedProvider,
        searchParams.get('redirect')
          ? searchParams.get('redirect')
          : window.location.pathname,
        searchParams.get('email'),
        null,
        false
      )
    }
  }

  const providerSelected = event => {
    // const {track} = this.props

    const selectedProvider = get(
      event,
      'target.dataset.provider',
      ''
    ).toLowerCase()

    // if (selectedProvider) {
    //   track(eventNames.common.providerSelected[selectedProvider], {
    //     'Selected Provider': get(
    //       providerData[selectedProvider],
    //       'name',
    //       selectedProvider
    //     )
    //   })
    // }
    setSelectedProvider(selectedProvider)
    // setShowModal(!!selectedProvider);
  }

  const closeModal = () => {
    // const {track} = this.props
    // track(eventNames.tosModal.clickedCancel)
    setShowModal(false)
    setSelectedProvider(null)
  }

  const {
    login,
    multiAccountScreen,
    parsedSearch,
    track,
    redirectProvider
  } = props
  if (redirectProvider && !selectedProvider) {
    setSelectedProvider(redirectProvider)
    setShowModal(true)
  }
  let providersToBeRendered = providerData
  if (specifiedProvider) {
    providersToBeRendered = {
      [specifiedProvider]: providerData[specifiedProvider]
    }
  }

  return (
    <Fragment>
      {map(providersToBeRendered, provider => (
        <ProviderButton
          data-provider={provider.name}
          // onClick={this.providerSelected}
          onClick={event => {
            const selectedProvider = get(
              event,
              'target.dataset.provider',
              ''
            ).toLowerCase()
            providerSelected(event)
            multiAccountScreen
              ? multiAccountFlow(selectedProvider)
              : regularFlow(selectedProvider)
          }}
          display="block"
          width="100%"
          key={provider.type}
          mb={4}
          pl="60px"
          pr="0"
          textAlign="left"
          color="white"
          fontSize={3}
          lineHeight="3.2"
          bg={provider.color}>
          <Box
            width="44px"
            height="44px"
            bg="white"
            position="absolute"
            left="0"
            p="10px"
            borderRadius="2px">
            {provider.logo}
          </Box>
          {!specifiedProvider
            ? `${login ? provider.loginLabel : provider.label}`
            : parsedSearch.email}
        </ProviderButton>
      ))}
      {/*{showModal && (*/}
      {/*  <ModalRoute*/}
      {/*    component={() => (*/}
      {/*      <TermsAndConditionsModal*/}
      {/*        disableBackgroundClick*/}
      {/*        apiToken={process.env.REACT_APP_UM_ACCESS_TOKEN}*/}
      {/*        closeModal={this.closeModal}*/}
      {/*        track={track}*/}
      {/*        onAccept={*/}
      {/*          multiAccountScreen ? this.multiAccountFlow : this.regularFlow*/}
      {/*        }*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  />*/}
      {/*)}*/}
    </Fragment>
  )
}

const ProviderButton = styled(Button)`
  &:last-child {
    margin-bottom: 0;
  }
`

export default SignupForm
