import React from 'react'
import { NavDropdown, Spinner } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAuth } from '../../../hook/useAuth'
import { MenuItem } from './styled'

const ItemStyle = {
  animation: 'fadeIn 1.5s'
}

const SelectMailbox = ({ setShowAddMailbox }) => {
  const { switchUser, logoutCurrentUser, isLoadingUsers } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const users = useSelector(state => state.app.users)
  const selectedUser = useSelector(state => state.app.user)

  return (
    <>
      {isLoadingUsers ? (
        <NavDropdown.Item
          style={{
            justifyContent: 'center',
            backgroundColor: '#404040',
            animation: 'fadeIn 0.5s'
          }}
          disabled>
          Loading users...{' '}
          <Spinner
            style={{ marginLeft: 10, fontSize: 12 }}
            animation="border"
          />
        </NavDropdown.Item>
      ) : (
        users.map(
          user =>
            user.email !== selectedUser.email && (
              <NavDropdown.Item
                style={ItemStyle}
                onClick={() => {
                  switchUser(user)
                  searchParams.delete('subscriptionId')
                  setSearchParams(searchParams)
                }}
                key={user.email}>
                <MenuItem>
                  <>{user.email} </>
                  {user.scan_status === 'in_progress' && (
                    <Spinner
                      style={{ marginLeft: 10, fontSize: 10 }}
                      animation="border"
                    />
                  )}
                </MenuItem>
              </NavDropdown.Item>
            )
        )
      )}
      {users.length > 1 && (
        <NavDropdown.Divider
          style={{ marginBottom: '1px', marginTop: '1px' }}
        />
      )}
      <NavDropdown.Item
        style={ItemStyle}
        disabled={isLoadingUsers}
        className="add-mailbox"
        // onClick={() => (window.location = '/adduser')}>
        onClick={e => {
          setShowAddMailbox(true)
          e.stopPropagation()
        }}>
        + Add Mailbox
      </NavDropdown.Item>
    </>
  )
}

export default SelectMailbox
