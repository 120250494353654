import React, { useEffect, useMemo, useState } from 'react'
import { Accordion, Button } from 'react-bootstrap'
import styled from 'styled-components'
import moment from 'moment/moment'

import MyDataTable from './MyDataTable'
import {
  useCreateArchiveMutation,
  useGetArchivesQuery
} from '../../../redux/Api/ArchiveApi'
import { useArchives } from '../../../contexts/Archive'

const Styled = styled.div`
  padding-bottom: 20px;
  margin-bottom: 0px;
  border-bottom: 1px solid rgb(0, 0, 0, 0.1);

  .accordion-header button {
    font-size: 16px;
    font-weight: bold;
    background: white;
    color: black;
    box-shadow: none;
    padding: 25px 10px 5px 0;
  }

  .accordion-header button:after {
    width: 20px;
    height: 20px;
    background-size: 20px;
    background-image: var(--bs-accordion-btn-icon) !important;
  }

  .accordion-item {
    border: none;
  }

  .accordion-body {
    padding: 10px 0;
  }

  .accordion-body p {
    opacity: 0.75;
    line-height: 1.25;
  }

  .create-file-btn {
    margin-top: 35px;
    width: 100%;
    height: 35px;
    font-size: 16px;
    font-weight: bold;
    background-color: #3cc2c8;
    border-radius: 17px;
    border: none;
  }

  .create-file-btn.disabled {
    background-color: rgb(0, 0, 0, 0.4);
  }

  .create-file-btn:hover {
    background-color: #3cc2c8;
    opacity: 0.7;
  }
`

const DownloadMyData = ({ isOpen, handleShowProceedAccountModal }) => {
  const {
    handleCreateArchive,
    archives,
    refetchArchives,
    btnDisabled
  } = useArchives()

  useEffect(() => {
    refetchArchives()
  }, [])

  return (
    <Styled>
      <Accordion defaultActiveKey={isOpen ? '0' : ''}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Download My Data</Accordion.Header>
          <Accordion.Body>
            <p className="text-start">
              You can download a copy of your Unroll.Me data at any time. Once
              you request to download your data, we will create a zip file with
              an archive of your information. Please give us up to 10 days to
              prepare your file. Once your file is ready, you may download it
              here. It will be available for 10 days.
            </p>
            <MyDataTable archives={archives} />
            <Button
              disabled={!!btnDisabled}
              className={`create-file-btn ${btnDisabled && 'disabled'} `}
              onClick={handleShowProceedAccountModal}>
              Create File
            </Button>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Styled>
  )
}

export default DownloadMyData
