import React, { PureComponent } from 'react'
import LazyLoad from 'vanilla-lazyload/dist/lazyload'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import Box from '../Box'

// Only initialize it one time for the entire application
if (!document.lazyLoadInstance) {
  document.lazyLoadInstance = new LazyLoad({
    element_selector: '.lazy'
  })
}

class Image extends PureComponent {
  // Update lazyLoad after first rendering of every image
  componentDidMount() {
    document.lazyLoadInstance.update()
  }

  // Update lazyLoad after rerendering of every image
  componentDidUpdate() {
    document.lazyLoadInstance.update()
  }

  // Just render the image with data-src
  render() {
    const {
      alt,
      src,
      srcset,
      sizes,
      width,
      height,
      isLoading,
      ...props
    } = this.props
    return (
      <ImageWrapper
        as="img"
        isLoading
        alt={alt}
        className="lazy"
        src={src}
        data-src={src}
        width={width || '100%'}
        height={height || 'auto'}
        {...props}
      />
    )
  }
}

const ImageWrapper = styled(Box)`
  object-fit: ${props => props.objectFit || 'fill'};
  &:not([src]) {
    visibility: hidden;
  }
`

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  sizes: PropTypes.string,
  srcset: PropTypes.string
}

export default Image
