import React, { useState } from 'react'
import { Nav } from 'react-bootstrap'
import styled from 'styled-components'
import SideBarMenuItem from './SideBarMenuItem'
import ToggleButton from './ToggleButton'
import { ReactComponent as InboxIcon } from './images/inboxIcon.svg'
import { ReactComponent as RollupIcon } from './images/rollupIcon.svg'
import { ReactComponent as SubscriptionsIcon } from './images/subscriptionsIcon.svg'

const sidebarData = [
  {
    name: 'Inbox',
    type: 'inbox',
    icon: <InboxIcon />
  },
  {
    name: 'Subscriptions',
    type: 'subscriptions',
    icon: <SubscriptionsIcon />
  },
  {
    name: 'Rollup',
    type: 'rolledup',
    icon: <RollupIcon />
  }
]

const Styled = styled.div`
  width: ${props => (props.collapsed ? 50 : 200)}px;
  height: calc(100vh - 100px) !important;
  margin-left: 25px;
  color: rgb(0, 0, 0, 0.25);
  min-height: 280px;
  height: 100%;
  -webkit-transition: 300ms linear;
  -moz-transition: 300ms linear;
  -o-transition: 300ms linear;
  -ms-transition: 300ms linear;
  transition: 300ms linear;
  .nav-item {
    padding-top: 40px !important;
    font-size: 18px;
    font-weight: bold;
    color: #000;
    display: flex;
  }

  .toggle-button {
    margin-top: 40px !important;
    text-align: center;
  }
`

const CollapseButton = styled.div``

const SideBar = () => {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <Styled collapsed={collapsed}>
      <Nav className="col-md-12 d-block" activeKey="/home">
        {sidebarData.map(entry => (
          <SideBarMenuItem key={entry.name} {...entry} collapsed={collapsed} />
        ))}
        <ToggleButton collapsed={collapsed} setCollapsed={setCollapsed} />
      </Nav>
    </Styled>
  )
}

export default SideBar
