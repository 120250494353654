import React, { Fragment } from 'react'
import { defineMessages } from 'react-intl.macro'
import Text from '../../components/Text'
import Button from '../../components/Button'
import Box from '../../components/Box'
import { formatMessages } from '../../i18n/utils'

export const messageDescriptors = defineMessages({
  useGooglePswd: {
    id: 'containers.GoogleSignup.CreatePassword.useGooglePswd',
    defaultMessage: 'Use Google App Password to Log In'
  },
  createAppPswd: {
    id: 'containers.GoogleSignup.CreatePassword.createAppPswd',
    defaultMessage: 'Create An App Password'
  },
  iHavePswd: {
    id: 'containers.GoogleSignup.CreatePassword.iHavePswd',
    defaultMessage: 'I Have An App Password'
  }
})

const CreatePasswordStep = ({ updateStep }) => {
  const messages = formatMessages(messageDescriptors)
  return (
    <Fragment>
      <Text
        as="h1"
        fontSize={['4.5vh', '5vh']}
        lineHeight="1.5"
        mt={['30px', '30px', '125px']}>
        {messages.useGooglePswd}
      </Text>
      <Box mt={['30px', '50px']}>
        <Button
          width={['95%', '375px']}
          positive
          pt="10px"
          pb="10px"
          borderRadius="50px"
          fontSize={['16px', '18px']}
          onClick={() => updateStep('/appPassword/login')}>
          {messages.createAppPswd}
        </Button>
      </Box>
      <Box mt="15px">
        <Button
          width={['95%', '375px']}
          pt="10px"
          pb="10px"
          borderRadius="50px"
          fontSize={['16px', '18px']}
          onClick={() => updateStep('/appPassword/connect')}>
          {messages.iHavePswd}
        </Button>
      </Box>
    </Fragment>
  )
}

export default CreatePasswordStep
