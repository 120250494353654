import axios from 'axios'
import {
  getCookie,
  getCookieForUserAuthentication,
  eraseCookieForUserAuthentication
} from './cookies'

const getRedirectPath = () => {
  const path = window.location.pathname
  if (/oauth-result/.test(path)) {
    return ''
  }

  return path.replace('/a/', '/') + window.location.search
}

// TODO: pass params as object
export function apiCaller(type = 'get', path, params, headers) {
  return new Promise((resolve, reject) => {
    const token = getCookie('tempAuthToken') || getCookieForUserAuthentication()

    axios
      .request({
        url: path,
        method: type,
        headers: {
          Authorization: `Bearer ${token}`,
          ...headers
        },
        data: params
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        error.code = 'REAUTH'
        reject(error)
      })
  })
}

export const axiosMethods = {
  ...axios,
  get: function(...args) {
    return apiCaller('get', ...args)
  },
  post: function(...args) {
    return apiCaller('post', ...args)
  },
  patch: function(...args) {
    return apiCaller('patch', ...args)
  },
  delete: function(...args) {
    return apiCaller('delete', ...args)
  },
  head: function(...args) {
    return apiCaller('head', ...args)
  }
}
