export const providers = {
  google: ['gmail.com', 'googlemail.com'],
  yahoo: [
    'yahoo.com.ar',
    'yahoo.com.au',
    'yahoo.at',
    'yahoo.fr',
    'yahoo.nl',
    'yahoo.com.br',
    'yahoo.ca',
    'yahoo.com.cn',
    'yahoo.cn',
    'yahoo.cz',
    'yahoo.dk',
    'yahoo.fi',
    'yahoo.fr',
    'yahoo.de',
    'yahoo.gr',
    'yahoo.com.hk',
    'yahoo.in',
    'yahoo.co.id',
    'yahoo.ie',
    'yahoo.co.il',
    'yahoo.it',
    'yahoo.co.jp',
    'yahoo.co.kr',
    'yahoo.com.my',
    'yahoo.com.mx',
    'yahoo.ae',
    'yahoo.nl',
    'yahoo.co.nz',
    'yahoo.no',
    'yahoo.com.ph',
    'yahoo.pl',
    'yahoo.pt',
    'yahoo.ro',
    'yahoo.ru',
    'yahoo.com.sg',
    'yahoo.co.za',
    'yahoo.es',
    'yahoo.se',
    'yahoo.com.tw',
    'yahoo.co.th',
    'yahoo.com.tr',
    'yahoo.co.uk',
    'yahoo.com.vn',
    'yahoo.com',
    'yahoo.co.in',
    'yahoo.be',
    'y7mail.com',
    'ymail.com',
    'rocketmail.com',
    'sbcglobal.net',
    'swbell.net',
    'att.com'
  ],
  outlook: [
    'outlook.com',
    'hotmail.com',
    'live.com',
    'msn.com',
    'hotmail.co.uk',
    'hotmail.fr',
    'live.co.uk',
    'hotmail.it',
    'comcast.net',
    'live.com.au',
    'live.fr',
    'outlook.fr',
    'live.ca',
    'hotmail.es',
    'hotmail.ca',
    'live.nl',
    'live.it',
    'outlook.es',
    'outlook.it',
    'hotmail.de',
    'outlook.sa',
    'outlook.com.au',
    'hotmail.com.au'
  ],
  aol: [
    'aol.com',
    'aim.com',
    'aol.co.uk',
    'aim.co.uk',
    'aol.fr',
    'aol.fr',
    'aol.de',
    'aol.pl',
    'aol.in',
    'aol.it',
    'aol.es',
    'aol.com.au'
  ],
  icloud: ['icloud.com', 'mac.com', 'me.com']
}
