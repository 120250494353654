import React, { useState } from 'react'
import ImapErrorModal from '../../components/new/ImapErrorModal'

function SignupErrorHandler() {
  const [showImapErrorModal, setShowImapErrorModal] = useState(true)

  const handleClosePage = () => {
    window.close()
  }

  return (
    <div onClick={handleClosePage}>
      <ImapErrorModal
        show={showImapErrorModal}
        setShow={setShowImapErrorModal}
        provider={'Outlook'}
      />
    </div>
  )
}

export default SignupErrorHandler
