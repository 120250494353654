import React from 'react'
import { NavDropdown } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { updateAddMailboxModal } from '../../../redux/slice/app.slice'
import { ReactComponent as SignupGoogleLogo } from '../../../global/signUpLogos/SignupGoogleLogo.svg'
import { ReactComponent as SignupYahooLogo } from '../../../global/signUpLogos/SignupYahooLogo.svg'
import { ReactComponent as SignupOutlookLogo } from '../../../global/signUpLogos/SignupOutlookLogo.svg'
import { ReactComponent as SignupAolLogo } from '../../../global/signUpLogos/SignupAolLogo.svg'
import { ReactComponent as SignupIcloudLogo } from '../../../global/signUpLogos/SignupIcloudLogo.svg'
import { useAuth } from '../../../hook/useAuth'

const ItemStyle = {
  animation: 'fadeIn 1.5s'
}

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  width: 100px;
  margin: auto;

  & > svg {
    width: 24px;
    height: 24px;
  }
`

const LinkWrapper = styled.a`
  color: #00bcbb;
  text-decoration: none;

  &:hover {
    color: #00cccb;
    text-decoration: none;
  }
`

const PrivacyWrapper = styled.div`
  width: 250px;
  word-break: break-word;
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
  font-size: 14px;
  padding-bottom: 10px;
`

const AddMailbox = ({ setShowAddMailbox }) => {
  const { showAddMailboxWindow } = useAuth()
  const dispatch = useDispatch()

  const onSelectMailbox = type => {
    if (['icloud', 'google'].includes(type)) {
      dispatch(updateAddMailboxModal({ show: true, type: type }))
    } else {
      showAddMailboxWindow(type)
    }
    setShowAddMailbox(false)
  }

  return (
    <>
      <NavDropdown.Item
        style={ItemStyle}
        onClick={e => {
          onSelectMailbox('google')
          // e.stopPropagation()
        }}>
        <ItemWrapper>
          <SignupGoogleLogo /> Google
        </ItemWrapper>
      </NavDropdown.Item>
      <NavDropdown.Item
        style={ItemStyle}
        onClick={e => {
          onSelectMailbox('yahoo')
          // e.stopPropagation()
        }}>
        <ItemWrapper>
          <SignupYahooLogo /> Yahoo
        </ItemWrapper>
      </NavDropdown.Item>
      <NavDropdown.Item
        style={ItemStyle}
        onClick={e => {
          onSelectMailbox('outlook')
        }}>
        <ItemWrapper>
          <SignupOutlookLogo /> Outlook
        </ItemWrapper>
      </NavDropdown.Item>
      <NavDropdown.Item
        style={ItemStyle}
        onClick={e => {
          onSelectMailbox('icloud')
        }}>
        <ItemWrapper>
          <SignupIcloudLogo /> iCloud
        </ItemWrapper>
      </NavDropdown.Item>
      <NavDropdown.Item
        style={ItemStyle}
        onClick={e => {
          onSelectMailbox('aol')
        }}>
        <ItemWrapper>
          <SignupAolLogo /> AOL
        </ItemWrapper>
      </NavDropdown.Item>
      <NavDropdown.Divider />
    </>
  )
}

export default AddMailbox
