import React, { Fragment, useState } from 'react'
import { defineMessages } from 'react-intl.macro'
import { useDispatch } from 'react-redux'
import { lighten } from 'polished'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { Tooltip, TooltipArrow, TooltipInner } from 'styled-tooltip-component'
import Text from '../../components/Text'
import Button from '../../components/Button'
import theme from '../../global/theme'
import Box from '../../components/Box'
import { providerData } from '../../global/config'
import { formatMessage, formatMessages, formatNumber } from '../../i18n/utils'
import { ReactComponent as ArrowRightLogo } from '../../components/Icon/arrowRight.svg'
import { ReactComponent as CheckmarkLogo } from '../../components/Icon/checkmark.svg'
import { updateAddMailboxModal } from '../../redux/slice/app.slice'

export const messageDescriptors = defineMessages({
  loginWithPswd: {
    id: 'containers.GoogleSignup.LoginEducation.loginWithPswd',
    defaultMessage: 'Setup Application Password'
  },
  signupDesc: {
    id: 'containers.GoogleSignup.LoginEducation.signupDesc',
    defaultMessage:
      'Now that you’ve signed-in with {provider} we’ll need to connect to your inbox. Follow the steps below to create an application password.',
    skipGroupFormatting: true
  },
  signupDescIcloud: {
    id: 'containers.GoogleSignup.LoginEducation.signupDescIcloud',
    defaultMessage:
      'To connect your inbox, follow the steps below to create an application password for your iCloud account.',
    skipGroupFormatting: true
  },
  reauthDesc: {
    id: 'containers.GoogleSignup.LoginEducation.reauthDesc',
    defaultMessage:
      'Create an application password so that we can connect to your inbox.'
  },
  stepCount: {
    id: 'containers.GoogleSignup.LoginEducation.stepCount',
    defaultMessage: 'Step {stepCount}',
    skipGroupFormatting: true
  },
  enable2StepVerifcn: {
    id: 'containers.GoogleSignup.LoginEducation.enable2StepVerifcn',
    defaultMessage: 'Enable 2-Step Verification in your {provider} Account'
  },
  enable2StepCta: {
    id: 'containers.GoogleSignup.LoginEducation.enable2StepCta',
    defaultMessage: 'Enable 2-Step'
  },
  createAppPswd: {
    id: 'containers.GoogleSignup.LoginEducation.createAppPswd',
    defaultMessage: 'Create an app password in your {provider} Account',
    skipGroupFormatting: true
  },
  createAppPswdCta: {
    id: 'containers.GoogleSignup.LoginEducation.createAppPswdCta',
    defaultMessage: 'Create App Password'
  },
  tooltipStage0: {
    id: 'containers.GoogleSignup.LoginEducation.tooltipStage0',
    defaultMessage:
      "You'll need to complete the steps above before you can continue."
  },
  tooltipStage1: {
    id: 'containers.GoogleSignup.LoginEducation.tooltipStage1',
    defaultMessage: 'Please complete Step 2 before continuing.'
  },
  setupHelpP1: {
    id: 'containers.GoogleSignup.LoginEducation.setupHelpP1',
    defaultMessage: 'Need a bit more help setting up?'
  },
  setupHelpP2: {
    id: 'containers.GoogleSignup.LoginEducation.setupHelpP2',
    defaultMessage: 'Click Here'
  },
  setupHelpP3: {
    id: 'containers.GoogleSignup.LoginEducation.setupHelpP3',
    defaultMessage: 'for step-by-step instructions'
  },
  allSet: {
    id: 'containers.GoogleSignup.LoginEducation.allSet',
    defaultMessage: 'Continue'
  },
  cancel: {
    id: 'containers.GoogleSignup.LoginEducation.cancel',
    defaultMessage: 'Cancel'
  }
})

// const track = (eventName, eventProps) => {
//   trackEvent(eventName, {
//     ...eventProps,
//     Page: eventConstants.appPasswordLogin.pageName
//   })
// }

const getSignupDesc = (
  reauth,
  provider,
  providerLabel,
  messages,
  messageDescriptors
) => {
  if (provider === 'icloud') {
    return messageDescriptors.signupDescIcloud.defaultMessage
  }
  return reauth
    ? messages.reauthDesc
    : formatMessage(messageDescriptors.signupDesc, {
        provider: providerLabel
      })
}

const LoginEducationStep = ({ setStep, provider }) => {
  const [isTwoFactorLinkClicked, setIsTwoFactorLinkClicked] = useState(false)
  const [
    isCreatePasswordLinkClicked,
    setIsCreatePasswordLinkClicked
  ] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch()

  const [showTooltip, setShowTooltip] = useState(false)
  const messages = formatMessages(messageDescriptors)
  const searchParams = parse(location.search)
  const providerConfig = providerData[provider]
  const {
    twoStepVerificationUrl,
    appPassswordUrl,
    name: providerLabel
  } = providerConfig
  let isContinueBtnDisabled = null
  let supportLink = null

  if (provider === 'google') {
    isContinueBtnDisabled =
      !isTwoFactorLinkClicked || !isCreatePasswordLinkClicked
    supportLink =
      'https://support.unroll.me/hc/en-us/articles/4423682917133-Google-App-Password-Authentication-Instructions'
  } else if (provider === 'yahoo') {
    isContinueBtnDisabled = !isCreatePasswordLinkClicked
    supportLink =
      'https://support.unroll.me/hc/en-us/articles/4423682942349-Yahoo-App-Password-Authentication-Instructions'
  } else if (provider === 'icloud') {
    isContinueBtnDisabled =
      !isTwoFactorLinkClicked || !isCreatePasswordLinkClicked
    supportLink =
      'https://support.unroll.me/hc/en-us/articles/4422749241101-iCloud-App-Password-Authentication-Instructions'
  }

  const getTooltipText = () => {
    if (!isTwoFactorLinkClicked && !isCreatePasswordLinkClicked)
      return messages.tooltipStage0
    if (!isCreatePasswordLinkClicked) return messages.tooltipStage1
  }

  return (
    <Fragment>
      <Box>
        <Box>
          <Text
            fontWeight="bold"
            fontSize={['22px', '25px', '30px', '45px']}
            width={['100%']}
            lineHeight="1.5"
            mt="20px">
            {messages.loginWithPswd}
          </Text>
          <Text
            fontSize={['15px', '15px', '16px', '18px']}
            margin="0 auto"
            width={['300px', '330px', '550px', '670px']}
            color={theme.colors.gray[2]}
            mt="10px">
            {getSignupDesc(
              searchParams.reauth,
              provider,
              providerLabel,
              messages,
              messageDescriptors
            )}
          </Text>
        </Box>
        <Box
          mt={['20px']}
          width={['250px', '280px', '340px', '370px']}
          margin="0 auto">
          {['google', 'icloud'].includes(provider) ? (
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Text fontWeight="bold">
                {formatMessage(messageDescriptors.stepCount, {
                  stepCount: formatNumber(1)
                })}
                :
              </Text>
              <StepWrapperBox>
                <Box>
                  {formatMessage(messageDescriptors.enable2StepVerifcn, {
                    provider: providerLabel
                  })}
                  {'. '}
                  <Button
                    as="a"
                    lineHeight="1.6"
                    positive={!isTwoFactorLinkClicked}
                    href={twoStepVerificationUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      setIsTwoFactorLinkClicked(true)
                      // track(eventConstants.appPasswordLogin.twoStepClicked)
                    }}>
                    <Box display="flex" flexDirection="row">
                      {isTwoFactorLinkClicked ? (
                        <Box top="6px" right="5px" position="relative">
                          <CheckmarkLogo />
                        </Box>
                      ) : null}
                      {messages.enable2StepCta}
                      {!isTwoFactorLinkClicked ? (
                        <Box top="6px" left="5px" position="relative">
                          <ArrowRightLogo />
                        </Box>
                      ) : null}
                    </Box>
                  </Button>
                </Box>
              </StepWrapperBox>
            </Box>
          ) : null}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            mt={['20px']}>
            {['google', 'icloud'].includes(provider) ? (
              <Text fontWeight="bold">
                {formatMessage(messageDescriptors.stepCount, {
                  stepCount: formatNumber(2)
                })}
                :
              </Text>
            ) : null}
            <StepWrapperBox>
              <Text width="100%">
                {formatMessage(messageDescriptors.createAppPswd, {
                  provider: providerLabel
                })}
                {'. '}
                <Button
                  as="a"
                  lineHeight="1.6"
                  positive={!isCreatePasswordLinkClicked}
                  href={appPassswordUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    setIsCreatePasswordLinkClicked(true)
                    // track(eventConstants.appPasswordLogin.createPasswordClicked)
                  }}>
                  <Box display="flex" flexDirection="row">
                    {isTwoFactorLinkClicked ? (
                      <Box top="6px" right="5px" position="relative">
                        <CheckmarkLogo />
                      </Box>
                    ) : null}
                    {messages.createAppPswdCta}
                    {!isTwoFactorLinkClicked ? (
                      <Box top="6px" left="5px" position="relative">
                        <ArrowRightLogo />
                      </Box>
                    ) : null}
                  </Box>
                </Button>
              </Text>
            </StepWrapperBox>
          </Box>
          <Text mt="15px" fontSize="15px" textAlign="left">
            {messages.setupHelpP1}{' '}
            <a href={supportLink} target="_blank" rel="noopener noreferrer">
              {messages.setupHelpP2}
            </a>{' '}
            {messages.setupHelpP3}
          </Text>
          <Box mt="15px" display="flex" alignItems="column">
            <Button
              width={['120px', '180px', '180px', '180px']}
              pt="10px"
              pb="10px"
              fontSize={['16px', '18px']}
              onClick={() => {
                // track(eventConstants.appPasswordLogin.cancelClicked)
                dispatch(
                  updateAddMailboxModal({ show: false, type: undefined })
                )
                // setStep('login')
              }}>
              {messages.cancel}
            </Button>
            <Button
              width={['120px', '180px', '180px', '180px']}
              positive
              color={theme.colors.white}
              disabled={isContinueBtnDisabled}
              pt="10px"
              pb="10px"
              ml="20px"
              mr="20px"
              fontSize={['16px', '18px']}
              onMouseLeave={() => setShowTooltip(false)}
              onMouseEnter={() => setShowTooltip(true)}
              onClick={() => {
                // track(eventConstants.appPasswordLogin.continueClicked)
                setStep('connect')
              }}>
              {messages.allSet}
              <Tooltip
                hidden={!showTooltip || !isContinueBtnDisabled}
                style={{
                  top: '-67px',
                  width: '225px',
                  left: '50%',
                  opacity: 1,
                  transform: 'translateX(-50%)',
                  background: lighten(0.3, theme.colors.black),
                  padding: '10px 7px'
                }}
                top>
                <TooltipArrow top />
                <TooltipInner
                  top
                  style={{
                    background: lighten(0.3, theme.colors.black),
                    fontSize: '12px'
                  }}>
                  {getTooltipText()}
                </TooltipInner>
              </Tooltip>
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

const StepWrapperBox = styled(Box)`
  ${'' /* margin: auto; */}
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  ${'' /* margin-top: 20px; */}
  text-align: left;
  font-size: 14px;

  @media (min-width: 480px) {
    ${'' /* width: 400px; */}
    ${'' /* margin-top: 25px; */}
    font-size: 15px;
  }
`

// const mapStateToProps = state => ({
//   isTwoFactorLinkClicked: state.googleSignup.get('isTwoFactorLinkClicked'),
//   isCreatePasswordLinkClicked: state.googleSignup.get(
//     'isCreatePasswordLinkClicked'
//   )
// })

// const mapDispatchToProps = {
//   twoFactorLinkClicked,
//   createPasswordLinkCLicked
// }

export default LoginEducationStep
