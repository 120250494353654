import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from '../Box'
import IconData from './IconData'

const IconComponent = styled.svg.attrs({
  width: props => props.size || '16px',
  height: props => props.size || '16px',
  viewBox: props => props.viewBox || '0 0 32 32'
})`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  vertical-align: middle;
  margin-top: -3px;
  display: inline-block;

  path {
    fill: ${props => (props.fill ? props.fill : 'inherit')};
  }
`
const Svg = Box.withComponent(IconComponent)

const Icon = ({ size, type, fill, ...props }) => (
  <Svg size={size} fill={fill} {...props}>
    <path d={IconData[type]} />
  </Svg>
)

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.oneOf([11, 12, 16, 24, 32, 38, 42, 48, 64, 128])
}

Icon.defaultProps = {
  size: 16
}

export default Icon
