import React, { useCallback, useEffect, useRef, useState } from 'react'
import Image from 'react-bootstrap/Image'
import { BiArrowBack } from 'react-icons/bi'
import { EnterLoginPage } from './pages/EnterMailbox'
import { ScanningCode } from './pages/ScanningCode'
import { BackButton, Wrapper } from './styled'
import { LogoWrapper } from './pages/EnterMailbox/styled'
import { Enable2Step } from './pages/Enable2Step'
import { CreateAppPassword } from './pages/CreateAppPassword'
import { EnterAppPassword } from './pages/EnterAppPassword'
import { VerificationCode } from './pages/VerificationCode'
import { ConnectYourMailbox } from './pages/ConnectYourMailbox'

const SIGNUP_STEPS = [
  'enter_mailbox',
  'scanning_code',
  'connect_your_mailbox',
  'enable_2_step',
  'create_app_password',
  'enter_app_password'
]

const LOGIN_STEPS = ['enter_mailbox', 'enter_verification_code']

const STEP_COMPONENT_MAP = new Map([
  [SIGNUP_STEPS[0], EnterLoginPage],
  [SIGNUP_STEPS[1], ScanningCode],
  [SIGNUP_STEPS[2], ConnectYourMailbox],
  [SIGNUP_STEPS[3], Enable2Step],
  [SIGNUP_STEPS[4], CreateAppPassword],
  [SIGNUP_STEPS[5], EnterAppPassword],
  [LOGIN_STEPS[1], VerificationCode]
])

export const SignupMailbox = ({ provider, mail }) => {
  const [stepFlow, setStepFlow] = useState(SIGNUP_STEPS)
  const [currentStep, setCurrentStep] = useState(SIGNUP_STEPS[0])
  const [email, setEmail] = useState('')

  const stepFlowRef = useRef(stepFlow)
  const CurrentComponent = STEP_COMPONENT_MAP.get(currentStep)

  useEffect(() => {
    // setStepFlow(SIGNUP_STEPS);
    setCurrentStep(stepFlow[0])
  }, [provider])

  const logoUrl =
    provider === 'google'
      ? `${process.env.PUBLIC_URL}/images/new-gmail-logo.png`
      : `${process.env.PUBLIC_URL}/images/SignupIcloudLogo.svg`

  const isFirstStep = useCallback(() => {
    return stepFlowRef.current.indexOf(currentStep) === 0
  }, [currentStep])

  const isLastStep = useCallback(() => {
    return stepFlowRef.current.indexOf(currentStep) === SIGNUP_STEPS.length - 1
  }, [currentStep])

  const nextStep = () => {
    if (isLastStep()) {
      return 0
    } else {
      const nextIndex = stepFlowRef.current.indexOf(currentStep) + 1
      setCurrentStep(stepFlowRef.current[nextIndex])
    }
  }

  const previousStep = () => {
    if (isFirstStep()) {
      return 0
    } else {
      const previousIndex = stepFlowRef.current.indexOf(currentStep) - 1
      setCurrentStep(stepFlowRef.current[previousIndex])
    }
  }

  const switchToLoginFlow = isSwitch => {
    if (isSwitch) {
      stepFlowRef.current = LOGIN_STEPS
      setStepFlow(LOGIN_STEPS)
    } else {
      stepFlowRef.current = SIGNUP_STEPS
      setStepFlow(SIGNUP_STEPS)
    }
  }

  return (
    <Wrapper>
      {!isFirstStep() && (
        <BackButton onClick={previousStep}>
          <BiArrowBack />
        </BackButton>
      )}

      <LogoWrapper>
        <Image src={logoUrl} height="55px" alt="gmail logo" />
      </LogoWrapper>
      <CurrentComponent
        provider={provider}
        email={email}
        setEmail={setEmail}
        nextStep={nextStep}
        switchToLoginFlow={switchToLoginFlow}
        initialEmail={mail}
      />
    </Wrapper>
  )
}
