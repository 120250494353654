import React from 'react'
import { useSelector } from 'react-redux'
import { TbTrash } from 'react-icons/tb'
import { useNavigate } from 'react-router'
import { Button, Modal } from 'react-bootstrap'
import styled from 'styled-components'

import { deleteUser } from '../../../containers/App/AppActions'
import { useAuth } from '../../../hook/useAuth'
import { useNotifications } from '../../../contexts/Notifications'
import { CONTACT_SUPPORT_TEAM } from '../constants'

const Styled = styled.div`
  font-size: 18px;

  .modal-title {
    font-size: 22px;
    font-weight: bold;
    color: #404040;
    padding-left: 40px;
    margin: auto;
  }

  .modal-title svg {
    margin: auto;
    font-size: 28px;
  }

  .modal-header {
    padding-top: 10px;
    height: 85px;
    border-bottom: none;
  }

  .modal-header button {
    margin: 0 !important;
    margin-right: 20px !important;
    font-size: 10px;
    padding: 10px;
    border: 1px solid rgb(0, 0, 0, 0.5);
    border-radius: 50%;
  }

  .modal-body {
    color: rgb(0, 0, 0, 0.6);
    font-size: 18px;
    padding: 0px 30px 20px;
    text-align: center;
  }

  .delete-account-btn {
    font-size: 18px;
    font-weight: 700;
    padding: 10px;
    margin: 15px auto;
    width: auto;
    padding-left: 0;
    border: none;
    text-decoration: none;
    color: #c83c3c;
  }

  .delete-account-btn:hover {
    color: #c83c3c;
    opacity: 0.7;
  }
`

const DeleteAccountModal = ({ show, setShow, setShowSettings }) => {
  const user = useSelector(state => state.app.user)
  const { logoutCurrentUser } = useAuth()
  const navigate = useNavigate()
  const { notifySuccess, notifyError, notifyWarning } = useNotifications()

  const handleDeleteAccount = () => {
    deleteUser()
      .then(() => {
        notifySuccess(
          <>
            Your account <b>{user?.email}</b> has been deleted.
          </>
        )
        navigate('/app/inbox')
        logoutCurrentUser()
      })
      .catch(error => {
        notifyError(
          <>
            Error on deleting account: <b>{user?.email}</b>. Please contact{' '}
            <CONTACT_SUPPORT_TEAM />
          </>
        )
      })
    setShow(false)
  }

  const handleClose = () => {
    setShow(false)
    setShowSettings(true)
  }

  return (
    <Modal show={show} centered={true} onHide={handleClose}>
      <Styled>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>
              <TbTrash />
              Delete Account
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete your account? All your settings and
          filters for your subscriptions will be lost.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="delete-account-btn"
            onClick={handleDeleteAccount}>
            Delete My Account
          </Button>
        </Modal.Footer>
      </Styled>
    </Modal>
  )
}

export default DeleteAccountModal
