import React from 'react'
import { NavLink } from 'react-router-dom'
import { defineMessages } from 'react-intl.macro'
import Box from '../Box'
import theme from '../../global/theme'
import Text from '../Text'
import Footer from '../Footer'
import Button from '../Button'
import { track } from '../../global/eventTracking'
import Logo from '../Logo'
import { formatMessages } from '../../i18n/utils'
import eventConstants from '../../global/eventConstants'

const messageDescriptors = defineMessages({
  ohNo: {
    id: 'components.DeclinedOAuthPrompt.ohNo',
    defaultMessage: 'Oh no!'
  },
  authDeclined: {
    id: 'components.DeclinedOAuthPrompt.authDeclined',
    defaultMessage: 'It looks like you declined authorization.'
  },
  clickHereP1: {
    id: 'components.DeclinedOAuthPrompt.clickHereP1',
    defaultMessage:
      'For more info about what data we collect and how we use it, click'
  },
  clickHereP2: {
    id: 'components.DeclinedOAuthPrompt.clickHereP2',
    defaultMessage: 'here'
  },
  retrySignup: {
    id: 'components.DeclinedOAuthPrompt.retrySignup',
    defaultMessage: 'Retry Signup'
  }
})

const DeclinedOAuthPrompt = ({ provider }) => {
  track(eventConstants.oauthResult.failed, {
    Page: eventConstants.oauthResult.pageName,
    [eventConstants.oauthResult.errMsgProp]:
      eventConstants.oauthResult.declinedAuth,
    [eventConstants.common.providerProp]:
      eventConstants.common.providerLabel[provider]
  })
  const messages = formatMessages(messageDescriptors)

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      bg={theme.colors.white}>
      <Box
        py="15px"
        display="flex"
        justifyContent="center"
        borderBottom={`1px solid ${theme.colors.gray[1]}`}>
        <Logo height="45px" />
      </Box>
      <Box textAlign="center" px="5%" py="25px" flex="1 1 auto">
        <Text
          as="h1"
          pt="10px"
          fontSize={['2.2rem', '3.2rem']}
          lineHeight="1.4em">
          {messages.ohNo}{' '}
          <span role="img" aria-label="scared">
            😱
          </span>{' '}
          {messages.authDeclined}
        </Text>
        <Text
          as="p"
          fontSize="1.6rem"
          color={theme.colors.gray[2]}
          fontWeight={600}>
          {messages.clickHereP1}
          <a href="https://unroll.me/faq#data"> {messages.clickHereP2}</a>
        </Text>
        <Box py="25px">
          <Button as={NavLink} to="/signup" positive py="5px" fontSize="1.5rem">
            {messages.retrySignup}
          </Button>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default DeclinedOAuthPrompt
