import React from 'react'
import { Col, Nav, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const SideBarMenuItem = ({ collapsed, name, icon, type }) => {
  const Styled = styled.div`
    height: 72px;

    a {
      width: 100%;
      text-decoration: none;
      font-weight: bold;
    }

    .nav-link {
      color: rgb(0, 0, 0, 0.25);
    }

    .icon-item {
      font-size: 25px;
      margin-right: 15px;
      stroke-width: 0.5;
      stroke: rgb(0, 0, 0, 0.25);
      fill: rgb(0, 0, 0, 0.25);
    }

    .active-menu-item .icon-item {
      color: #4ab9ba;
      fill: #4ab9ba;
      stroke: #4ab9ba;
      stroke-width: 0.5;
    }
    .active-menu-item .text-item {
      color: #4ab9ba;
    }
  `
  const Line = styled.div`
    display: block;
    color: rgb(0, 0, 0, 0.25);
    height: 25px;

    .icon-item {
      display: inline-block;
      width: 22px;
    }
    :hover {
      opacity: 0.6;
    }

    .text-item {
      width: calc(100% - 50px);
      display: inline-block;
      text-decoration: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  `

  return (
    <Styled collapsed={collapsed}>
      <Nav.Item>
        <NavLink
          to={type}
          className={({ isActive }) => (isActive ? 'active-menu-item' : '')}>
          <Line>
            <Col className="align-items-center icon-item">{icon}</Col>
            <Col className="text-item">{name}</Col>
          </Line>
        </NavLink>
      </Nav.Item>
    </Styled>
  )
}

export default SideBarMenuItem
