import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import onClickOutside from 'react-onclickoutside'
import { defineMessages } from 'react-intl.macro'
import Icon from '../Icon'
import Box from '../Box'
import Text from '../Text'
import Button from '../Button'
import { track } from '../../global/eventTracking'
import { ReactComponent as ManageDataSvg } from '../Icon/manageData.svg'
import { formatMessages } from '../../i18n/utils'

const messageDescriptors = defineMessages({
  manageData: {
    id: 'components.MainNav.UserMenu.manageData',
    defaultMessage: 'Manage My Data'
  },
  settings: {
    id: 'components.MainNav.UserMenu.settings',
    defaultMessage: 'Settings'
  },
  logout: {
    id: 'components.MainNav.UserMenu.logout',
    defaultMessage: 'Logout'
  }
})

const UserMenu = ({ userEmail, authenticateUser, isCCPAWhitelisted }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  UserMenu.handleClickOutside = () => setIsOpen(false)
  const messages = formatMessages(messageDescriptors)

  return (
    <Box
      display="inline-block"
      borderLeft={1}
      borderColor="gray.1"
      position="relative">
      <Button
        link
        lineHeight="3"
        fontSize={[2, 3]}
        py={[2, 3]}
        pl={[3, 3, 5]}
        onClick={toggle}>
        <Text as="span" display={['none', 'none', 'none', 'inline']}>
          {userEmail}
        </Text>
        <Icon type="menu" ml={[0, 2]} />
      </Button>

      {isOpen ? (
        <Box
          position="absolute"
          bg="white"
          border={1}
          borderRadius={1}
          boxShadow={1}
          zIndex="100"
          maxWidth="170px"
          px={0}
          py={2}
          borderColor="gray.1"
          right="10px"
          top="100%"
          mt="-15px">
          <Button
            to="/settings"
            onClick={() =>
              track('main_navigation_action', { clickedOn: 'Settings' })
            }
            display="block"
            width="100%"
            lineHeight="2.8"
            px={4}
            as={NavLink}
            textAlign="left"
            link>
            <Icon type="settings" mr={2} />
            {messages.settings}
          </Button>
          {isCCPAWhitelisted ? (
            <Button
              to="/data"
              onClick={() =>
                track('main_navigation_action', { clickedOn: 'Manage My Data' })
              }
              display="block"
              width="100%"
              lineHeight="2.8"
              px={4}
              as={NavLink}
              textAlign="left"
              link>
              <ManageDataSvg
                style={{
                  display: 'inline-block',
                  'margin-top': '-3px',
                  'margin-right': '10px',
                  'vertical-align': 'middle'
                }}
              />
              {messages.manageData}
            </Button>
          ) : null}
          <Button
            onClick={() => {
              track('main_navigation_action', { clickedOn: 'Log Out' })
              authenticateUser(false)
            }}
            display="block"
            width="100%"
            lineHeight="2.8"
            px={4}
            textAlign="left"
            link>
            <Icon type="logout" mr={2} />
            {messages.logout}
          </Button>
        </Box>
      ) : null}
    </Box>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => UserMenu.handleClickOutside
}

export default onClickOutside(UserMenu, clickOutsideConfig)
