import React from 'react'
import styled from 'styled-components'
import { TbSortAscending, TbSortDescending } from 'react-icons/tb'
import { Dropdown } from 'react-bootstrap'

const ASC = 'asc'
const DESC = 'desc'
const orders = [['domain', 'Domain'], ['created_at', 'Date']]

const Styled = styled.div`
  display: block;
  text-align: end;
  height: 30px;

  font-size: 16px;
  font-weight: 500 !important;
  color: rgb(0, 0, 0, 0.3) !important;
  margin-bottom: 10px;
  margin-right: 20px;
  justify-content: flex-end;

  .dropdown .btn {
    font-size: 16px;
    font-weight: bold !important;
    color: rgb(0, 0, 0, 0.3) !important;
    padding-top: 0;
  }

  .dropdown-toggle:after {
    content: none;
  }

  .dropdown-menu {
    font-size: 16px;
    font-weight: bold !important;
    color: rgb(0, 0, 0, 0.3) !important;
  }

  .dropdown-menu .dropdown-item {
    font-size: 16px;
    font-weight: 500 !important;
    color: rgb(0, 0, 0, 0.4) !important;
  }

  .dropdown-menu .dropdown-item:active {
    color: rgb(0, 0, 0, 0.4) !important;
    background-color: rgb(0, 0, 0, 0.1) !important;
  }

  .sort-icon {
    display: inline-block;
    height: 25px;
    position: relative;
    top: 4px;
    cursor: pointer;
  }

  svg {
    font-size: 25px;
  }

  .label {
    display: inline-block;
    height: 30px;
    // border-bottom: 2px solid rgb(0, 0, 0, 0.3);
  }

  .dropdown {
    display: inline-block;
    height: 30px;
    width: 76px;
  }
`

const Sorter = ({ order, direction, setOrder, setDirection }) => {
  return (
    <Styled>
      <div className="label">Sort by:</div>
      <Dropdown>
        <Dropdown.Toggle
          variant="label"
          className="dropdown"
          id="dropdown-basic">
          {orders.find(o => o[0] === order)[1]}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {orders.map(order => (
            <Dropdown.Item key={order} onClick={() => setOrder(order[0])}>
              {order[1]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <div
        className="sort-icon"
        onClick={() => setDirection(direction === ASC ? DESC : ASC)}>
        {direction === ASC ? <TbSortDescending /> : <TbSortAscending />}
      </div>
    </Styled>
  )
}

export default Sorter
