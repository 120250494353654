import React, { createContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import {
  isAndroid,
  isDesktop,
  isIOS,
  isMobile,
  isTablet
} from 'react-device-detect'
import { useSearchParams } from 'react-router-dom'
import {
  eraseCookie,
  getCookie,
  getCookieForUserAuthentication,
  setCookie,
  setCookieForUserAuthentication
} from '../global/cookies'
import {
  getAllAccounts,
  logout,
  saveAllAccounts
} from '../containers/App/AppActions'
import { axiosMethods as axios } from '../global/axiosHelpers'
import {
  logoutUser,
  updateAddMailboxModal,
  updateUser,
  updateUserOnboarding,
  updateUsers
} from '../redux/slice/app.slice'
import { SubscriptionsApi, usePrefetch } from '../redux/Api/SubscriptionsApi'
import { ArchiveApi } from '../redux/Api/ArchiveApi'
import store from '../redux/store'
import loginMiddleware from '../global/loginMiddleware'
import { providers } from './providers'
import { useNotifications } from '../contexts/Notifications'
import { CONTACT_SUPPORT_TEAM } from '../components/new/constants'

export const AuthContext = createContext(null)

const customOauthProviders = ['google', 'icloud']

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch()
  const prefetchSubscriptions = usePrefetch('getSubscriptionsByState')
  const [isLoadingUsers, setIsLoadingUsers] = useState(false)
  const [needLoadingNew, setNeedLoadingNew] = useState(false)
  const [isShowMailboxWindow, setIsShowMailboxWindow] = useState(false)
  const openedWindowRef = useRef(null)
  const navigate = useNavigate()
  const { notifySuccess, notifyError, notifyWarning } = useNotifications()

  const [hasUsers, setHasUsers] = useState(
    getAllAccounts()?.length > 0 ?? false
  )
  const [searchParams, setSearchParams] = useSearchParams()
  const user = useSelector(store => {
    return store.app.user
  })

  const users = useSelector(store => {
    return store.app.users
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (getCookie('reload') === 'true') {
        setNeedLoadingNew(true)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (needLoadingNew) {
      if (getAllAccounts().length > users.length || getCookie('newAccount')) {
        loadNewUsers().then(() => eraseCookie('newAccount'))
      }
      setCookie('reload', '')
      setNeedLoadingNew(false)
    }
  }, [needLoadingNew])

  useEffect(() => {
    let checkWindowClosed
    if (isShowMailboxWindow) {
      checkWindowClosed = setInterval(() => {
        if (openedWindowRef.current.closed) {
          clearInterval(checkWindowClosed)
          hideAddMailboxWindow()
        } else {
          if (
            getAllAccounts().length > users.length ||
            getCookie('newAccount')
          ) {
            hideAddMailboxWindow()
            loadNewUsers().then(() => {
              eraseCookie('newAccount')
            })
          }
        }
      }, 500)
    }
    return () => {
      clearInterval(checkWindowClosed)
    }
  }, [isShowMailboxWindow])

  useEffect(() => {
    if (isMobile || isTablet) {
      if (isIOS) {
        window.location.replace(
          'https://app.adjust.com/bipxj3j?campaign=finalize_signups'
        )
      } else if (isAndroid) {
        window.location.replace(
          'https://app.adjust.com/7foh194?campaign=finalize_signups'
        )
      }
    }
    loadUsers(searchParams.get('selected'))
    searchParams.delete('selected')
    setSearchParams(searchParams)

    return () => {
      hideAddMailboxWindow()
    }
  }, [])

  const showAddMailboxWindow = type => {
    if (isShowMailboxWindow) {
      hideAddMailboxWindow()
    }
    const width = 600
    const height = 500
    const left = window.screenX + (window.outerWidth - width) / 2
    const top = window.screenY + (window.outerHeight - height) / 2.5
    const title = `Add Mailbox to Unroll.me`
    const url = loginMiddleware(type, '', null, null, false)
    openedWindowRef.current = window.open(
      url,
      title,
      `width=${width},height=${height},left=${left},top=${top}`
    )
    setIsShowMailboxWindow(true)
    window.addEventListener('beforeunload', ev => {
      hideAddMailboxWindow()
    })
  }

  const hideAddMailboxWindow = () => {
    if (openedWindowRef.current) {
      openedWindowRef.current.close()
    }
    openedWindowRef.current = null
    setIsShowMailboxWindow(false)
  }

  const logoutCurrentUser = () => {
    if (users.length > 1) {
      const updatedUsers = users.filter(u => u.id !== user.id)
      const accounts = getAllAccounts()
      const updatedAccounts = accounts.filter(account => account[0] !== user.id)
      saveAllAccounts(updatedAccounts)
      dispatch(updateUser(updatedUsers[0]))
      dispatch(updateUsers(updatedUsers))
      switchUser(updatedUsers[0])
      navigate('/app/inbox', { replace: true })
    } else {
      logout()
      dispatch(updateUsers([]))
      dispatch(logoutUser())
      setHasUsers(false)
    }
  }

  const loadUsers = async selectedEmail => {
    const accounts = getAllAccounts()
    if (accounts?.length > 0) {
      setHasUsers(true)
      setIsLoadingUsers(true)
      const fullAccounts = await Promise.all(
        accounts.map(async ([id, token, email]) => {
          try {
            const user = await axios.get(
              `${process.env.REACT_APP_API}/users/me`,
              null,
              {
                Authorization: `Bearer ${token}`
              }
            )
            return user.data
          } catch (e) {
            if (e.code === 'REAUTH') {
              return {
                id,
                email,
                reauth: true
              }
            }
          }
        })
      )
      dispatch(updateUsers(fullAccounts))

      const currentAccount = !selectedEmail
        ? accounts.find(
            account => account[1] === getCookieForUserAuthentication()
          )
        : accounts.find(account => account[2] === selectedEmail)

      if (
        currentAccount &&
        fullAccounts.find(account => account.id === currentAccount[0])
      ) {
        switchUser(
          fullAccounts.find(account => account.id === currentAccount[0], true)
        )
      } else {
        switchUser(fullAccounts[0])
        const provider = await getEmailProvider(selectedEmail)
        if (provider) {
          if (customOauthProviders.includes(provider)) {
            dispatch(
              updateAddMailboxModal({
                show: true,
                onLoad: false,
                type: provider,
                email: selectedEmail
              })
            )
          } else {
            dispatch(
              updateAddMailboxModal({
                show: true,
                onLoad: true,
                type: provider,
                email: selectedEmail
              })
            )
          }
        }
      }
      setIsLoadingUsers(false)
    } else {
      setHasUsers(false)
      dispatch(updateUsers([]))
    }
    // setSearchParams('')
  }

  const getEmailProvider = async email => {
    if (!email) {
      return null
    }
    let emailProvider = email.split('@')?.[1]
    if (!emailProvider) {
      return null
    }
    emailProvider = emailProvider.toLowerCase()

    const foundedProvider = Object.keys(providers).find(provider =>
      providers[provider].includes(emailProvider)
    )

    if (foundedProvider) {
      return foundedProvider
    }

    try {
      const user = await axios.get(
        `${process.env.REACT_APP_API}/auth/action?email=${email}`,
        null
      )
      return user.data?.provider
    } catch (e) {
      return null
      console.log(e)
    }
  }

  const startUserInitialScan = async token => {
    for (let i = 0; i < 90; i++) {
      await new Promise(resolve => setTimeout(resolve, 1000))
      try {
        const scanUser = await axios.get(
          `${process.env.REACT_APP_API}/users/me`,
          null,
          {
            Authorization: `Bearer ${token}`
          }
        )
        if (scanUser.data.scan_status === 'succeeded') {
          dispatch(
            updateUsers([
              ...users.filter(u => u.id !== scanUser.data.id),
              scanUser.data
            ])
          )
          if (store.getState().app.user?.id === scanUser.data?.id) {
            dispatch(updateUser(scanUser.data))
            dispatch(SubscriptionsApi.util.invalidateTags(['Subscriptions']))
            dispatch(ArchiveApi.util.invalidateTags(['Archives']))
          }
          break
        }
      } catch (e) {
        if (e.code === 'REAUTH') {
          return {
            id,
            email,
            reauth: true
          }
        }
      }
    }
  }

  const loadNewUsers = async () => {
    const accounts = getAllAccounts()

    const newAccounts = accounts.filter(
      account => !users.some(user => account[0] === user.id)
    )

    if (newAccounts.length > 0) {
      const newAccountsFull = await Promise.all(
        newAccounts.map(async ([id, token, email]) => {
          try {
            const user = await axios.get(
              `${process.env.REACT_APP_API}/users/me`,
              null,
              {
                Authorization: `Bearer ${token}`
              }
            )
            if (user.data.scan_status === 'in_progress') {
              startUserInitialScan(token)
              if (accounts?.length === 1) {
                dispatch(updateUserOnboarding(true))
              }
            }
            return user.data
          } catch (e) {
            if (e.code === 'REAUTH') {
              return {
                id,
                email,
                reauth: true
              }
            } else {
              notifyError(
                <>
                  Error on loading mailbox: ${email}. Please contact{' '}
                  <CONTACT_SUPPORT_TEAM />
                </>
              )
            }
          }
        })
      )
      dispatch(updateUsers([...users, ...newAccountsFull]))

      switchUser(newAccountsFull[0])
      if (!searchParams.get('subscriptionId')) {
        navigate('/app/inbox', { replace: true })
      }
    } else {
      const account = JSON.parse(atob(getCookie(`newAccount`)))
      if (users.find(user => user.id === account[0])) {
        switchUser(users.find(user => user.id === account[0]))
        if (!searchParams.get('subscriptionId')) {
          navigate('/app/inbox', { replace: true })
        }
      }
    }
    setHasUsers(accounts?.length > 0)
  }

  const switchUser = toUser => {
    let accounts = getAllAccounts()
    const selectedAccount = accounts.find(account => account[0] === toUser.id)

    if (selectedAccount) {
      dispatch(updateUser(toUser))
      setCookieForUserAuthentication(selectedAccount[1])
      dispatch(SubscriptionsApi.util.invalidateTags(['Subscriptions']))
      dispatch(ArchiveApi.util.invalidateTags(['Archives']))
      prefetchData()
    } else {
      dispatch(updateUsers([users.filter(u => u.id !== toUser.id)]))
    }
  }

  const prefetchData = () => {
    prefetchSubscriptions({
      state: 'new'
    })
    prefetchSubscriptions({
      state: 'inbox'
    })
    prefetchSubscriptions({
      state: 'unsubscribed'
    })
    prefetchSubscriptions({
      state: 'rollup'
    })
    prefetchSubscriptions({
      state: 'rolledup'
    })
  }

  const isUserAuthenticated = useSelector(
    store => store.app.isUserAuthenticated
  )

  const value = {
    user,
    isUserAuthenticated,
    switchUser,
    logoutCurrentUser,
    isLoadingUsers,
    loadUsers,
    loadNewUsers,
    hasUsers,
    showAddMailboxWindow,
    isShowMailboxWindow,
    getEmailProvider
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
