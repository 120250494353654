import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl.macro'
import Box from '../Box'
import Wrapper from '../Wrapper'
import theme from '../../global/theme'
import { track } from '../../global/eventTracking'
import Text from '../Text'
import MainNavAuthenticated from './MainNavAuthenticated'
import MainNavUnauthenticated from './MainNavUnauthenticated'
import Logo from '../Logo'
import LogoWhite from '../Logo/LogoWhite'
import { formatMessages } from '../../i18n/utils'

const messageDescriptors = defineMessages({
  howUseData: {
    id: 'components.MainNav.howUseData',
    defaultMessage: 'How we use data'
  }
})

const renderGoogleSignup = messages => {
  return (
    <Text
      as="a"
      href="https://unroll.me/your-data"
      target="_blank"
      color="gray.2"
      onClick={() =>
        track('reauthentication_action', {
          clickedOn: 'How we use data"'
        })
      }
      fontWeight="500">
      {messages.howUseData}
    </Text>
  )
}

const renderSubNav = (isUserAuthenticated, userEmail) => {
  if (isUserAuthenticated) {
    return <MainNavAuthenticated userEmail={userEmail} />
  }
  return <MainNavUnauthenticated />
}

const renderNav = (
  messages,
  isUserAuthenticated,
  userEmail,
  isGoogleSignUp
) => {
  return isGoogleSignUp
    ? renderGoogleSignup(messages)
    : renderSubNav(isUserAuthenticated, userEmail)
}

const renderLogo = showWhiteLogo => {
  return showWhiteLogo ? <LogoWhite height="38px" /> : <Logo height="38px" />
}

const MainNav = ({
  userEmail,
  isUserAuthenticated,
  isGoogleSignUp,
  showOnlyLogo,
  showWhiteLogo,
  redirectToLandingPage
}) => {
  const messages = formatMessages(messageDescriptors)

  return (
    <Box
      bg={showWhiteLogo ? 'gray.5' : 'white'}
      borderBottom={isGoogleSignUp ? null : 1}
      borderColor={showWhiteLogo ? 'gray.5' : 'gray.1'}
      position="relative"
      zIndex={103}>
      <Wrapper maxWidth={9}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <LogoWrapper
            py={[2, 3]}
            onClick={() => {
              track('main_navigation_action', { clickedOn: 'Unrollme Logo' })
              if (isGoogleSignUp)
                track('reauthentication_action', {
                  clickedOn: 'Unrollme Logo'
                })
            }}>
            {redirectToLandingPage ? (
              <a href="/">{renderLogo(showWhiteLogo)}</a>
            ) : (
              <Link to="/">{renderLogo(showWhiteLogo)}</Link>
            )}
          </LogoWrapper>
          {showOnlyLogo
            ? null
            : renderNav(
                messages,
                isUserAuthenticated,
                userEmail,
                isGoogleSignUp
              )}
        </Box>
      </Wrapper>
    </Box>
  )
}

const LogoWrapper = styled(Box)`
  @media only screen and (max-width: ${theme.breakpoints[1]}) {
    width: 40px;
    overflow: hidden;
    display: block;
  }
`

MainNav.propTypes = {
  userEmail: PropTypes.string,
  isUserAuthenticated: PropTypes.bool.isRequired
}

export default MainNav
