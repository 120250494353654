import React from 'react'
import { InputGroup } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { HiSearch } from 'react-icons/hi'
import styled from 'styled-components'

const Styled = styled.div`
  padding: 15px 0 15px 0;

  .input-group {
    height: 50px;
  }

  .search-icon-wrapper {
    width: 50px;
    justify-content: center;
    border: none;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .settings-button {
    width: 50px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: none;
    margin-left: 0px !important;
  }

  .settings-icon {
    margin: auto;
    transform: rotate(90deg) !important;
    width: 20px;
    height: 20px;
    color: rgb(0, 0, 0, 0.25);
  }

  .search-icon {
    width: 20px;
    height: 20px;
    border: none;
    color: rgb(0, 0, 0, 0.25);
  }

  .search-input {
    margin-left: 0px !important;
    font-size: 16px;
    font-weight: bold;
    border: none;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .search-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.25);
  }

  .search-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.25);
  }

  .search-input::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.25);
  }

  .search-input:focus {
    background: rgba(0, 0, 0, 0.03);
    box-shadow: none;
  }
`

const SearchInput = ({ handleSearch, searchStr }) => {
  const handleChange = event => {
    handleSearch(event.target.value)
  }

  return (
    <Styled>
      <InputGroup>
        <InputGroup.Text className="search-icon-wrapper">
          <HiSearch className="search-icon" />
        </InputGroup.Text>
        <Form.Control
          onChange={handleChange}
          value={searchStr}
          placeholder="Search Subscriptions"
          aria-label="Search Subscriptions"
          aria-describedby="basic-addon1"
          className="search-input"
          type="search"
        />
        <InputGroup.Text className="settings-button">
          {/*<HiOutlineAdjustments className="settings-icon" />*/}
        </InputGroup.Text>
      </InputGroup>
    </Styled>
  )
}

export default SearchInput
