import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { setCookieForOauthCode } from '../../global/cookies'

function SignupHandlerWrapper({ children }) {
  const params = useParams()
  const searchParams = new URLSearchParams(useLocation().search)

  useEffect(() => {
    const codeQS = searchParams.get('code')

    if (codeQS) {
      try {
        setCookieForOauthCode(codeQS)
      } catch (e) {}
    }
  }, [])

  return children
}

export default SignupHandlerWrapper
