import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Loading from '../../components/Loading'
import Box from '../../components/Box'
import { authenticateUser, authorizeUserWithOauth } from '../App/AppActions'
import DeclinedOAuthPrompt from '../../components/DeclinedOAuthPrompt'
import {
  eraseCookieForOauthCode,
  getCookieForOauthCode,
  setCookie
} from '../../global/cookies'

const SignupHandler = props => {
  let { provider } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispath = useDispatch()
  const providerMapForAuth = new Map([['outlook', 'azure']])

  useEffect(() => {
    if (searchParams.has('code')) {
      searchParams.delete('code')
      setSearchParams(searchParams)
    }
  }, [])

  useEffect(() => {
    // const {
    //   match: {
    //     params: { provider }
    //   },
    //   history,
    //   history: {
    //     location: { search }
    //   },
    //   authenticateUser,
    //   authorizeUserWithOauth
    // } = this.props

    provider = providerMapForAuth.has(provider)
      ? providerMapForAuth.get(provider)
      : provider

    const parsedState = JSON.parse(window.atob(searchParams.get('state')))
    const oauthCode = getCookieForOauthCode()
    eraseCookieForOauthCode()

    const redirectToAfterLogin =
      parsedState.query &&
      parsedState.query.redirect &&
      !parsedState.query.reauth
        ? parsedState.query.redirect
        : null

    if (oauthCode) {
      const params = {
        provider,
        secret: oauthCode,
        method: 'oauth'
        // history
      }

      if (parsedState.query.reauth) {
        params.reauth = true
      }

      if (
        parsedState &&
        parsedState.query &&
        parsedState.query.isAuthorization
      ) {
        if (provider === 'google' || provider === 'yahoo') {
          params.email = parsedState.query.email
        }
        authorizeUserWithOauth({
          ...params,
          redirectToAfterLogin
        }).then(() => {
          setCookie('reload', 'true')
          setTimeout(() => window.close(), 1000)
        })
      } else {
        authenticateUser(
          { ...params, redirectToAfterLogin },
          null,
          dispath,
          searchParams
        )
          .then(() => {
            setCookie('reload', 'true')
            setTimeout(() => window.close(), 1000)
          })
          .catch(err => {
            setCookie('reload', 'true')
            setTimeout(() => window.close(), 1000)
          })
      }
    }
  }, [searchParams])

  // const {
  //   location: {search},
  //   match: {
  //     params: {provider}
  //   }
  // } = this.props

  const error = searchParams.get('error')

  return error ? (
    <DeclinedOAuthPrompt provider={provider} />
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      flexDirection="column">
      <Loading icon />
    </Box>
  )
}

export default SignupHandler
