import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { get, last } from 'lodash'
import { parse } from 'query-string'
import { defineMessages } from 'react-intl.macro'
import Box from '../../components/Box'
import Button from '../../components/Button'
import { Input } from '../../components/Form'
import Text from '../../components/Text'
import Notification from '../../components/Notification'
import theme from '../../global/theme'
import { formatMessages } from '../../i18n/utils'
import { ReactComponent as GmailLogo } from '../../components/Icon/gmail.svg'
import { ReactComponent as IcloudLogo } from '../../global/signUpLogos/SignupIcloudLogo.svg'
import {
  authenticateUser,
  getAuthenticateActionForCustomAuth,
  submitEmailForLogin
} from '../App/AppActions'
import { buildParams } from './utils'
import ValidateModal from './ValidateModal'
import { useAuth } from '../../hook/useAuth'

const validIcloudDomains = ['icloud.com', 'me.com', 'mac.com']
const mainYahooDomains = ['yahoo.com']
const mainOutlookDomains = ['outlook.com', 'live.com', 'msn.com']
const mainAolDomains = ['aol.com']

export const messageDescriptors = defineMessages({
  gmail: {
    id: 'containers.CustomAuth.gmail',
    defaultMessage: 'Gmail'
  },
  icloud: {
    id: 'containers.CustomAuth.icloud',
    defaultMessage: 'iCloud'
  },
  linkMailbox: {
    id: 'containers.CustomAuth.linkMailbox',
    defaultMessage: 'Link your mailbox'
  },
  emailPlaceholderGmail: {
    id: 'containers.CustomAuth.emailPlaceholderGmail',
    defaultMessage: 'johnappleseed@gmail.com'
  },
  emailPlaceholderIcloud: {
    id: 'containers.CustomAuth.emailPlaceholderIcloud',
    defaultMessage: 'johnappleseed@icloud.com'
  },
  verificationPlaceholder: {
    id: 'containers.CustomAuth.verificationPlaceholder',
    defaultMessage: 'Verification Code'
  },
  enterEmail: {
    id: 'containers.CustomAuth.enterEmail',
    defaultMessage: 'Enter your email address below.'
  },
  verification: {
    id: 'containers.CustomAuth.verification',
    defaultMessage: 'A verification code will be sent to your email.'
  },
  btnContinue: {
    id: 'containers.CustomAuth.btnContinue',
    defaultMessage: 'Continue'
  },
  btnConnect: {
    id: 'containers.CustomAuth.btnConnect',
    defaultMessage: 'Connect Mailbox'
  },
  btnResend: {
    id: 'containers.CustomAuth.btnResend',
    defaultMessage: 'Resend'
  },
  footerL1: {
    id: 'containers.CustomAuth.footerLine1',
    defaultMessage: 'By connecting your mailbox with Unroll.Me, you understand'
  },
  footerL2P1: {
    id: 'containers.CustomAuth.footerL1P1',
    defaultMessage: 'that you may be enrolled in our'
  },
  footerL2P2: {
    id: 'containers.CustomAuth.footerL1P2',
    defaultMessage: 'measurement panel'
  },
  footerL2P3: {
    id: 'containers.CustomAuth.footerL1P1',
    defaultMessage: ', and'
  },
  footerL3P1: {
    id: 'containers.CustomAuth.footerL1P1',
    defaultMessage: 'you are agreeing to our'
  },
  footerL3P2: {
    id: 'containers.CustomAuth.footerL1P2',
    defaultMessage: 'Terms of Service'
  },
  footerL3P3: {
    id: 'containers.CustomAuth.footerL1P1',
    defaultMessage: 'and'
  },
  footerL3P4: {
    id: 'containers.CustomAuth.footerL1P1',
    defaultMessage: 'Privacy Policy'
  }
})

const EmailInput = styled(Input)`
  border: 0;
  border-radius: 11px;
  border: 1px solid #847f7f;
  margin: auto;
  margin-top: 25px;

  ::placeholder {
    text-align: left;
    opacity: 50%;
  }
`

const CodeInput = styled(Input)`
  border: 0;
  border-radius: 11px;
  border: 1px solid #847f7f;
  margin: auto;
  margin-top: 25px;

  ::placeholder {
    text-align: left;
    opacity: 50%;
  }
`

const FooterBox = styled(Box)`
  a {
    text-decoration: none;
    color: #41c1c8;
  }

  a:hover {
    color: #41c1c8;
  }
`

const EmailLabel = styled.label`
  position: absolute;
  top: 12px;
  right: 2%;
`

const CustomAuth = ({ setShowAppClip, setEmail }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false)
  const [isBtnEnabled, setBtnEnableState] = useState(false)
  const [showResendBtn, setResendBtnState] = useState(false)
  const [enteredEmail, setEnteredEmail] = useState('')
  const [code, setCode] = useState(null)
  const [isEmailSubmitted, setEmailSubmitted] = useState(false)
  const messages = formatMessages(messageDescriptors)
  const searchParams = parse(location.search)
  const storeProvider = useSelector(store => store.app.addMailboxModal.type)
  const searchProvider = searchParams.provider
  const provider = searchProvider ?? storeProvider
  const { loadNewUsers } = useAuth()

  useEffect(() => {
    if (code) {
      setResendBtnState(false)
    }

    const timeout = setTimeout(() => {
      if (!code && isEmailSubmitted) {
        setResendBtnState(true)
      }
    }, 30000)

    return () => {
      clearTimeout(timeout)
    }
  }, [code, showResendBtn, isEmailSubmitted])

  const validateEmail = (values, provider) => {
    const errors = {}

    if (!values.email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    } else if (
      provider === 'google' &&
      validIcloudDomains
        .concat(mainYahooDomains, mainOutlookDomains, mainAolDomains)
        .includes(last(values.email.split('@')))
    ) {
      errors.email = 'Please enter a valid Google email.'
    } else if (
      provider === 'icloud' &&
      !validIcloudDomains.includes(last(values.email.split('@')))
    ) {
      errors.email = 'Please enter a valid iCloud email.'
    } else {
      return setBtnEnableState(true)
    }

    return errors
  }

  const validateCode = values => {
    const errors = {}

    if (!values.code || values.code.length < 6) {
      errors.code = 'The 6-digit code you entered was incorrect. Try again.'
    } else if (!/^[0-9]{6}$/i.test(values.code)) {
      errors.code = 'Please enter the valid numeric code from the email.'
    } else {
      return setBtnEnableState(true)
    }

    return errors
  }

  const renderLogo = provider => {
    if (provider === 'google') {
      return <GmailLogo width="35px" height="30px" />
    }
    if (provider === 'icloud') {
      return <IcloudLogo width="35px" height="30px" />
    }

    return null
  }

  const renderLabel = provider => {
    if (provider === 'google') {
      return (
        <Text
          as="span"
          fontWeight={1}
          fontSize={['25px', '25px', '25px', '25px']}
          ml={3}
          mt={1}>
          {messages.gmail}
        </Text>
      )
    }
    if (provider === 'icloud') {
      return (
        <Text
          as="span"
          fontWeight={1}
          fontSize={['25px', '25px', '25px', '25px']}
          ml={3}
          mt={1}>
          {messages.icloud}
        </Text>
      )
    }

    return null
  }

  const getPlaceholder = provider => {
    if (provider === 'google') {
      return messages.emailPlaceholderGmail
    }
    if (provider === 'icloud') {
      return messages.emailPlaceholderIcloud
    }

    return ''
  }

  return (
    <>
      <Box bg="white">
        <Notification />
        <Box
          display="flex"
          alignItems="center"
          width={['80%', '80%', '80%', '65%']}
          fontSize={['15px', '15px', '15px', '15px']}
          margin="0 auto"
          mt="20px"
          flexDirection="column">
          <Box textAlign="center" color="gray.2" mt={6}>
            <Box display="flex" flexDirection="row" justifyContent="center">
              {renderLogo(provider)}
              {renderLabel(provider)}
            </Box>
            <Text
              fontWeight="bold"
              fontSize={['20px', '20px', '20px', '20px']}
              color="gray.2"
              mt={5}>
              {messages.linkMailbox}
            </Text>
            <Text
              fontWeight={1}
              m={0}
              mt={3}
              fontSize={['15px', '15px', '15px', '15px']}>
              {messages.enterEmail}
            </Text>
          </Box>
          <Box mt={3}>
            <Formik
              initialValues={{
                email: '',
                code: null
              }}
              validate={values => {
                setBtnEnableState(false)

                return isEmailSubmitted
                  ? validateCode(values)
                  : validateEmail(values, provider)
              }}
              onSubmit={async (values, actions) => {
                if (isEmailSubmitted) {
                  // Login Action
                  const params = buildParams(
                    searchParams,
                    values.email.trim().toLowerCase(),
                    values.code,
                    provider
                  )
                  const redirectToAfterLogin = searchParams.redirect || null
                  authenticateUser({ ...params, redirectToAfterLogin }, true)
                    .then(() => navigate('/app/inbox'))
                    .catch(err => {
                      actions.setSubmitting(false)
                      setBtnEnableState(true)
                      let errMsg = 'Something went wrong. Try again.'

                      if (err.response && err.response.status === 400) {
                        errMsg = get(err, 'response.data.detail', errMsg)
                      }
                      actions.setErrors({ code: errMsg })
                    })
                } else {
                  // Get auth action
                  const customAction = await getAuthenticateActionForCustomAuth(
                    values.email.trim().toLowerCase(),
                    provider,
                    !!searchParams.reauth
                  )

                  if (
                    customAction === 'signup' ||
                    customAction === 'authorize'
                  ) {
                    // For icloud signup/authorization, redirect to app-password page for direct signup/authorization
                    // For google signup/authorization, redirect to appclip page, then to app-pswd page direct signup/authorization in case of manual authorization
                    const searchObj = {
                      ...searchParams,
                      directauth: true,
                      email: values.email.trim().toLowerCase()
                    }

                    if (provider === 'google') {
                      setEmail(values.email.trim().toLowerCase())
                      setShowAppClip(true)
                      // navigate(`/appclip?${stringify(searchObj)}`)
                    } else if (provider === 'icloud') {
                      setEmail(values.email.trim().toLowerCase())
                      setShowAppClip(true)
                    }

                    return
                  }

                  // Submit email to get custom code for login
                  submitEmailForLogin(values.email.trim().toLowerCase())
                    .then(() => {
                      actions.setErrors({ email: '' })
                      actions.setSubmitting(false)
                      setEmailSubmitted(true)
                      setBtnEnableState(false)
                      setEnteredEmail(values.email.trim().toLowerCase())
                      setShowModal(true)

                      // Redirect to validate modal
                      // const searchObj = {
                      //   ...searchParams,
                      //   email: values.email.trim().toLowerCase()
                      // }
                      // navigate(`/authenticate/validate?${stringify(searchObj)}`)
                    })
                    .catch(err => {
                      actions.setSubmitting(false)
                      let errMsg = 'Something went wrong. Try again.'

                      if (err.response && err.response.status === 400) {
                        errMsg = get(err, 'response.data.detail', errMsg)
                      }
                      actions.setErrors({ email: errMsg })
                    })
                }
              }}>
              {formProps => (
                <Form>
                  <Box
                    margin="auto"
                    width={['220px', '220px', '280px', '350px']}>
                    <EmailInput
                      placeholder={getPlaceholder(provider)}
                      name="email"
                      disabled={isEmailSubmitted}
                      value={formProps.values.email}
                      onChange={formProps.handleChange}
                      error={formProps.errors.email}
                    />
                  </Box>
                  <Box textAlign="center" color="red">
                    {formProps.errors.email ? formProps.errors.email : null}
                  </Box>
                  {!isEmailSubmitted ? null : (
                    <Box>
                      <Box
                        margin="auto"
                        position="relative"
                        width={['220px', '220px', '280px', '350px']}>
                        <CodeInput
                          placeholder={messages.verificationPlaceholder}
                          name="code"
                          id="code"
                          maxLength="6"
                          fontWeight={0}
                          value={formProps.values.code}
                          onChange={event => {
                            formProps.setErrors({})
                            setCode(event.target.value)
                            formProps.handleChange(event)
                          }}
                          error={formProps.errors.code}
                        />
                        <EmailLabel htmlFor="code">
                          {!showResendBtn ? null : (
                            <Button
                              link
                              color={theme.colors.blueLogin}
                              mb={1}
                              display={['block', 'block', 'inline-block']}
                              as="a"
                              onClick={() => {
                                submitEmailForLogin(
                                  formProps.values.email.trim().toLowerCase()
                                )
                                  .then(() => {
                                    formProps.setErrors({})
                                    formProps.setSubmitting(false)
                                    setEmailSubmitted(true)
                                    setResendBtnState(false)
                                  })
                                  .catch(err => {
                                    formProps.setSubmitting(false)
                                    let errMsg =
                                      'Something went wrong. Try again.'

                                    if (
                                      err.response &&
                                      err.response.status === 400
                                    ) {
                                      errMsg = get(
                                        err,
                                        'response.data.detail',
                                        errMsg
                                      )
                                    }

                                    formProps.setErrors({ code: errMsg })
                                  })
                              }}>
                              {messages.btnResend}
                            </Button>
                          )}
                        </EmailLabel>
                      </Box>
                      <Box textAlign="center" color="red">
                        {formProps.errors.code ? formProps.errors.code : null}
                      </Box>
                    </Box>
                  )}
                  <Box textAlign="center">
                    <Button
                      disabled={!isBtnEnabled || formProps.isSubmitting}
                      type="submit"
                      customLogin
                      borderRadius="11px"
                      color={theme.colors.white}
                      lineHeight={['2.4', '3']}
                      mt={4}
                      width={['220px', '220px', '280px', '350px']}
                      height="90px"
                      fontSize={['16px', '16px', '16px', '16px']}
                      display="block">
                      {isEmailSubmitted
                        ? messages.btnConnect
                        : messages.btnContinue}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
          <FooterBox
            textAlign="center"
            fontSize={['11px', '11px', '13px', '13px']}
            mt={5}>
            <Text>{messages.footerL1}</Text>
            <Text>
              {messages.footerL2P1}{' '}
              <a href="/your-data">{messages.footerL2P2}</a>
              {messages.footerL2P3}
            </Text>
            <Text mb={6}>
              {messages.footerL3P1}{' '}
              <a href="/legal/terms">{messages.footerL3P2}</a>{' '}
              {messages.footerL3P3}{' '}
              <a href="/legal/privacy">{messages.footerL3P4}</a>.
            </Text>
          </FooterBox>
        </Box>
      </Box>
      {showModal && (
        <ValidateModal
          buildParams={buildParams}
          email={enteredEmail}
          provider={provider}
        />
      )}
    </>
  )
}

export default CustomAuth
