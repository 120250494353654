import { defineMessages } from 'react-intl.macro'
import { formatMessages } from '../../i18n/utils'

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

const messageDescriptors = defineMessages({
  errorOccured: {
    id: 'components.Notification.Actions.errorOccured',
    defaultMessage:
      'An error occured. Please contact support@unroll.me for further assistance.'
  }
})
const messages = formatMessages(messageDescriptors)

export function removeNotification() {
  return {
    type: REMOVE_NOTIFICATION
  }
}

export function addNotification({
  context,
  text = messages.errorOccured,
  timeout = 3000
}) {
  const payload = {
    context,
    text
  }

  return dispatch => {
    dispatch({
      type: ADD_NOTIFICATION,
      payload
    })
    setTimeout(
      () => {
        dispatch(removeNotification())
      },
      context !== 'negative' ? timeout : 10000
    )
  }
}
