import React from 'react'
import PropTypes from 'prop-types'
import Box from '../Box'
import { formatNumber } from '../../i18n/utils'

const Badge = ({ count, overflowCount, text, bg, ...props }) => {
  const displayCount = count > overflowCount ? `${overflowCount}+` : count

  return (
    <Box
      bg={bg}
      lineHeight="1"
      color="white"
      borderRadius="10em"
      fontSize="10px"
      px={1}
      alignItems="center"
      justifyContent="center"
      display="inline-flex"
      minWidth={18}
      height={18}
      {...props}>
      <Box as="span" display="block">
        {formatNumber(displayCount) || null} {text || null}
      </Box>
    </Box>
  )
}

Badge.defaultProps = {
  count: 0,
  overflowCount: 999,
  text: null,
  bg: 'green'
}
Badge.propTypes = {
  count: PropTypes.number,
  overflowCount: PropTypes.number,
  text: PropTypes.string,
  bg: PropTypes.string
}

export default Badge
