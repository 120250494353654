import { createApi } from '@reduxjs/toolkit/query/react'
import UnrollApi from '../../http/UnrollApi'

const axiosBaseQuery = ({ baseUrl } = { baseUrl: '' }) => async ({
  url,
  method,
  data,
  params,
  configs
}) => {
  try {
    const callUrl = url.startsWith('http') ? url : baseUrl + url
    const result = await UnrollApi({
      url: callUrl,
      method,
      data,
      params,
      ...configs
    })
    return { data: result.data }
  } catch (axiosError) {
    let err = axiosError
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message
      }
    }
  }
}

export const ArchiveApi = createApi({
  reducerPath: 'ArchiveApi',
  tagTypes: ['Archives'],
  baseQuery: axiosBaseQuery({
    baseUrl: '/'
  }),
  endpoints: build => ({
    getArchives: build.query({
      query: () => ({
        url: 'archives',
        method: 'get'
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Archives', id })),
              { type: 'Archives', id: 'LIST' }
            ]
          : [{ type: 'Archives', id: 'LIST' }]
    }),
    createArchive: build.mutation({
      query: () => {
        return {
          url: 'archives',
          method: 'POST'
        }
      },
      invalidatesTags: [{ type: 'Archives', id: 'LIST' }]
    })
  })
})

export const { useGetArchivesQuery, useCreateArchiveMutation } = ArchiveApi
