const cookies = require('browser-cookies')

function setCookie(cookieName, value) {
  return cookies.set(cookieName, value)
}

function eraseCookie(cookieName) {
  return cookies.erase(cookieName)
}

function getCookie(cookieName) {
  return cookies.get(cookieName)
}

function setCookieForUserAuthentication(token) {
  return cookies.set('umUserAuthenticated', token, { expires: 90 })
}

function getCookieForUserAuthentication() {
  const oldAuthCookie = cookies.get('umAccessControl')
  if (oldAuthCookie) {
    setCookieForUserAuthentication(oldAuthCookie)
    eraseCookie('umAccessControl')
  }
  return cookies.get('umUserAuthenticated')
}

function eraseCookieForUserAuthentication() {
  cookies.erase('activeAccount')
  cookies.erase('tempAuthToken')
  return cookies.erase('umUserAuthenticated')
}

function getCookieForRollupRecommendations() {
  return cookies.get('umHideRollupRecommendations') || false
}
function getDecodedAccounts() {
  if (getCookie('activeAccount')) {
    return JSON.parse(window.atob(getCookie('activeAccount')))
  }
  return false
}

function setEncodedAccounts(accountsObj) {
  const encodedAccounts = window.btoa(JSON.stringify(accountsObj))
  setCookie('activeAccount', encodedAccounts)
}

function getCookieForOauthCode() {
  return getCookie('umOauthCode')
}

function setCookieForOauthCode(codeQS) {
  return cookies.set('umOauthCode', codeQS, { domain: 'unroll.me' })
}

function eraseCookieForOauthCode() {
  return cookies.erase('umOauthCode', {
    domain: 'unroll.me'
  })
}

module.exports = {
  setCookieForOauthCode,
  setCookieForUserAuthentication,
  getCookieForUserAuthentication,
  eraseCookieForUserAuthentication,
  getCookieForRollupRecommendations,
  setCookie,
  getCookie,
  eraseCookie,
  getDecodedAccounts,
  setEncodedAccounts,
  getCookieForOauthCode,
  eraseCookieForOauthCode
}
