import React from 'react'
import styled from 'styled-components'

export const KeepLabel = styled.span`
  color: #00bcbb;
`

export const BlockedLabel = styled.span`
  color: #c83c3c;
`

export const RollupLabel = styled.span`
  color: #f7931d;
`

export const BoldText = styled.span`
  font-weight: bold;
`
