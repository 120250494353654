import React, { useCallback, useContext, useMemo, useState } from 'react'
import {
  Accordion,
  AccordionContext,
  Badge,
  Card,
  useAccordionButton
} from 'react-bootstrap'
import { TbChevronUp } from 'react-icons/tb'
import SubscriptionSingleItem from './SubscriptionSingleItem'
import {
  ButtonGroupWrapper,
  GroupDomain,
  GroupLabelContainer,
  SubscriptionGroupWrapper
} from './styled'
import SubscriptionButtonsGroup from '../SubscriptionButtonsGroup'

function CustomToggle({
  eventKey,
  subscriptions,
  type,
  subtype,
  selectedSubscription,
  setSelectedSubscription
}) {
  const [showPlaceHolder, setShowPlaceholder] = useState(false)
  const [loadImage, setLoadImage] = useState(true)
  const { activeEventKey } = useContext(AccordionContext)
  const isCurrentEventKey = activeEventKey === eventKey

  const decoratedOnClick = useAccordionButton(eventKey, () => {})

  return (
    <>
      <GroupLabelContainer
        onClick={decoratedOnClick}
        collapsed={isCurrentEventKey}>
        <TbChevronUp />
        {(showPlaceHolder || loadImage) && (
          <Badge
            className="alternative-icon"
            style={{
              display: 'inline-flex',
              borderRadius: '12px',
              width: '40px',
              height: '40px',
              fontSize: 20,
              border: '1px solid rgb(0,0,0,0.05)',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: 20
            }}>{`${subscriptions[0]?.domain.charAt(0).toUpperCase()}`}</Badge>
        )}
        {!showPlaceHolder && (
          <img
            style={{
              borderRadius: '12px',
              width: '40px',
              height: '40px',
              objectFit: 'contain',
              border: '1px solid rgb(0,0,0,0.05)'
            }}
            hidden={loadImage}
            alt={`${subscriptions[0].domain} logo`}
            src={`https://logo.clearbit.com/${subscriptions[0].domain}`}
            onLoad={() => setLoadImage(false)}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null // prevents looping
              setShowPlaceholder(true)
            }}
          />
        )}
        <GroupDomain>{`${subscriptions[0]?.domain} (${subscriptions.length})`}</GroupDomain>
      </GroupLabelContainer>
      <ButtonGroupWrapper>
        <SubscriptionButtonsGroup
          subscription={subscriptions}
          setSelectedSubscription={setSelectedSubscription}
          selectedSubscription={selectedSubscription}
          subtype={subtype}
          type={type}
        />
      </ButtonGroupWrapper>
    </>
    // <button
    //   type="button"
    //   style={{ backgroundColor: 'pink' }}
    //   onClick={decoratedOnClick}
    // >
    //   {children}
    // </button>
  )
}

const SubscriptionGroup = ({
  subscriptions,
  setSelectedSubscription,
  selectedSubscription,
  type,
  isOpen,
  subtype
}) => {
  return (
    <SubscriptionGroupWrapper>
      <Accordion defaultActiveKey={isOpen && '0'}>
        <Card>
          <Card.Header>
            <CustomToggle
              eventKey="0"
              subscriptions={subscriptions}
              subtype={subtype}
              type={type}
              setSelectedSubscription={setSelectedSubscription}
              selectedSubscription={selectedSubscription}
            />
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {subscriptions.map(subscription => (
                <SubscriptionSingleItem
                  key={subscription.id}
                  subscription={subscription}
                  type={type}
                  subtype={subtype}
                  setSelectedSubscription={setSelectedSubscription}
                  selectedSubscription={selectedSubscription}
                />
              ))}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </SubscriptionGroupWrapper>
  )
}

export default SubscriptionGroup
