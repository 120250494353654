import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { parse } from 'query-string'
import Box from '../../components/Box'
import Page from '../../components/Page'
import Button from '../../components/Button'
import Wrapper from '../../components/Wrapper'
import Text from '../../components/Text'
import theme from '../../global/theme'

const AppClipPage = styled(Box)`
  background-color: ${theme.colors.gray[5]};
  font-family: ${theme.fontFamily.ptSans};
`

const AppClip = ({ email, provider, setShowAppClip, setShowGoogleSignup }) => {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    // const {
    //   location: { search },
    //   history,
    //   startAppClipSignupPolling
    // } = this.props

    const searchParams = parse(location.search)
    // startAppClipSignupPolling( email, provider, location.search)
  }, [])

  return (
    <>
      <AppClipPage
        display="flex"
        color="white"
        // minHeight="100vh"
        height="550px"
        borderBottomLeftRadius="40px"
        borderBottomRightRadius="40px"
        flexDirection="column">
        <Wrapper
          width={['90%', '90%', '90%', '90%', '80%', '80%']}
          fontSize={['15px', '15px', '18px', '18px']}
          lineHeight="26px"
          textAlign="center">
          <Box
            display="flex"
            minHeight="300px"
            heigth="100%"
            flexDirection="row"
            bg="white"
            borderRaduis="25px">
            <Box width="45%" mt={5} pl={[1, 1, 1, 7]} pr={[1, 1, 1, 7]}>
              <Box display="flex" flexDirection="column">
                <Text fontSize={[4, 4, 4, 4, 5]} fontWeight={2} color="black">
                  Automatic Upgrade
                  <Text as="span" color="blue">
                    {' '}
                    (Recommended):
                  </Text>
                </Text>
                <Text
                  fontSize={[3, 3, 3, 3, 4]}
                  fontWeight={1}
                  color="black"
                  lineHeight="1.5"
                  mt={3}>
                  Scan the AppClip or Instant App code below with your device to
                  automatically launch Unroll.Me and upgrade your mailbox.
                </Text>
                <Box
                  mt={5}
                  mb={9}
                  height={['400px', '400px', '400px', '400px', '200px']}>
                  <Box
                    display="flex"
                    flexDirection={[
                      'column',
                      'column',
                      'column',
                      'column',
                      'row'
                    ]}>
                    <Box
                      width={['100%', '100%', '100%', '100%', '50%']}
                      display="flex"
                      flexDirection="column">
                      <Box
                        as="img"
                        src="https://unroll.me/assets/img/ios-appclip-code.png"
                        width="80%"
                        maxWidth="150px"
                        verticalAlign="middle"
                        margin="0 auto"
                      />
                      <Box height="50px" mt="10px">
                        <Text
                          fontSize={[2, 2, 2, 2, 3]}
                          color="black"
                          margin="0 auto">
                          Requires iOS 14.3 or later
                        </Text>
                      </Box>
                    </Box>
                    <Box
                      width={['100%', '100%', '100%', '100%', '50%']}
                      display="flex"
                      flexDirection="column">
                      <Box
                        as="img"
                        src="https://unroll.me/assets/img/android-qr-code.png"
                        width="80%"
                        maxWidth="150px"
                        verticalAlign="middle"
                        margin="0 auto"
                      />
                      <Box height="50px"></Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box width="10%">
              <Box
                display="flex"
                height="100%"
                flexDirection="column"
                alignItems="center">
                <Box
                  height="45%"
                  borderRight={1}
                  borderColor="gray.6"
                  mt="20px"></Box>
                <Box>
                  <Text fontSize={4} fontWeight={2} color="black">
                    or
                  </Text>
                </Box>
                <Box
                  height="45%"
                  borderRight={1}
                  borderColor="gray.6"
                  mb="20px"></Box>
              </Box>
            </Box>
            <Box width="45%" mt={5} pl={[1, 1, 1, 7]} pr={[1, 1, 1, 7]}>
              <Box display="flex" flexDirection="column">
                <Text fontSize={[4, 4, 4, 4, 5]} fontWeight={2} color="black">
                  Manual Upgrade
                </Text>
                <Text
                  fontSize={[3, 3, 3, 3, 4]}
                  fontWeight={1}
                  color="black"
                  lineHeight="1.5"
                  mt={3}>
                  Tap the button below to set up a Google App Password manually.
                </Text>
                <Text
                  fontSize={[3, 3, 3, 3, 4]}
                  fontWeight={1}
                  color="black"
                  lineHeight="1.5"
                  mt={1}>
                  NOTE: This process can be tricky and may take some time.
                </Text>
                <Box
                  mt={5}
                  mb={9}
                  height={['400px', '400px', '400px', '400px', '200px']}>
                  <Box
                    height={['10px', '140px', '100px', '100px', '150px']}></Box>
                  <Box alignItems="center">
                    <Button
                      type="submit"
                      primary
                      backgroundColor="white"
                      color="blue"
                      borderRadius="25px"
                      width="70%"
                      lineHeight={[3, 3, 3, 3, 3.5]}
                      fontSize={[1, 1, 1, 1, 2]}
                      display="block"
                      onClick={() => {
                        setShowAppClip(false)
                        setShowGoogleSignup(true)
                      }}>
                      Upgrade Manually
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Wrapper>
      </AppClipPage>
    </>
  )
}

export default AppClip
