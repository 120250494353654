import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { useAuth } from '../hook/useAuth'

const RequireAuth = ({ children }) => {
  const location = useLocation()
  const { user, isUserAuthenticated } = useAuth()

  if (!user && !isUserAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />
  }
  return children
}

export { RequireAuth }
