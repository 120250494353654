import { setCookieForUserAuthentication } from '../../global/cookies'
import { axiosMethods as axios } from '../../global/axiosHelpers'
import { logoutUser } from '../App/AppActions'

export const SET_RESEND_BTN_STATE = 'SET_RESEND_BTN_STATE'
export const SET_CODE = 'SET_CODE'
export const SET_EMAIL = 'SET_EMAIL'

export function setResendBtnState(visible) {
  return {
    type: SET_RESEND_BTN_STATE,
    payload: visible
  }
}

export function setCode(code) {
  return {
    type: SET_CODE,
    payload: code
  }
}

export const submitEmail = email => {
  return axios.post(`${process.env.REACT_APP_API}/users/notify`, {
    email,
    subject: 'delete-account'
  })
}
export const deleteAccount = async (email, code) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_AUTH_API}/auth/login`,
      {
        provider: 'unrollme',
        email,
        secret: code,
        method: 'custom',
        isDeleteAccount: true,
        isWeb: true
      }
    )
    setCookieForUserAuthentication(resp.data.access_token)
  } catch (err) {
    let errMsg = 'Something went wrong. Try again.'
    if (err.response && err.response.status === 400) {
      errMsg = 'The code you entered was incorrect. Try again.'
    }

    throw new Error(errMsg)
  }

  try {
    await axios.delete(`${process.env.REACT_APP_API}/users/me`)
    await logoutUser()
  } catch (err) {
    throw new Error('Something went wrong. Try again.')
  }
}
