import React from 'react'
import { NavLink } from 'react-router-dom'
import { defineMessages } from 'react-intl.macro'
import Button from '../Button'
import Box from '../Box'
import { formatMessages } from '../../i18n/utils'

const messageDescriptors = defineMessages({
  home: {
    id: 'components.MainNav.UnAuthenticated.home',
    defaultMessage: 'Home'
  },
  login: {
    id: 'components.MainNav.UnAuthenticated.login',
    defaultMessage: 'Login'
  },
  signup: {
    id: 'components.MainNav.UnAuthenticated.signup',
    defaultMessage: 'Signup'
  }
})

const MainNavUnauthenticated = () => {
  const messages = formatMessages(messageDescriptors)

  return (
    <Box>
      <Button href="/" link lineHeight="3" as="a" fontSize={[2, 3]} py={[2, 3]}>
        {messages.home}
      </Button>
      <Button
        to="/login"
        link
        lineHeight="3"
        as={NavLink}
        fontSize={[2, 3]}
        py={[2, 3]}
        color="orange"
        pr={0}>
        {messages.login}
      </Button>
      <Button
        to="/login"
        link
        lineHeight="3"
        as={NavLink}
        fontSize={[2, 3]}
        py={[2, 3]}
        px={1}>
        /
      </Button>
      <Button
        to="/signup"
        link
        lineHeight="3"
        as={NavLink}
        fontSize={[2, 3]}
        py={[2, 3]}
        pl={0}
        color="blue"
        pr={0}>
        {messages.signup}
      </Button>
    </Box>
  )
}

export default MainNavUnauthenticated
