import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { desaturate, darken, rgba } from 'polished'
import {
  width,
  fontSize,
  space,
  textStyle,
  lineHeight,
  color,
  textAlign,
  position,
  fontWeight,
  top,
  bottom,
  left,
  right,
  borderRadius,
  boxShadow
} from 'styled-system'
import theme from '../../global/theme'

const Button = styled.button`
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  font-family: inherit;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  margin: 0;
  position: relative;
  background-image: none;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
  border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  font-size: ${theme.fontSizes[2]}px;
  text-align: center;

  color: ${theme.colors.gray[2]};
  font-weight: ${theme.fontWeights[2]};
  border: 1px solid;
  padding: 0 ${theme.space[3]}px;
  line-height: calc(${theme.space[3]}px * 2 + 1px);
  box-shadow: ${theme.buttons.shadows.normal};
  border-radius: ${theme.radii[1]}px;
  position: relative;
  fill: ${theme.colors.gray[2]};
  border-color: ${props => (props.bg ? props.bg : theme.colors.gray[1])};
  background-color: ${props => (props.bg ? props.bg : theme.colors.white)};
  visibility: ${props => (props.visibility ? props.visibility : 'visible')};

  &:hover {
    text-decoration: none;
    border-color: ${props =>
      props.bg ? darken(0.1, props.bg) : darken(0.1, theme.colors.gray[1])};
    background-color: ${props => (props.bg ? darken(0.1, props.bg) : null)};
    box-shadow: ${theme.buttons.shadows.hover};
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: ${theme.buttons.shadows.active};
  }

  &[disabled] {
    cursor: default;

    &:hover {
      border-color: ${props => (props.bg ? props.bg : theme.colors.gray[1])};
      box-shadow: ${theme.buttons.shadows.normal};
    }
  }

  ${props =>
    props.opaque &&
    css`
      opacity: 0.5;
      cursor: wait;
    `}

  ${props =>
    props.active &&
    css`
      background-color: ${theme.colors.blue};
      border-color: ${theme.colors.blue};
      color: ${theme.colors.white};
      box-shadow: none;
      fill: ${theme.colors.white};

      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
        border-color: ${theme.colors.blue};
        cursor: default;
      }
    `}

  ${props =>
    props.primary &&
    css`
      background-color: ${theme.colors.blue};
      border-color: ${theme.colors.blue};
      color: ${theme.colors.white};
      box-shadow: none;
      fill: ${theme.colors.white};

      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
        border-color: ${theme.colors.blue};
      }

      &[disabled],
      &[disabled]:hover {
        box-shadow: none;
        background-color: ${rgba(theme.colors.green, 0.3)};
        border-color: ${rgba(theme.colors.green, 0.3)};
        svg path {
          fill: ${theme.colors.gray[1]};
        }
      }
    `}

  ${props =>
    props.positive &&
    css`
      background-color: ${theme.colors.green};
      border-color: ${theme.colors.green};
      color: ${theme.colors.white};
      &:hover {
        background-color: ${desaturate(0.1, theme.colors.green)};
        border-color: ${desaturate(0.1, theme.colors.green)};
      }

      &[disabled],
      &[disabled]:hover {
        box-shadow: none;
        background-color: ${rgba(theme.colors.green, 0.3)};
        border-color: ${rgba(theme.colors.green, 0.3)};
        svg path {
          fill: ${theme.colors.gray[1]};
        }
      }
    `}

  ${props =>
    props.negative &&
    css`
      background-color: ${theme.colors.red};
      border-color: ${theme.colors.red};
      color: ${theme.colors.white};
      &:hover {
        background-color: ${desaturate(0.1, theme.colors.red)};
        border-color: ${desaturate(0.1, theme.colors.red)};
      }
    `}

  ${props =>
    props.deleteAccount &&
    css`
      background-color: ${theme.colors.redDark};
      border-color: ${theme.colors.redDark};
      color: ${theme.colors.white};
      &:hover {
        background-color: ${desaturate(0.1, theme.colors.redDark)};
        border-color: ${desaturate(0.1, theme.colors.redDark)};
      }

      &[disabled],
      &[disabled]:hover {
        box-shadow: none;
        background-color: ${rgba(theme.colors.redDark, 0.3)};
        border-color: ${rgba(theme.colors.redDark, 0.3)};
        svg path {
          fill: ${theme.colors.gray[1]};
        }
      }
    `}

  ${props =>
    props.customLogin &&
    css`
      background-color: ${theme.colors.blueLogin};
      border-color: ${theme.colors.blueLogin};
      color: ${theme.colors.white};
      &:hover {
        background-color: ${desaturate(0.1, theme.colors.blueLogin)};
        border-color: ${desaturate(0.1, theme.colors.blueLogin)};
      }

      &[disabled],
      &[disabled]:hover {
        box-shadow: none;
        background-color: ${rgba(theme.colors.blueLogin, 0.3)};
        border-color: ${rgba(theme.colors.blueLogin, 0.3)};
        svg path {
          fill: ${theme.colors.gray[1]};
        }
      }
    `}

  ${props =>
    props.link &&
    css`
      background-color: transparent;
      border: 0;
      box-shadow: none;
      border-radius: none;
      &:hover,
      &:active,
      &:focus {
        color: ${props.focusColor || theme.colors.gray[3]};
        box-shadow: none;
        background-color: transparent;
      }
      &[aria-current='page'] {
        color: ${theme.colors.blue};
      }

      &:hover {
        svg path {
          fill: ${theme.colors.gray[3]};
        }
      }
      &[disabled],
      &[disabled]:hover {
        color: ${theme.colors.gray[1]};
        box-shadow: none;
        background-color: transparent;
        svg path {
          fill: ${theme.colors.gray[1]};
        }
      }
    `}




  ${width} ${fontSize} ${space} ${lineHeight} ${textStyle} ${color} ${textAlign} ${position} ${top} ${bottom} ${left} ${right} ${borderRadius} ${boxShadow} ${fontWeight}
`

Button.propTypes = {
  link: PropTypes.bool
}

export default Button
