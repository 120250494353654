import { addLocaleData, IntlProvider } from 'react-intl'
import en from 'react-intl/locale-data/en'
import ja from 'react-intl/locale-data/ja'
import translations from './locales'

export const locale = process.env.REACT_APP_LOCALE || 'en'
export const messages = translations[locale]

addLocaleData([...en, ...ja])

export const { intl } = new IntlProvider(
  { locale, messages: messages },
  {}
).getChildContext()
