import styled from 'styled-components'

export const SubscriptionGroupWrapper = styled.div`
  .card {
    border: none;
    border-radius: 0;
    //border-bottom: 2px solid rgb(0, 0, 0, 0.1);
  }

  .card-body {
    padding: 0;
    background: #fafafa;

    > div {
      padding-left: 15px;
    }
  }

  .card-header {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0 10px;
    background: #f3f3f3;
  }
`

export const GroupLabelContainer = styled.div`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
    opacity: 0.6;
  }

  > svg {
    width: 30px;
    height: 30px;
    transform: rotate(${props => (props.collapsed ? 0 : 180)}deg);
    margin-right: 20px;
    transition: transform 0.15s ease;
  }

  .alternative-icon {
    background-color: #3cc2c8 !important;
  }
`

export const GroupDomain = styled.div`
  font-size: 18px;
  font-weight: bold;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 20px;
`

export const ButtonGroupWrapper = styled.div`
  margin-left: auto;
`
