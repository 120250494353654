import styled from 'styled-components'

export const NotificationsWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1056;
  width: 400px;
  opacity: 0.9;
  max-height: 270px;
  overflow: hidden;

  > .alert {
    font-size: 14px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 270px;

  .btn-close {
    top: 50% !important;
    transform: translateY(-50%);
  }
`

export const OpacityTop = styled.div`
  position: absolute;
  background: linear-gradient(
    rgba(255, 255, 255, 0.85),
    rgba(255, 255, 255, 0.7) 10%,
    rgba(255, 255, 255, 0.6) 40%,
    rgba(255, 255, 255, 0.1) 90%,
    rgba(255, 255, 255, 0) 100%
  );
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  height: 20px;
  z-index: 999;
`

export const OpacityBottom = styled.div`
  position: absolute;
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 10%,
    rgba(255, 255, 255, 0.6) 60%,
    rgba(255, 255, 255, 0.7) 90%,
    rgba(255, 255, 255, 0.85) 100%
  );
  width: 100%;
  height: 20px;
  z-index: 999;
  bottom: 0;
  //border-bottom-left-radius: 4px;
  //border-bottom-right-radius: 4px;
`
