import React from 'react'
import { defineMessages } from 'react-intl.macro'
import Page from '../../components/Page'
import Text from '../../components/Text'
import Box from '../../components/Box'
import Header from '../../components/Header'
import Wrapper from '../../components/Wrapper'
import Button from '../../components/Button'
import Image from '../../components/Image'
import { formatMessages } from '../../i18n/utils'

const messageDescriptors = defineMessages({
  authedNotFoundTitle: {
    id: 'containers.NotFound.authedNotFoundTitle',
    defaultMessage: 'Sorry, page not found!'
  },
  authedNotFoundDesc: {
    id: 'containers.NotFound.authedNotFoundDesc',
    defaultMessage: 'A link might have expired or something broke 😭'
  },
  invalidPage: {
    id: 'containers.NotFound.invalidPage',
    defaultMessage: "Unfortunately, that page doesn't seem to exist."
  },
  somethingMoved: {
    id: 'containers.NotFound.somethingMoved',
    defaultMessage: 'Looks like something might have moved :('
  },
  btnHome: {
    id: 'containers.NotFound.btnHome',
    defaultMessage: 'Home'
  }
})

export default ({ isUserAuthenticated }) => {
  const messages = formatMessages(messageDescriptors)

  return (
    <Page bodyClassName="white" title="Page not found">
      {isUserAuthenticated ? (
        <Wrapper py={[4, 4, 5, 6]}>
          <Header
            title={messages.authedNotFoundTitle}
            description={messages.authedNotFoundDesc}
          />
          <Box maxWidth={4} mx="auto" pt={[4, 4, 6]}>
            <Image src={`${process.env.PUBLIC_URL}/images/oops.svg`} />
          </Box>
        </Wrapper>
      ) : (
        <Wrapper py={[5, 6]} textAlign="center" maxWidth={6}>
          <Text
            as="h1"
            mt={[4, 4, 6, 8]}
            fontSize={['28px', '28px', '42px', '46px']}
            lineHeight="1.2"
            fontWeight={2}
            mb={0}
            color="gray.4">
            {messages.invalidPage}
          </Text>
          <Text as="p" fontSize={5} mb={6} color="gray.2">
            {messages.somethingMoved}
          </Text>
          <Button as="a" href="/" lineHeight="3.2" fontSize={4} px={5} positive>
            {messages.btnHome}
          </Button>

          <Box maxWidth={5} mx="auto" pt={[4, 4, 6]}>
            <Image src={`${process.env.PUBLIC_URL}/images/oops.svg`} />
          </Box>
        </Wrapper>
      )}
    </Page>
  )
}
