import React, { useMemo } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import moment from 'moment/moment'
import { useGetSubscriptionMailQuery } from '../../../redux/Api/SubscriptionsApi'
import Subtitle from './Subtitle'
import { useSubscriptions } from '../context/SubscriptionsProvider'
import './styles.css'
import { formatIframeString } from '../../../global/IframeUtilities'

const StyledTitle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  iframe {
    width: 100%;
    height: 100%;
  }

  .spinner {
    padding-top: 20px;
    display: block;
    margin: auto;
    width: 50px;
    height: 50px;
    color: #3cc2c8;
    --bs-spinner-border-width: 4px;
  }

  .header-item {
    color: black;
    font-weight: bold;
    align-items: center;
    padding: 15px;
    padding-bottom: 10px;
    text-align: center;
    display: flex;
    font-size: 19.8px;
  }

  .header-item button {
    color: rgb(40, 40, 40, 0.25);
    font-size: 18px;
    width: 27px;
    padding: 0;
    margin: auto;
  }

  .buttons {
    margin-bottom: 15px;
  }

  .buttons div div {
    text-align: center;
  }
`

const RollupDateLine = styled.div`
  margin: -5px auto 5px;
  color: rgb(90, 90, 90) !important;
  font-weight: 500;
  font-size: 16px;
`

const FullScreenPreviewModal = () => {
  const {
    selectedSubscription,
    showFullPreview,
    setShowFullPreview,
    selectNextSubscription,
    selectPreviousSubscription,
    type
  } = useSubscriptions()
  const { data, isLoading, isFetching, isError } = useGetSubscriptionMailQuery(
    selectedSubscription.links.self
  )

  const rollupDate = useMemo(() => {
    const date = moment(selectedSubscription.created_at)
    return date.format('LLL')
  }, [selectedSubscription])

  return (
    <Modal
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={showFullPreview}
      onHide={() => {
        setShowFullPreview(false)
      }}
      dialogClassName="full-screen-modal"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-center">
          <StyledTitle>
            <div style={{}}>
              <div className="header-item">
                <div
                  style={{
                    width: '100%',
                    paddingRight: '10px'
                  }}>
                  {(selectedSubscription && selectedSubscription.name) ||
                    selectedSubscription.subject}
                </div>
              </div>
            </div>
            {type === 'rolledup' && (
              <RollupDateLine>
                Rollup date:{' '}
                <span style={{ color: '#808080', fontWeight: 600 }}>
                  {rollupDate}
                </span>
              </RollupDateLine>
            )}
            <Subtitle />
          </StyledTitle>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading || isFetching ? (
          <Spinner
            className="spinner"
            animation="border"
            style={{ margin: 'auto auto' }}
          />
        ) : isError ? (
          <div
            style={{
              paddingTop: 40,
              height: '100%',
              textAlign: 'center',
              margin: 'auto'
            }}>
            <h2 style={{ paddingBottom: '5px' }}>Couldn't load your email</h2>
            <h4>It seems something goes wrong when loading your email</h4>
          </div>
        ) : (
          <iframe
            style={{
              width: '100%',
              height: '100%',
              borderBottomLeftRadius: 15,
              borderBottomRightRadius: 15
            }}
            title=""
            srcDoc={formatIframeString(data)}
            allowFullScreen
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

export default FullScreenPreviewModal
