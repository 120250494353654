import React from 'react'
import { Link } from 'react-router-dom'
import { defineMessages } from 'react-intl.macro'
import Wrapper from '../Wrapper'
import Box from '../Box'
import Text from '../Text'
import Button from '../Button'
import Logo from '../Logo'
import { formatMessages, formatDate } from '../../i18n/utils'

const messageDescriptors = defineMessages({
  home: {
    id: 'components.Footer.home',
    defaultMessage: 'Home'
  },
  features: {
    id: 'components.Footer.features',
    defaultMessage: 'Features'
  },
  support: {
    id: 'components.Footer.support',
    defaultMessage: 'Support'
  },
  press: {
    id: 'components.Footer.press',
    defaultMessage: 'Press Inquiries'
  },
  status: {
    id: 'components.Footer.status',
    defaultMessage: 'Status'
  },
  contact: {
    id: 'components.Footer.contact',
    defaultMessage: 'Contact Us'
  },
  signup: {
    id: 'components.Footer.signup',
    defaultMessage: 'Signup'
  },
  rightsReserved: {
    id: 'components.Footer.rightsReserved',
    defaultMessage: 'Nielsen Consumer LLC. All rights reserved.'
  },
  tos: {
    id: 'components.Footer.tos',
    defaultMessage: 'Terms of Service'
  },
  privacy: {
    id: 'components.Footer.privacy',
    defaultMessage: 'Privacy Policy'
  },
  andSign: {
    id: 'components.Footer.andSign',
    defaultMessage: '&'
  },
  dot: {
    id: 'components.Footer.dot',
    defaultMessage: '.'
  },
  dataPermissionsTextP1: {
    id: 'components.Footer.dataPermissionsTextP1',
    defaultMessage:
      'We use your data to fuel our market research business, NielsenIQ.'
  },
  dataPermissionsTextP2: {
    id: 'components.Footer.dataPermissionsTextP2',
    defaultMessage: 'You can manage your data permissions here:'
  },
  dataPermissionsTextP3: {
    id: 'components.Footer.dataPermissionsTextP3',
    defaultMessage: 'Do Not Sell my personal information.'
  }
})

const Footer = () => {
  const messages = formatMessages(messageDescriptors)

  return (
    <Box
      bg="white"
      py={6}
      borderTop={1}
      borderColor="gray.1"
      textAlign="center">
      <Wrapper>
        <Box href="/" as="a" width="100px" display="inline-block">
          <Logo />
        </Box>
        <Box mt={2} mb={1}>
          <Button
            link
            px={2}
            fontSize={2}
            display={['block', 'block', 'inline-block']}
            width={['100%', '100%', 'auto']}
            as="a"
            href="/">
            {messages.home}
          </Button>
          <Button
            link
            px={2}
            fontSize={2}
            display={['block', 'block', 'inline-block']}
            width={['100%', '100%', 'auto']}
            as="a"
            href="/features">
            {messages.features}
          </Button>
          <Button
            link
            px={2}
            fontSize={2}
            display={['block', 'block', 'inline-block']}
            width={['100%', '100%', 'auto']}
            as="a"
            href="https://unrollme.zendesk.com/hc">
            {messages.support}
          </Button>
          <Button
            link
            px={2}
            fontSize={2}
            display={['block', 'block', 'inline-block']}
            width={['100%', '100%', 'auto']}
            as="a"
            href="mailto:media.relations@nielseniq.com">
            {messages.press}
          </Button>
          <Button
            link
            px={2}
            fontSize={2}
            display={['block', 'block', 'inline-block']}
            width={['100%', '100%', 'auto']}
            as="a"
            href="https://unrollme.zendesk.com/hc/requests/new">
            {messages.contact}
          </Button>
          <Button
            link
            px={2}
            fontSize={2}
            display={['block', 'block', 'inline-block']}
            width={['100%', '100%', 'auto']}
            as={Link}
            to="/signup">
            {messages.signup}
          </Button>
        </Box>
        <Text fontSize={2}>{messages.dataPermissionsTextP1}</Text>
        <Text fontSize={2}>
          {`${messages.dataPermissionsTextP2} `}
          <Link to="/data">{messages.dataPermissionsTextP3}</Link>
        </Text>
        <Text fontSize={2} mt={2}>
          © {formatDate(new Date(), { year: 'numeric' })}{' '}
          {messages.rightsReserved} <a href="/legal/terms">{messages.tos}</a>{' '}
          {messages.andSign} <a href="/legal/privacy">{messages.privacy}</a>
          {messages.dot}
        </Text>
      </Wrapper>
    </Box>
  )
}

export default Footer
