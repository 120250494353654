import styled, { css } from 'styled-components'
import { darken } from 'polished'
import theme from '../../global/theme'

const Input = styled.input`
  width: 100%;
  display: block;
  border-radius: ${theme.radii[1]}px;
  font-weight: 400;
  border: 1px solid ${theme.colors.gray[1]};
  font-size: ${theme.fontSizes[3]}px;
  line-height: 2rem;
  padding: 1em;
  background-color: white;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: ${theme.shadows[1]};
  }

  &:focus {
    border-color: ${darken(0.1, theme.colors.gray[1])};
    outline: 0;
    box-shadow: ${theme.shadows[1]};
  }

  ${props =>
    props.error &&
    css`
      border-color: ${theme.colors.red};
      &:focus {
        border-color: ${darken(0.1, theme.colors.red)};
      }
    `};
`

export { Input }
