import React from 'react'
import styled, { keyframes } from 'styled-components'
import { defineMessages } from 'react-intl.macro'
import { useSelector } from 'react-redux'
import Box from '../Box'
import Text from '../Text'
import theme from '../../global/theme'
import { formatMessages } from '../../i18n/utils'
import WelcomeStep from '../../containers/Welcome/WelcomeStep'
import WelcomeInstructions from '../new/WelcomeInstructions'

const messageDescriptors = defineMessages({
  loading: {
    id: 'components.Loading.loading',
    defaultMessage: 'Loading...'
  }
})

const Loading = ({ icon, fontWeight, hidden, empty, ...props }) => {
  const messages = formatMessages(messageDescriptors)
  const isUserPolling = useSelector(state => state.app.isUserPolling)

  return (
    <>
      {isUserPolling ? (
        <>
          <WelcomeStep imageMaxWidth="600px" image="InboxLoading.gif">
            <Text
              as="p"
              fontWeight={3}
              m={0}
              fontSize={[4, 4, 5]}
              color="gray.3"
              textAlign="center"
              lineHeight={1.3}>
              We are looking for email subscriptions. Hang tight…
            </Text>
          </WelcomeStep>
          <WelcomeInstructions />
        </>
      ) : (
        <LoadingWrapper
          as="span"
          position="relative"
          display={hidden ? 'none' : 'inline-block'}
          {...props}>
          {icon ? (
            <Ball
              position="relative"
              display="inline-block"
              width="12px"
              mr={2}
            />
          ) : null}

          {!empty && (
            <Text
              as="span"
              display="inline-block"
              color="gray.2"
              fontWeight={2}>
              {messages.loading}
            </Text>
          )}
        </LoadingWrapper>
      )}
    </>
  )
}

const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const LoadingWrapper = styled(Box)`
  opacity: 0;
  animation: ${fade} 1000ms ease-in;
  animation-fill-mode: forwards;
`

const bounce = keyframes`
  0% {
    top: -6px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: 14px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: -24px;
  }
`

const Ball = styled(Box)`
  &:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${theme.colors.green};
    transform-origin: 50%;
    animation: ${bounce} 500ms alternate infinite ease;
  }
`

export default Loading
