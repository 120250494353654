import styled, { css } from 'styled-components'
import {
  space,
  color,
  width,
  fontSize,
  fontWeight,
  fontStyle,
  textAlign,
  maxWidth,
  display,
  lineHeight,
  textStyle,
  height
} from 'styled-system'

const Text = styled.div`
  ${space}
  ${color}
  ${width}
  ${fontSize}
  ${fontWeight}
  ${fontStyle}
  ${textAlign}
  ${maxWidth}
  ${display}
  ${lineHeight}
  ${textStyle}
  

  
  ${props =>
    props.whiteSpace &&
    css`
      white-space: ${props.whiteSpace || 'normal'};
    `}

  ${props =>
    props.trunc &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  ${height}
`

export default Text
