import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import {
  useGetSubscriptionsByStateQuery,
  useLoadMoreSubscriptionsMutation
} from '../../../redux/Api/SubscriptionsApi'
import SubscriptionItem from '../SubscriptionItem/SubscriptionItem'
import SearchInput from '../SearchInput'
import SubscriptionTypeSelector from '../SubscriptionTypeSelector'
import Sorter from '../Sorter'
import EmptySubscriptions from './EmptySubscriptions'
import { logout } from '../../../containers/App/AppActions'
import { useSubscriptions } from '../context/SubscriptionsProvider'
import { useAuth } from '../../../hook/useAuth'
import AuthAccountModal from '../AuthAccountModal'
import SubscriptionGroup from '../SubscriptionItem/SubscriptionGroup'

const Styled = styled.div`
  width: auto;

  .spinner {
    padding-top: 20px;
    display: block;
    margin: auto;
    width: 50px;
    height: 50px;
    color: #3cc2c8;
    --bs-spinner-border-width: 4px;
  }

  .spinner.keep {
    color: #3cc2c8 !important;
  }

  .spinner.blocked {
    color: #c83c3c !important;
  }

  .spinner.rollup {
    color: #f7931d !important;
  }

  .subscriptions {
    overflow-y: auto;
    padding-right: 10px;

    height: calc(
      100vh - ${props => (props.type === 'subscriptions' ? '285px' : '220px')}
    );
  }

  .show-more {
    display: flex;
    margin: auto;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 0;
    text-decoration: none;
  }

  .show-more > svg {
    font-size: 30px;
    font-weight: 900;
    margin-left: 7px;
  }

  .rolledup-show-more {
    color: #3cc2c8 !important;
  }

  .keep-show-more {
    color: #3cc2c8 !important;
  }

  .blocked-show-more {
    color: #c83c3c !important;
  }

  .rollup-show-more {
    color: #f7931d !important;
  }
`

const InitialScanWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  --bs-spinner-border-width: 4px;

  & > img {
    height: 150px;
  }
`

const InitialScanText = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #848484;
  text-align: center;
`

const getStateByTypeAndSubtype = (type, subtype) => {
  if (type === 'new') {
    return 'new'
  }
  if (type === 'rolledup') {
    return 'rolledup'
  }
  if (subtype === 'keep') {
    return 'inbox'
  } else if (subtype === 'blocked') {
    return 'blocked'
  } else if (subtype === 'rollup') {
    return 'rollup'
  }
}
const SubscriptionsContainer = ({ type, selectedSubscription }) => {
  const context = useSubscriptions()
  const { getEmailProvider } = useAuth()
  const scrollToRef = useRef(null)

  const [selectedProvider, setSelectedProvider] = useState()
  const [selectedEmail, setSelectedEmail] = useState()
  const [showAuthModal, setShowAuthModal] = useState(false)

  const { subtype } = useParams()
  const user = useSelector(state => state.app.user)
  const [searchParams, setSearchParams] = useSearchParams()

  const isScanning = useMemo(() => {
    return user?.scan_status === 'in_progress'
  }, [user])

  useEffect(() => {
    if (selectedSubscription && context.data && type === 'rolledup') {
      const selectedRollup = context.data.data.find(
        rollup => rollup.id === selectedSubscription.id
      )
      context.setSelectedSubscription(selectedRollup)
    }
  }, [context.data])

  useEffect(() => {
    const email = searchParams.get('selected')
    const subscriptionId = +searchParams.get('subscriptionId')
    if (context.data?.data?.length && user?.email && email) {
      if (user.email === email) {
        const selectedRollup = context.data.data.find(
          rollup => rollup.id === subscriptionId
        )
        context.setSelectedSubscription(selectedRollup)
      } else {
        getEmailProvider(email).then(provider => {
          if (provider) {
            setSelectedProvider(provider)
            setSelectedEmail(email)
            setShowAuthModal(true)
          }
        })
        // logout()
      }
    }
  }, [context.data, user])

  const sid = searchParams.get('subscriptionId')
  useEffect(() => {
    if (scrollToRef.current && context.sortedData.length) {
      const selected = context.sortedData
        .flat(1)
        .find(subscription => subscription.id.toString() === sid)
      if (selected) {
        scrollToRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
        setTimeout(() => context.changeSelectedSubscription(selected), 300)
        searchParams.delete('subscriptionId')
        setSearchParams(searchParams)
      }
    }
  }, [
    scrollToRef,
    context.isLoadingSubscriptions,
    context.isFetchingSubscriptions
  ])

  useEffect(() => {
    context.setSearchStr('')
    // setSearchQuery("")
    context.setSelectedSubscription(undefined)
    context.setOrder(type === 'rolledup' ? 'created_at' : 'domain')
    context.setDirection(type === 'rolledup' ? 'desc' : 'asc')
  }, [type, subtype, user.id])

  return (
    <Styled type={context.type}>
      <SearchInput
        handleSearch={context.setSearchStr}
        searchStr={context.searchStr}
      />
      {context.type === 'subscriptions' && <SubscriptionTypeSelector />}
      {isScanning && (
        <InitialScanWrapper>
          <img
            alt="loading"
            src={`${process.env.PUBLIC_URL}/images/onboarding/InboxLoading.gif`}
          />
          <InitialScanText>
            We are looking for email subscriptions. Hang tight…
          </InitialScanText>
        </InitialScanWrapper>
      )}
      {!isScanning &&
        (context.isLoadingSubscriptions ||
          context.isFetchingSubscriptions ||
          !user.id) && (
          <Spinner className={`spinner ${subtype}`} animation="border" />
        )}
      {!isScanning &&
        !context.isLoadingSubscriptions &&
        !context.isFetchingSubscriptions && (
          <>
            {context.data && context.data.data?.length > 0 && (
              <Sorter
                order={context.order}
                setOrder={context.setOrder}
                direction={context.direction}
                setDirection={context.setDirection}
              />
            )}
            <div className="subscriptions xs-12">
              {user.id &&
              (!context.sortedData ||
                !context.sortedData ||
                context.sortedData.length === 0) ? (
                <EmptySubscriptions
                  type={context.type}
                  subtype={context.subtype}
                />
              ) : (
                context.sortedData?.map(subscription => {
                  return (
                    <>
                      {Array.isArray(subscription) ? (
                        <div
                          ref={
                            subscription.some(
                              subsc => subsc.id?.toString() === sid
                            )
                              ? scrollToRef
                              : null
                          }
                          data-sid={sid}
                          data-id={subscription.id}
                          key={subscription.id}>
                          <SubscriptionGroup
                            key={`${subscription[0].domain}-group`}
                            subscriptions={subscription}
                            type={context.type}
                            subtype={context.subtype}
                            isOpen={subscription.some(
                              subsc => subsc.id?.toString() === sid
                            )}
                            setSelectedSubscription={
                              context.changeSelectedSubscription
                            }
                            selectedSubscription={context.selectedSubscription}
                          />
                        </div>
                      ) : (
                        <div
                          ref={
                            subscription.id?.toString() === sid
                              ? scrollToRef
                              : null
                          }
                          data-sid={sid}
                          data-id={subscription.id}
                          key={subscription.id}>
                          <SubscriptionItem
                            key={subscription.id}
                            ref={
                              subscription.id?.toString() === sid
                                ? scrollToRef
                                : null
                            }
                            subscription={subscription}
                            type={context.type}
                            subtype={context.subtype}
                            setSelectedSubscription={
                              context.changeSelectedSubscription
                            }
                            selectedSubscription={context.selectedSubscription}
                            // searchStr={searchQuery}
                          />
                        </div>
                      )}
                    </>
                  )
                })
              )}
            </div>
          </>
        )}
      {['yahoo', 'outlook', 'aol'].includes(selectedProvider) &&
        showAuthModal && (
          <AuthAccountModal
            show={showAuthModal}
            setShow={setShowAuthModal}
            email={selectedEmail}
            provider={selectedProvider}
          />
        )}
    </Styled>
  )
}

export default SubscriptionsContainer
