import { Modal } from 'react-bootstrap'
import React from 'react'
import styled from 'styled-components'

import { ConnectYourMailbox } from '../SignupMailbox/pages/ConnectYourMailbox'

const Styled = styled.div`
  .modal-title {
    font-size: 22px;
    font-weight: bold;
    color: #404040;
    padding-left: 40px;
    margin: auto;
    text-transform: capitalize;
  }

  .modal-title svg {
    margin: auto;
    font-size: 28px;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .modal-header {
    padding-top: 10px;
    height: 85px;
    border-bottom: none;
  }

  .modal-header button {
    margin: 0 !important;
    margin-right: 20px !important;
    font-size: 10px;
    padding: 10px;
    border: 1px solid rgb(0, 0, 0, 0.5);
    border-radius: 50%;
  }
`

const ConsentModal = ({ show, onClose, onButtonClick }) => {
  return (
    <Modal show={show} centered={true} onHide={onClose} size={'lg'}>
      <Styled>
        <Modal.Header closeButton>
          <Modal.Title>Connect your Mailbox</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ConnectYourMailbox nextStep={onButtonClick} hideTitle />
        </Modal.Body>
      </Styled>
    </Modal>
  )
}

export default ConsentModal
