import React, { createContext, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { axiosMethods as axios } from '../global/axiosHelpers'
import { useNotifications } from './Notifications'
import { CONTACT_SUPPORT_TEAM } from '../components/new/constants'
import { SupportTeamLink } from '../components/new/constants/styled'
import store from '../redux/store'
import {
  addNotification,
  removeNotification,
  updateUserReauth
} from '../redux/slice/app.slice'

export const ArchiveContext = createContext(null)

export const ArchiveProvider = ({ children }) => {
  const [archives, setArchives] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const user = useSelector(state => state.app.user)
  const { notifySuccess, notifyError } = useNotifications()

  const handleCreateArchive = async () => {
    setIsLoading(true)
    const originalEmail = user?.email
    try {
      await axios.post(`${process.env.REACT_APP_API}/archives`)
      notifySuccess(
        'Preparing a copy of your Unroll.Me data has started. Please be patient as it may take some time to complete.',
        5000
      )
    } catch (e) {
      notifyError(
        <>
          Something goes wrong when preparing a copy of your Unroll.Me data.
          Please contact <CONTACT_SUPPORT_TEAM />
        </>
      )
      return
    }
    try {
      for (let i = 0; i < 200; i++) {
        const promise = new Promise(resolve => setTimeout(resolve, 3000))
        await promise
        if (originalEmail !== store.getState().app?.user?.email) {
          setIsLoading(false)
          await refetchArchives()
          return
        }

        const res = await axios.get(`${process.env.REACT_APP_API}/archives`)
        setArchives(res?.data?.data)
        if (res?.data?.data?.[0]?.link) {
          notifySuccess(
            <>
              A copy of your Unroll.Me data is now available. You can download
              it by{' '}
              <SupportTeamLink target="_blank" href={res?.data?.data[0].link}>
                the Link
              </SupportTeamLink>{' '}
              or check your mailbox to access it.
            </>,
            15000
          )
          break
        }
      }
    } catch (e) {
      notifyError(
        <>
          Something goes wrong when fetching Unroll.Me data. Please contact{' '}
          <CONTACT_SUPPORT_TEAM />
        </>
      )
    }
    setIsLoading(false)
  }

  const refetchArchives = async () => {
    if (user?.email) {
      setIsLoading(true)
      try {
        const archives = await axios.get(
          `${process.env.REACT_APP_API}/archives`
        )
        setArchives(archives?.data?.data ?? [])
      } catch (e) {
        if (e.response?.status === 401) {
          e.message = 'Please login and reload page'
          console.error('response data:', e.response)
          store.dispatch(updateUserReauth(true))
        } else if (e.response?.status === 403) {
          e.message =
            "You haven't permissions to the current resource. Please contact admin"
          store.dispatch(updateUserReauth(true))
        } else if (e.message === 'Network Error') {
          const id = uuid()
          store.dispatch(
            addNotification({
              id: id,
              message: <>Looks like something goes wrong with your network.</>,
              type: 'danger'
            })
          )
          setTimeout(() => {
            store.dispatch(removeNotification(id))
          }, 7000)
        } else {
          notifyError(
            <>
              Something goes wrong when fetching Unroll.Me data. Please contact{' '}
              <CONTACT_SUPPORT_TEAM />
            </>
          )
        }
      }
      setIsLoading(false)
    }
  }

  const recentArchive = archives[0]

  const btnDisabled = useMemo(() => {
    return (
      isLoading ||
      (recentArchive &&
        moment(recentArchive.requested_at).isSame(moment(), 'day'))
    )
  }, [isLoading, recentArchive, archives])

  const value = {
    handleCreateArchive,
    archives,
    refetchArchives,
    isLoading,
    btnDisabled
  }

  return (
    <ArchiveContext.Provider value={value}>{children}</ArchiveContext.Provider>
  )
}

export const useArchives = () => useContext(ArchiveContext)
