import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'

import styled from 'styled-components'
import { useAuth } from '../../../hook/useAuth'
import { ReactComponent as AccountDisconnected } from '../../../../src/components/Icon/accountDisconnected.svg'
import { updateAddMailboxModal } from '../../../redux/slice/app.slice'

const Styled = styled.div`
  font-size: 18px;

  .modal-title {
    font-size: 22px;
    font-weight: bold;
    color: #404040;
    padding-left: 40px;
    margin: auto;
  }

  .modal-title svg {
    margin: auto;
    font-size: 28px;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .modal-header {
    padding-top: 10px;
    height: 85px;
    border-bottom: none;
  }

  .modal-header button {
    margin: 0 !important;
    margin-right: 20px !important;
    font-size: 10px;
    padding: 10px;
    border: 1px solid rgb(0, 0, 0, 0.5);
    border-radius: 50%;
  }

  .modal-body {
    color: rgb(0, 0, 0, 0.6);
    font-size: 18px;
    padding: 0px 30px 20px;
    text-align: center;
  }

  .delete-account-btn {
    font-size: 16px;
    font-weight: 700;
    padding: 10px;
    margin: 15px auto;
    width: auto;
    padding-left: 0;
    border: none;
    text-decoration: none;
    color: #4ab9ba;
  }

  .delete-account-btn:hover {
    color: #4ab9ba;
    opacity: 0.7;
  }
`

const customOauthProviders = ['google', 'icloud']
const ReauthAccountModal = ({ show, setShowSettings }) => {
  const dispatch = useDispatch()
  const selectedUser = useSelector(state => state.app.user)
  const {
    logoutCurrentUser,
    showAddMailboxWindow,
    getEmailProvider
  } = useAuth()

  const handleReauthAccount = async () => {
    const provider =
      selectedUser.service_provider ??
      (await getEmailProvider(selectedUser.email))
    if (provider) {
      if (customOauthProviders.includes(provider)) {
        dispatch(
          updateAddMailboxModal({
            show: true,
            type: provider
          })
        )
      } else {
        showAddMailboxWindow(provider)
      }
    }

    setShowSettings(false)
    logoutCurrentUser()
  }

  const handleClose = () => {
    setShowSettings(false)
    logoutCurrentUser()
  }

  return (
    <Modal show={show} centered={true} onHide={handleClose}>
      <Styled>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>
              <AccountDisconnected />
              Account Disconnected
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ marginTop: 10 }}>
            Something went wrong and Unroll.Me is having trouble connecting to
            your mailbox.
          </p>
          <p>
            <b>Please reconnect the mailbox for:</b>
          </p>
          <p>
            <b>{selectedUser.email}</b>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="delete-account-btn"
            onClick={handleReauthAccount}>
            Reconnect Mailbox
          </Button>
        </Modal.Footer>
      </Styled>
    </Modal>
  )
}

export default ReauthAccountModal
