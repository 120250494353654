import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal, Spinner } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import { get } from 'lodash'
import Box from '../../components/Box'
import Text from '../../components/Text'
import Button from '../../components/Button'
import theme from '../../global/theme'
import { deleteAccount, submitEmail } from './DeleteActions'
import { Input } from '../../components/Form'

const CodeInput = styled(Input)`
  color: black;
  border: 0;
  margin: auto;
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solid #646464;
`

const Styled = styled.div`
  .modal-title {
    font-size: 22px;
    font-weight: bold;
    color: #404040;
    padding-left: 40px;
    margin: auto;
  }

  .modal-header button {
    margin: 0 !important;
    margin-right: 15px !important;
    font-size: 10px;
    padding: 10px;
    border: 1px solid rgb(0, 0, 0, 0.5);
    border-radius: 50%;
  }

  .modal-header {
    padding-top: 10px;
    height: 60px;
  }

  .close-btn:hover {
    color: white;
  }
`

const messages = {
  titleVerify: 'Verification Code',
  titleDeleted: 'Account Deleted',
  verifyText:
    'A verification code has been sent to the email address you provided. Please check your email and enter the code below to continue deleting your account. ',
  deletedText: `Your account has successfully been deleted. We appreciate all our customers, and if you decide you’d like to use Unroll.Me again in the future, we’ll be here.`,
  didntReceiveCodeText: `Didn't receive a Code?`,
  codePlaceholder: 'Enter verification code',
  btnDelete: 'Delete My Account',
  btnClose: 'Close',
  btnResend: 'Resend'
}

const DeleteAccountModalNew = ({ show, setShow, email }) => {
  const [showResendBtn, setShowResendBtn] = useState(false)
  const [successDeleted, setSuccessDeleted] = useState(false)
  const [code, setCode] = useState('')

  useEffect(() => {
    if (code) {
      setShowResendBtn(false)
    }

    const timeout = setTimeout(() => {
      if (!code) {
        setShowResendBtn(true)
      }
    }, 30000)

    return () => {
      clearTimeout(timeout)
    }
  }, [code, showResendBtn])

  const closeModal = () => {
    setShow(false)
    document.location.href = '/app/inbox'
  }

  return (
    <Modal show={show} onHide={closeModal}>
      <Styled>
        <Modal.Header
          closeButton
          onHide={() => {
            document.location.href = '/app/inbox'
          }}>
          <Modal.Title id="contained-modal-title-center">
            Delete Account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {successDeleted ? (
            <Box width="80%" margin="auto">
              <Text
                as="p"
                mt={6}
                mb={4}
                textAlign="center"
                color="black"
                fontSize={['15px', '15px', '16px', '16px']}>
                {messages.deletedText}
              </Text>

              <Box display="flex">
                <Button
                  className="close-btn"
                  bg="black"
                  as="a"
                  href="/"
                  color="white"
                  margin="auto"
                  borderRadius="25px"
                  lineHeight={['2.4', '3.4']}
                  mt={6}
                  width={['220px', '220px', '250px', '350px']}
                  fontSize={['15px', '15px', '16px', '16px']}
                  display="block">
                  {messages.btnClose}
                </Button>
              </Box>
            </Box>
          ) : (
            <Formik
              initialValues={{
                // email: parsedSearch.email ? parsedSearch.email : '',
                email: email,
                code: null
              }}
              validateOnChange={false}
              validate={values => {
                const errors = {}

                if (!values.code || values.code.length < 6) {
                  errors.code =
                    'The 6-digit code you entered was incorrect. Try again.'
                } else if (!/^[0-9]{6}$/i.test(values.code)) {
                  errors.code =
                    'Please enter the valid numeric code from the email.'
                }

                return errors
              }}
              onSubmit={(values, actions) => {
                deleteAccount(values.email, values.code)
                  .then(() => {
                    actions.setSubmitting(false)
                    setSuccessDeleted(true)
                  })
                  .catch(err => {
                    actions.setSubmitting(false)
                    actions.setErrors({
                      code: err.message
                    })
                  })
              }}>
              {formProps => (
                <Form>
                  <Box width="80%" margin="auto">
                    <Text
                      as="p"
                      mt={4}
                      textAlign="center"
                      color="black"
                      fontSize={['16px', '16px', '16px', '16px']}>
                      {messages.verifyText}
                    </Text>
                    {showResendBtn && (
                      <Box mt={5} height="36px">
                        <Text
                          as="p"
                          textAlign="center"
                          fontWeight="bold"
                          color="black"
                          fontSize={['16px', '16px', '16px', '16px']}>
                          {messages.didntReceiveCodeText}
                          <Button
                            link
                            color={theme.colors.blue}
                            mb={1}
                            display={['block', 'block', 'inline-block']}
                            width={['100%', '100%', 'auto']}
                            fontSize={['17px', '17px', '18px', '18px']}
                            as="a"
                            onClick={() => {
                              submitEmail(formProps.values.email, true)
                                .then(() => {
                                  formProps.setErrors({})
                                  setShowResendBtn(false)
                                })
                                .catch(err => {
                                  let errMsg =
                                    'Something went wrong. Try again.'

                                  if (
                                    err.response &&
                                    err.response.status === 400
                                  ) {
                                    errMsg = get(
                                      err,
                                      'response.data.detail',
                                      'Email address is not associated with an Unroll.Me account'
                                    )
                                  }

                                  formProps.setErrors({ email: errMsg })
                                })
                            }}>
                            {messages.btnResend}
                          </Button>
                        </Text>
                      </Box>
                    )}

                    {formProps.errors.email && (
                      <Box
                        textAlign="center"
                        color="red"
                        mt={2}
                        fontSize={['17px', '17px', '16px', '16px']}>
                        {formProps.errors.email}
                      </Box>
                    )}
                    <Box
                      textAlign="center"
                      color="red"
                      mt={4}
                      style={{ height: 25, marginTop: 0 }}
                      fontSize={['17px', '17px', '16px', '16px']}>
                      {formProps.errors.code}
                    </Box>
                    <Box
                      margin="auto"
                      width={['220px', '220px', '250px', '350px']}>
                      <CodeInput
                        placeholder={messages.codePlaceholder}
                        name="code"
                        maxLength="6"
                        value={formProps.values.code}
                        onChange={event => {
                          formProps.setErrors({})
                          setCode(event.target.value)
                          formProps.handleChange(event)
                        }}
                        // error={formProps.errors.email}
                      />
                    </Box>
                    <Box display="flex">
                      <Button
                        type="submit"
                        disabled={formProps.isSubmitting}
                        deleteAccount
                        margin="auto"
                        borderRadius="30px"
                        lineHeight={['2.4', '3.4']}
                        mt={5}
                        width={['220px', '220px', '250px', '350px']}
                        fontSize={['15px', '15px', '16px', '16px']}
                        display="block">
                        {formProps.isSubmitting ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              style={{ width: 18, height: 18, marginRight: 8 }}
                            />
                            Deleting...
                          </>
                        ) : (
                          <>{messages.btnDelete}</>
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </Styled>
    </Modal>
  )
}

export default DeleteAccountModalNew
