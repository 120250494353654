import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router'
import { parse } from 'query-string'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { lighten } from 'polished'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { defineMessages } from 'react-intl.macro'
import { Tooltip, TooltipArrow, TooltipInner } from 'styled-tooltip-component'
import Text from '../../components/Text'
import Button from '../../components/Button'
import Box from '../../components/Box'
import { Input } from '../../components/Form'
import { formatMessage, formatMessages } from '../../i18n/utils'
import theme from '../../global/theme'
import { ReactComponent as InfoIcon } from '../../components/Icon/InfoIcon.svg'
import PasswordField from './PasswordField'
import eventConstants from '../../global/eventConstants'
import { providerData } from '../../global/config'
import {
  authenticateUserForDirectAppPswdFlow,
  authorizeUserWithAppPassword
} from '../App/AppActions'
import WelcomeInstructions from '../../components/new/WelcomeInstructions'
import { useAuth } from '../../hook/useAuth'
import { updateAddMailboxModal } from '../../redux/slice/app.slice'

const PASSWORD_SANITIZATION_REGEX = /[ /_]/g

const sanitizePassword = (password = '') => {
  return password.replace(PASSWORD_SANITIZATION_REGEX, '')
}

export const messageDescriptors = defineMessages({
  connectPswd: {
    id: 'containers.GoogleSignup.ConnectPasswordStep.connectPswd',
    defaultMessage: 'Connect your inbox'
  },
  connectDesc: {
    id: 'containers.GoogleSignup.ConnectPasswordStep.connectDesc',
    defaultMessage:
      'Connect Unroll.me to your inbox. Enter the application password generated by {provider} to continue.',
    skipGroupFormatting: true
  },
  tos: {
    id: 'containers.GoogleSignup.ConnectPasswordStep.tos',
    defaultMessage: 'Terms of Service'
  },
  privacyPolicy: {
    id: 'containers.GoogleSignup.ConnectPasswordStep.privacyPolicy',
    defaultMessage: 'Privacy Policy'
  },
  continue: {
    id: 'containers.GoogleSignup.ConnectPasswordStep.continue',
    defaultMessage: 'Continue'
  },
  emailTitle: {
    id: 'containers.GoogleSignup.ConnectPasswordStep.emailTitle',
    defaultMessage: 'Email'
  },
  passwordTitle: {
    id: 'containers.GoogleSignup.ConnectPasswordStep.passwordTitle',
    defaultMessage: '16 digit {provider} app password',
    skipGroupFormatting: true
  },
  agreeTo: {
    id: 'containers.GoogleSignup.ConnectPasswordStep.agreeTo',
    defaultMessage:
      'By clicking continue, I understand that my email data will be used to support NielsenIQ’s e-commerce measurement business.'
  },
  passwordPrompt: {
    id: 'containers.GoogleSignup.ConnectPasswordStep.passwordPrompt',
    defaultMessage:
      "There are not enough characters in the password field. Ensure you’re entering the 16-digit app password generated by {provider} and try again. Or click 'back' for instructions",
    skipGroupFormatting: true
  },
  passwordIncorrectFormat: {
    id: 'containers.GoogleSignup.ConnectPasswordStep.passwordIncorrectFormat',
    defaultMessage:
      "Your app password should only contain lowercase letters. Ensure you’re entering the 16-digit app password generated by {provider} and try again. Or click 'back' for instructions",
    skipGroupFormatting: true
  },
  unrollmeEmailPrompt: {
    id: 'containers.GoogleSignup.ConnectPasswordStep.unrollmeEmailPrompt',
    defaultMessage: 'Please enter your Unroll.Me account email.'
  },
  emailNotRecognizedMsg: {
    id: 'containers.GoogleSignup.ConnectPasswordStep.emailNotRecognizedMsg',
    defaultMessage:
      'We didn’t recognize this email. Please check and try again.'
  },
  loginFailure: {
    id: 'containers.GoogleSignup.ConnectPasswordStep.loginFailure',
    defaultMessage:
      'We were unable to login with the password provided. Ensure it is the 16-digit application password generated by {provider} and try again.',
    skipGroupFormatting: true
  },
  passwordTooltip: {
    id: 'containers.GoogleSignup.ConnectPasswordStep.passwordTooltip',
    defaultMessage:
      'This is the app specific password you created in step 2, on the previous screen'
  }
})

const messages = formatMessages(messageDescriptors)

const getUserTypeFromQueryParams = params => {
  if (params.reauth) {
    return eventConstants.common.userType.reauthenticating
  }
  if (params.paidSignup) {
    return eventConstants.common.userType.paid
  }
  return eventConstants.common.userType.organic
}

// const track = (eventName, eventProps) => {
//   trackEvent(eventName, {
//     ...eventProps,
//     Page: eventConstants.appPasswordConnect.pageName
//   })
// }

// App Password validation methods
Yup.addMethod(Yup.string, 'isValidLength', function(providerLabel) {
  return this.test(
    'isValidLength',
    formatMessage(messageDescriptors.passwordPrompt, {
      provider: providerLabel
    }),
    (password = '') => {
      if (password.length === 0) {
        // track(eventConstants.appPasswordConnect.loginErrorEvent, {
        //   [eventConstants.appPasswordConnect.loginErrorProp]:
        //     'Empty Password (Validation)'
        // })
        return false
      }
      if (password.length !== 16) {
        // track(eventConstants.appPasswordConnect.loginErrorEvent, {
        //   [eventConstants.appPasswordConnect.loginErrorProp]:
        //     'Invalid Length (Validation)'
        // })
        return false
      }
      return true
    }
  )
})

Yup.addMethod(Yup.string, 'isValidAppPasswordPattern', function(providerLabel) {
  return this.test(
    'isValidAppPasswordPattern',
    formatMessage(messageDescriptors.passwordIncorrectFormat, {
      provider: providerLabel
    }),
    (password = '') => {
      if (password.length !== 16) {
        // Hack to run only 1 validator at a time
        return true
      }
      if (!/^[a-z]+$/.test(password)) {
        // track(eventConstants.appPasswordConnect.loginErrorEvent, {
        //   [eventConstants.appPasswordConnect.loginErrorProp]:
        //     'Invalid Format (Validation)'
        // })
        return false
      }
      return true
    }
  )
})

const ConnectPasswordStep = ({ setStep, email, provider }) => {
  const location = useLocation()
  const { loadNewUsers } = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [disableButton, handleDisableButton] = useState(false)
  const [showErrors, updateShowErrors] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const parsedSearch = parse(location.search)
  const providerConfig = providerData[provider]
  const providerLabel = providerConfig.name
  const isUserPolling = useSelector(state => state.app.isUserPolling)

  const AppPasswordValidationSchema = Yup.object().shape({
    password: Yup.string()
      .isValidLength(providerLabel)
      .isValidAppPasswordPattern(providerLabel),
    email: Yup.string()
      .required(messages.unrollmeEmailPrompt)
      .email(messages.emailNotRecognizedMsg)
  })

  return (
    <Fragment>
      <Formik
        initialValues={{
          email: email,
          password: '',
          checkbox: false
        }}
        validationSchema={AppPasswordValidationSchema}
        validateOnChange={false}
        onSubmit={values => {
          handleDisableButton(true)
          const authParams = {
            provider: provider,
            email: values.email,
            secret: values.password,
            checkbox: values.checkbox
          }

          if (parsedSearch.redirect) {
            authParams.redirect = parsedSearch.redirect
          }
          if (parsedSearch.reauth) {
            authParams.reauth = true
          }

          if (provider === 'icloud' || parsedSearch.directauth || true) {
            authenticateUserForDirectAppPswdFlow(authParams)
              .then(() => {
                loadNewUsers()
                dispatch(
                  updateAddMailboxModal({ show: false, type: undefined })
                )

                // navigate('/app/inbox')
              })
              .catch(() => {
                handleDisableButton(false)
              })
          }
          // else {
          //   authorizeUserWithAppPassword(authParams)
          //     .then(() => navigate('/app/inbox'))
          //     .catch(() => {
          //       handleDisableButton(false)
          //     })
          // }
        }}
        render={formProps => (
          <form
            onSubmit={values => {
              updateShowErrors(true)
              formProps.handleSubmit(values)
            }}>
            <Text
              fontWeight="bold"
              fontSize={['22px', '25px', '30px', '45px']}
              lineHeight="1.5"
              mt="20px">
              {messages.connectPswd}
            </Text>
            <Text
              fontSize={['15px', '15px', '16px', '18px']}
              lineHeight="1.5"
              margin="0 auto"
              width={['300px', '330px', '550px', '670px']}
              color={theme.colors.gray[2]}>
              {formatMessage(messageDescriptors.connectDesc, {
                provider: providerLabel
              })}
            </Text>
            <Box
              mt={['20px']}
              width={['250px', '280px', '300px', '320px']}
              margin="0 auto">
              <Box mt="10px" margin="auto">
                <Box mb={['8px']}>
                  <Text
                    fontWeight="bold"
                    textAlign="left"
                    color={theme.colors.gray[2]}>
                    {messages.emailTitle}:
                  </Text>
                </Box>
                <Input
                  placeholder={messages.emailPlaceholder}
                  name="email"
                  value={formProps.values.email}
                  disabled
                  onChange={formProps.handleChange}
                  error={showErrors && formProps.errors.email}
                />
                {showErrors && formProps.errors.email && (
                  <Box textAlign="left" color="red">
                    {formProps.errors.email}
                  </Box>
                )}
              </Box>
              <Box mt="25px" margin="auto">
                <Box display="flex" mb={['8px']}>
                  <Text
                    fontWeight="bold"
                    textAlign="left"
                    color={theme.colors.gray[2]}>
                    {formatMessage(messageDescriptors.passwordTitle, {
                      provider: providerLabel
                    })}
                    :
                  </Text>
                  <Box width="24px" height="24px" ml={['5px']} mt={['5px']}>
                    <InfoIcon
                      onMouseLeave={() => setShowTooltip(false)}
                      onMouseEnter={() => setShowTooltip(true)}
                    />
                    <Tooltip
                      hidden={!showTooltip}
                      style={{
                        position: 'relative',
                        top: '-106px',
                        width: '250px',
                        right: '35px',
                        opacity: 1,
                        transform: 'translateX(-50%)',
                        background: lighten(0.3, theme.colors.black),
                        padding: '10px 7px'
                      }}
                      top>
                      <TooltipArrow
                        top
                        style={{
                          right: '-45px'
                        }}
                      />
                      <TooltipInner
                        top
                        style={{
                          background: lighten(0.3, theme.colors.black),
                          fontSize: '12px',
                          'text-align': 'left'
                        }}>
                        {messages.passwordTooltip}
                      </TooltipInner>
                    </Tooltip>
                  </Box>
                </Box>
                <PasswordField
                  name="password"
                  fontSize={['10px', '11px', '12px', '13px']}
                  value={formProps.values.password}
                  onChange={event => {
                    // Sanitize password
                    const password = sanitizePassword(event.target.value)
                    formProps.handleChange(event.target.name)(password)
                  }}
                  error={showErrors && formProps.errors.password}
                />
                {showErrors && formProps.errors.password && (
                  <Box textAlign="left" color="red">
                    {formProps.errors.password}
                  </Box>
                )}
              </Box>
              <Box mt="10px" margin="auto">
                <Text textAlign="left">{messages.agreeTo}</Text>
              </Box>
              <Box mt="15px" display="flex" alignItems="column">
                <Button
                  width={['95%', '175px']}
                  pt="10px"
                  pb="10px"
                  fontSize={['16px', '18px']}
                  opaque={disableButton}
                  onClick={() => {
                    // track(eventConstants.appPasswordConnect.backClicked)
                    setStep('login')
                    // props.updateStep('/appPassword/login')
                  }}>
                  {'Back'}
                </Button>
                <Button
                  type="submit"
                  width={['95%', '175px']}
                  positive
                  pt="10px"
                  pb="10px"
                  ml="20px"
                  fontSize={['16px', '18px']}
                  opaque={disableButton}>
                  {messages.continue}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      />
      {isUserPolling && <WelcomeInstructions />}
    </Fragment>
  )
}

export default ConnectPasswordStep
