import React, { createContext, useContext } from 'react'
import { v4 as uuid } from 'uuid'
import store from '../redux/store'
import { addNotification, removeNotification } from '../redux/slice/app.slice'

/*
Notification format:
{
  id: uuid
  title: title message or default value
  message: text or element
  type: one of the types from react-bootstrap

}

 */

const typeTimeoutMap = new Map([
  ['success', 3000],
  ['warning', 4000],
  ['danger', 5000],
  [undefined, 3000]
])

export const NotificationsContext = createContext({ notifications: [] })

export const NotificationsProvider = ({ children }) => {
  const notifications = store.getState().app.notifications

  const pushNotification = (notification, timeout) => {
    const id = uuid()

    store.dispatch(
      addNotification({
        id: id,
        title: notification.title,
        message: notification.message,
        type: notification.type
      })
    )

    setTimeout(() => {
      removeNotificationById(id)
    }, timeout || typeTimeoutMap.get(notification.type))
  }

  const removeNotificationById = id => {
    store.dispatch(removeNotification(id))
  }

  const notifySuccess = (message, timeout, title) => {
    pushNotification(
      {
        message: message,
        title: title,
        type: 'success'
      },
      timeout
    )
  }

  const notifyWarning = (message, timeout, title) => {
    pushNotification(
      {
        message: message,
        title: title,
        type: 'warning'
      },
      timeout
    )
  }

  const notifyError = (message, timeout, title) => {
    pushNotification(
      {
        message: message,
        title: title,
        type: 'danger'
      },
      timeout
    )
  }

  const value = {
    notifications,
    removeNotificationById,
    notifySuccess,
    notifyWarning,
    notifyError
  }

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  )
}

export const useNotifications = () => useContext(NotificationsContext)
