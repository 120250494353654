import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import SubscriptionsContainer from '../SubscriptionsContainer'
import EmailPreviewContainer from '../EmailPreviewContainer'
import {
  SubscriptionsProvider,
  useSubscriptions
} from '../context/SubscriptionsProvider'
import FullScreenPreviewModal from '../FullScreenPreviewModal'

const Styled = styled.div`
  .subscription-content {
    flex-wrap: nowrap;
  }

  .subscriptions-container {
    padding-right: 40px;
    min-width: 435px;
    border-right: 2px solid rgb(40, 40, 40, 0.05) !important;

    -webkit-transition: 300ms linear;
    -moz-transition: 300ms linear;
    -o-transition: 300ms linear;
    -ms-transition: 300ms linear;
    transition: 300ms linear;
  }
`

const ContentWrapper = ({ type }) => {
  const context = useSubscriptions()

  return (
    <Row className="subscription-content">
      <Col
        xs={!context.selectedSubscription ? 12 : 6}
        className="subscriptions-container">
        <SubscriptionsContainer
          type={type}
          // setSelectedSubscription={useSubscriptions().setSelectedSubscription}
          // selectedSubscription={useSubscriptions().selectedSubscription}
        />
      </Col>
      {context.selectedSubscription && (
        <Col xs={6} style={{ padding: 0 }}>
          <EmailPreviewContainer
            setSelectedSubscription={context.setSelectedSubscription}
            selectedSubscription={context.selectedSubscription}
            type={type}
            className="email-preview-container"
          />
        </Col>
      )}
      {context.selectedSubscription && <FullScreenPreviewModal />}
    </Row>
  )
}

const ContentMenu = ({ type, title }) => {
  useEffect(() => {
    const fullTitle = title ? title + ' - ' : ''
    document.title = fullTitle + 'Unroll.me'
  }, [title])

  return (
    <Styled>
      <SubscriptionsProvider type={type}>
        <ContentWrapper type={type} />
      </SubscriptionsProvider>
    </Styled>
  )
}

export default ContentMenu
