import React from 'react'
import styled from 'styled-components'
import { Button, Table } from 'react-bootstrap'
import moment from 'moment'
import { useGetArchivesQuery } from '../../../redux/Api/ArchiveApi'
import Logo from '../../Logo/LogoPlain'

const Styled = styled.div`
  max-height: 200px;
  overflow-y: auto;

  th,
  td {
    text-align: left;
    vertical-align: middle;
    font-size: 16px;
  }

  tr {
    height: 55px;
  }

  th {
    font-size: 18px !important;
  }

  .download-button {
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    height: 34px;
    padding-left: 22px;
    padding-right: 22px;
    border-radius: 17px;
    border: solid 1px rgba(247, 147, 29, 0.75);
    background-color: #f7931d;
  }

  .download-button:hover {
    background-color: #f7931d;
    color: white;
    border: solid 1px rgba(247, 147, 29, 0.75);
    opacity: 0.75;
  }

  .file-header {
    width: 75px;
  }

  .empty-link {
    font-weight: bold;
    color: rgb(0, 0, 0, 0.4);
  }
`

const MyDataFileLabel = styled.div`
  text-align: left;
  font-size: 18px;
  font-weight: bold;
`

const RequestDataLabel = styled.div`
  text-align: left;
  font-size: 14px;
`

const MyDataTable = ({ archives }) => {
  return (
    <Styled>
      <Table>
        <tbody className="w-100">
          {archives.map(archive => (
            <div
              key={archive.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '50px',
                marginTop: '10px',
                justifyContent: 'space-between'
              }}>
              <div style={{ display: 'flex' }}>
                <Logo
                  style={{
                    background: '#404040',
                    width: '40px',
                    height: '40px',
                    padding: '8px 7px 8px 9px',
                    borderRadius: '12px',
                    margin: 'auto'
                  }}
                />
                <div style={{ paddingLeft: '12px' }}>
                  <MyDataFileLabel>My Data File</MyDataFileLabel>
                  <RequestDataLabel>
                    Requested:{' '}
                    <b>{moment(archive.requested_at).format('MMM Do YYYY')}</b>
                  </RequestDataLabel>
                </div>
              </div>
              <div style={{}}>
                {archive.is_expired ? (
                  <div className="empty-link">Expired</div>
                ) : archive.link ? (
                  <Button
                    className="download-button"
                    target="_blank"
                    href={archive.link}>
                    Download
                  </Button>
                ) : (
                  <div className="empty-link">Creating...</div>
                )}
              </div>
            </div>
          ))}
        </tbody>
      </Table>
    </Styled>
  )
}

export default MyDataTable
