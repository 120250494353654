import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import moment from 'moment'
import eventConstants from './eventConstants'

/* eslint-disable */
export const initializeEventTracking = function() {
  try {
    const HEAP_APP_ID =
      window.location && window.location.hostname === 'unroll.me'
        ? '3543597151'
        : '821966953'
    window.heap.load(HEAP_APP_ID, {
      disableTextCapture: false,
      secureCookie: true
    })
  } catch (err) {
    console.error('Heap initialization error', err)
  }

  ReactGA.initialize('UA-212332778-1', {
    titleCase: false
  })
  TagManager.initialize({
    gtmId: 'GTM-WHZJ9L3'
  })

  ReactGA.pageview()
}
/* eslint-disable */

let state

export const track = (eventName, data = {}) => {
  data.Platform = 'Web'

  try {
    window.heap.track(eventName, data)
  } catch (err) {
    console.error('Heap tracking error', err)
  }

  try {
    ReactGA.event({
      category: 'All',
      action: eventName.toLowerCase()
    })
  } catch (err) {
    console.error('ReactGA tracking error', err)
  }
}

export function IdentifyUser({
  id,
  service_provider,
  auth_type,
  signup_date,
  settings
}) {
  const userProperties = {
    [eventConstants.userProperties.hasAppPassword]: auth_type === 'password',
    [eventConstants.userProperties.signupDate]: signup_date
      ? moment(signup_date).format('YYYY/MM/DD')
      : '',
    ID: id,
    [eventConstants.userProperties.provider]:
      eventConstants.common.providerLabel[service_provider],
    [eventConstants.userProperties.researchOptOut]:
      settings.is_opted_out_of_data_sharing,
    [eventConstants.userProperties.adOptOut]:
      settings.is_opted_out_of_ad_targeting
  }

  window.heap.identify(id)
  window.heap.addUserProperties(userProperties)
}

const subscriptionUpdated = action => {
  return {
    currentState: action.payload.currentFilter,
    transitionState: action.payload.filter,
    inGroup: !!action.payload.inGroup,
    editAsGroup: action.payload.inGroup === 'groupEdit',
    totalInGroup: action.payload.groupSize,
    subscriptionId: action.payload.subscriptionId,
    groupedSubscriptionIds: action.payload.inGroupIds
  }
}

const trackingData = {
  UPDATE_SUBSCRIPTION_STATE: {
    event: 'subscription_action',
    cb: subscriptionUpdated
  }
}

/* eslint-disable */
const eventTrackingMiddleware = store => next => action => {
  state = store.getState()
  if (action.type === 'LOGOUT_USER') {
    state = null
  }

  if (trackingData[action.type]) {
    if (typeof trackingData[action.type] === 'function') {
      trackingData[action.type](state, action)
    } else {
      track(
        trackingData[action.type].event,
        trackingData[action.type].cb(action)
      )
    }
  }
  return next(action)
}
/* eslint-disable */

export default eventTrackingMiddleware
