import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl.macro'
import Modal from '../Modal'
import Button from '../Button'
import Box from '../Box'
import Text from '../Text'
import { formatMessages } from '../../i18n/utils'
import eventNames from '../../global/eventConstants'

const messageDescriptors = defineMessages({
  modalTitle: {
    id: 'components.TCModal.modalTitle',
    defaultMessage: '😳'
  },
  errorProviderTitle: {
    id: 'components.TCModal.howDataTitle',
    defaultMessage: 'Error'
  },

  errorProviderDesc1: {
    id: 'components.TCModal.howDataDesc1',
    defaultMessage: 'Looks like you’re trying to connect your '
  },
  errorProviderDesc2: {
    id: 'components.TCModal.howDataDesc2',
    defaultMessage: 'account, but you didn’t click the '
  },
  errorProviderDesc3: {
    id: 'components.TCModal.howDataDesc2',
    defaultMessage: ' sign up option.'
  },
  errorProviderDesc4: {
    id: 'components.TCModal.howDataDesc3',
    defaultMessage: ' No worries! You can connect your account'
  },
  errorProviderDesc5: {
    id: 'components.TCModal.howDataDesc4',
    defaultMessage: 'by clicking '
  },
  errorProviderDesc6: {
    id: 'components.TCModal.howDataDesc5',
    defaultMessage: ' below'
  },
  btnAccept: {
    id: 'components.TCModal.btnAccept',
    defaultMessage: 'Connect '
  }
})

const MailProviderErrorModal = ({
  closeModal,
  onAccept,
  track,
  mailProvider
}) => {
  useEffect(() => {
    track(eventNames.toErrorProviderModal.modalLoad)
  }, [])
  const messages = formatMessages(messageDescriptors)

  return (
    <Modal
      borderRadius="20px"
      disableBackgroundClick={false}
      title={messages.modalTitle}
      titleTextAlign="center"
      maxWidth="480px"
      titleSize="36px"
      forceModal
      closeModal={closeModal}
      mb={0}
      textAlign="center">
      <Box
        mt={[1, 1, 2]}
        pb={[1, 2, 2]}
        borderBottom={1}
        textAlign="center"
        borderColor="gray.1">
        <Text
          as="h4"
          display="block"
          color="black"
          textAlign="center"
          fontSize="18px"
          fontWeight={2}
          m={0}
          mb={2}>
          {`${mailProvider} ${messages.errorProviderTitle}`}
        </Text>
        <Text
          display="block"
          as="p"
          m={0}
          color="black"
          fontSize={['14px', '14px', '16px']}
          lineHeight={['1.38em']}>
          {messages.errorProviderDesc1} {mailProvider}
          <br />
          {messages.errorProviderDesc2}
          {mailProvider}
          {messages.errorProviderDesc3}
          <br />
          {messages.errorProviderDesc4}
          <br />
          {messages.errorProviderDesc5}{' '}
          <strong>
            {'Connect '}
            {mailProvider}
          </strong>{' '}
          {messages.errorProviderDesc6} <br />
          <a
            href="/legal/privacy/#restrictions"
            target="_blank"
            rel="noopener noreferrer">
            {messages.howDataDesc2}
          </a>{' '}
        </Text>
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Button
          onClick={onAccept}
          pt="10px"
          lineHeight={['2.4', '3.4']}
          fontSize="18px"
          display="block"
          width="100%"
          color="#4ab9ba"
          focusColor="#6ad9da"
          link>
          {messages.btnAccept}
          {mailProvider}
        </Button>
      </Box>
    </Modal>
  )
}

MailProviderErrorModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onAccept: PropTypes.string.isRequired
}

export default MailProviderErrorModal
