import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import AppReducer from './slice/app.slice'
import { SubscriptionsApi } from './Api/SubscriptionsApi'
import { ArchiveApi } from './Api/ArchiveApi'

const store = configureStore({
  reducer: {
    app: AppReducer,
    [SubscriptionsApi.reducerPath]: SubscriptionsApi.reducer,
    [ArchiveApi.reducerPath]: ArchiveApi.reducer
  },
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: undefined,
  middleware: getDefaultMiddleware => {
    if (process.env.NODE_ENV !== 'production') {
      return getDefaultMiddleware()
        .concat(logger)
        .concat(SubscriptionsApi.middleware)
        .concat(ArchiveApi.middleware)
    }
    return getDefaultMiddleware()
      .concat(SubscriptionsApi.middleware)
      .concat(ArchiveApi.middleware)
  }
})

export default store
