import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { lighten } from 'polished'
import Box from '../Box'

const fadeIn = keyframes`
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
`

const TextLoading = styled(Box)`
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${fadeIn};
  animation-timing-function: linear;
  background: ${props => props.bg};
  background: ${props =>
    `linear-gradient(to right, ${props.bg} 8%, ${lighten(0.3, props.bg)} 18%, ${
      props.bg
    } 33%)`};
  background-size: 800px 10px;
  position: relative;
  width: ${props =>
    Math.floor(Math.random() * props.maxWidth) + props.minWidth}px;
  overflow: hidden;

  &:before {
    content: '';
    width: 10px;
    height: 100px;
    position: absolute;
    top: -1px;
    right: 0;
    margin-right: ${Math.floor(Math.random() * 50) + 20}px;
    background-color: white;
  }
`

TextLoading.propTypes = {
  bg: PropTypes.string.isRequired,
  maxWidth: PropTypes.number.isRequired,
  minWidth: PropTypes.number.isRequired
}

export default TextLoading
