import { createSlice } from '@reduxjs/toolkit'
import { getCookieForUserAuthentication } from '../../global/cookies'

const initUser = {
  id: null,
  email: null,
  timezone: null,
  joined_at: null,
  reauth: false,
  settings: {
    rollups: {},
    subscriptions: {}
  },
  fetched: false
}

const initialState = {
  // Check for auth cookie for logged in redirect state
  isUserAuthenticated: !!getCookieForUserAuthentication(),
  isUserNew: false,
  isUserPolling: false,
  isUserOnboarding: false,
  // isSmartlookUserIdentified: false,
  isCCPAWhitelisted: true,
  configFetched: false,
  totalCount: {},
  addMailboxModal: {
    show: false,
    onLoad: false,
    type: undefined,
    email: undefined
  },
  genericConfig: {},
  users: [],
  user: initUser,
  notifications: []
}

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    logoutUser(state) {
      state.isUserAuthenticated = false
      state.user = initUser
    },
    getUser(state, action) {
      state.user = { ...action.payload.user, fetched: true }
      state.isUserNew = !action.payload.user.has_shared
      state.isUserAuthenticated = true
    },
    updateUsers(state, action) {
      state.users = action.payload
    },
    updateUserReauth(state, action) {
      state.user.reauth = action.payload
    },
    updateUserPolling(state, action) {
      state.isUserPolling = action.payload
    },
    updateUserOnboarding(state, action) {
      state.isUserOnboarding = action.payload
    },
    updateTotalCount(state, action) {
      state.totalCount[action.payload.state] = action.payload.count
    },
    updateAddMailboxModal(state, action) {
      state.addMailboxModal = action.payload
    },
    updateUserDeliveryTime(state, action) {
      state.user.settings.rollups.delivery_time = action.payload
      const userIndex = state.users.findIndex(u => u.id === state.user.id)
      if (userIndex !== -1) {
        state.users[userIndex].settings.rollups.delivery_time = action.payload
      }
    },
    updateUserOptOut(state, action) {
      state.user.settings.is_opted_out_of_data_sharing = action.payload
      const userIndex = state.users.findIndex(u => u.id === state.user.id)
      if (userIndex !== -1) {
        state.users[userIndex].settings.is_opted_out_of_data_sharing =
          action.payload
      }
    },
    updateUserEmailNotification(state, action) {
      state.user.settings.rollups.email = action.payload
      const userIndex = state.users.findIndex(u => u.id === state.user.id)
      if (userIndex !== -1) {
        state.users[userIndex].settings.rollups.email = action.payload
      }
    },
    updateUser(state, action) {
      state.user = { ...action.payload, fetched: true }
      state.isUserNew = !action.payload.has_shared
      state.isUserAuthenticated = true
    },
    addNotification(state, action) {
      state.notifications.unshift(action.payload)
    },
    removeNotification(state, action) {
      state.notifications = state.notifications.filter(
        notification => notification.id !== action.payload
      )
    }
  }
})

export const {
  logoutUser,
  getUser,
  updateUser,
  updateUserPolling,
  updateUserOnboarding,
  updateUsers,
  updateUserDeliveryTime,
  updateUserOptOut,
  updateUserEmailNotification,
  updateUserReauth,
  updateAddMailboxModal,
  updateTotalCount,
  addNotification,
  removeNotification
} = appSlice.actions
export default appSlice.reducer
