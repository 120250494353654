import React from 'react'
import { Button } from 'react-bootstrap'
import { FooterText, Text, Wrapper } from '../../styled'
import { YouTubeWrapper } from './styled'
import { SubmitButtonStyles } from '../EnterMailbox/styled'

export const CreateAppPassword = ({ email, nextStep, provider }) => {
  const link =
    provider === 'google'
      ? 'https://accounts.google.com/signin/v2/challenge/pwd?continue=https://myaccount.google.com/apppasswords'
      : 'https://appleid.apple.com/account/manage'

  const handleButtonClick = () => {
    nextStep()
  }
  return (
    <Wrapper>
      <Text style={{ width: '445px', maxWidth: '445px' }}>
        Create Google App Password for
        <br />
        <b>{email}</b>
      </Text>
      <YouTubeWrapper
        src={
          provider === 'google'
            ? 'https://www.youtube.com/embed/RPivqAyLfHM'
            : 'https://www.youtube.com/embed/GE-LiLoDj9g'
        }
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
      <Button style={SubmitButtonStyles} target="_blank" href={link}>
        Create App Password
      </Button>
      <FooterText onClick={handleButtonClick}>
        App Password Already Created
      </FooterText>
    </Wrapper>
  )
}
