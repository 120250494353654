import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { defineMessages } from 'react-intl.macro'
import Box from '../../components/Box'
import MainNav from '../../components/MainNav/MainNav'
import CreatePasswordStep from './CreatePasswordStep'
import LoginEducationStep from './LoginEducationStep'
import ConnectPasswordStep from './ConnectPasswordStep'
import theme from '../../global/theme'
import Text from '../../components/Text'
import Notification from '../../components/Notification'
import WhyWeWontChargeModal from '../../components/WhyWeChargeModal/WhyWeWontChargeModal'
import { track as trackEvent } from '../../global/eventTracking'
import { formatMessages } from '../../i18n/utils'
import eventConstants from '../../global/eventConstants'

export const messageDescriptors = defineMessages({
  whyWeDontCharge: {
    id: 'containers.GoogleSignup.whyWeDontCharge',
    defaultMessage: "Why we don't charge you for this amazing service."
  },
  contactUsP1: {
    id: 'containers.GoogleSignup.contactUsP1',
    defaultMessage: 'If you have trouble logging in, '
  },
  contactUsP2: {
    id: 'containers.GoogleSignup.contactUsP2',
    defaultMessage: 'contact us'
  }
})

// loginPassword, connectPassword
const GoogleSignup = ({ email, provider }) => {
  const [step, setStep] = useState('login')
  const location = useLocation()
  const navigate = useNavigate()
  const [showWhyWeWontCharge, setShowWhyWeWontCharge] = useState(false)

  const updateStep = path => {
    navigate(path + location.search)
  }

  const pathToPageMap = {
    '/appPassword/login': eventConstants.appPasswordLogin.pageName,
    '/appPassword/connect': eventConstants.appPasswordConnect.pageName
  }

  const page = pathToPageMap[location.pathname]

  const messages = formatMessages(messageDescriptors)
  return (
    <Box bg="white">
      {/*<MainNav isGoogleSignUp />*/}
      <Notification />
      <Box
        // minHeight="100vh"
        display="flex"
        alignItems="center"
        width={['300px', '400px', '500px', '672px']}
        margin="0 auto"
        flexDirection="column">
        <Box pl={[4]} pr={[4]} textAlign="center">
          {step === 'education' && (
            <CreatePasswordStep updateStep={updateStep} />
          )}
          {step === 'login' && (
            <LoginEducationStep setStep={setStep} provider={provider} />
          )}
          {step === 'connect' && (
            <ConnectPasswordStep
              setStep={setStep}
              email={email}
              provider={provider}
            />
          )}
        </Box>
        <Text
          onClick={() => {
            setShowWhyWeWontCharge(true)
          }}
          style={{ cursor: 'pointer' }}
          mt="15px"
          fontStyle="italic"
          color={theme.colors.blue}
          textAlign="center">
          {messages.whyWeDontCharge}
        </Text>
        {showWhyWeWontCharge && (
          <WhyWeWontChargeModal
            page={page}
            closeModal={() => {
              setShowWhyWeWontCharge(false)
            }}
          />
        )}
        <Text mt="15px" textAlign="center">
          {messages.contactUsP1}&nbsp;
          <a
            style={{ textDecoration: 'underline', color: '#41c1c8' }}
            href="http://unrollme.zendesk.com/hc">
            {messages.contactUsP2}
          </a>
        </Text>
      </Box>
    </Box>
  )
}

export default GoogleSignup
