import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Container, Nav, Navbar, NavDropdown, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { TbLogout, TbSettings } from 'react-icons/tb'
import { BiSupport } from 'react-icons/bi'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { NavLink, useLocation } from 'react-router-dom'
import './header.css'
import Logo from '../../Logo/LogoPlain'
import SettingsModal from '../SettingsModal'
import DeleteAccountModal from '../DeleteAccountModal'
import { useAuth } from '../../../hook/useAuth'
import ReauthAccountModal from '../ReauthAccountModal'
import SelectMailbox from './SelectMailbox'
import AddMailbox from './AddMailbox'
import AddMailboxModal from '../AddMailboxModal'
import WelcomeInstructions from '../WelcomeInstructions'
import AuthAccountModal from '../AuthAccountModal'
import { updateAddMailboxModal } from '../../../redux/slice/app.slice'
import { useSubscriptions } from '../context/SubscriptionsProvider'
import { useNotifications } from '../../../contexts/Notifications'
import { AcceptCreateArchiveModal } from '../SettingsModal/AcceptCreateArchiveModal'

const Styled = styled.div`
  .nav-item {
    width: 33%;
  }

  .email-item {
    text-align: right;
    padding-right: 10px;
  }

  .email-item .nav-item {
    width: 100%;
  }

  .email-label svg {
    display: flex;
  }

  .subscription-logo-item {
    color: white;
    text-decoration: none;
  }

  .main-logo {
    display: block;
    margin: auto;
    width: fit-content;
  }

  .icon-logo {
    display: block;
    width: fit-content;
  }

  .logo-item a {
    height: 40px;
  }

  .logo-item svg {
    padding-left: 30px;
  }

  .dropdown-item {
    display: flex;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 500;
    background-color: #404040;

    &:active {
      background-color: #555555;
      //animation: 'fadeIn 0.5s'
    }
  }

  .dropdown-toggle {
    color: white !important;
    font-weight: 500;
  }

  .dropdown-toggle:hover {
    color: white;
  }

  .dropdown-item svg {
    margin-right: 10px;
    font-size: 20px;
    position: relative;
    top: 3px;
  }

  .dropdown-menu {
    width: 100%;
  }

  .settings-dropdown .dropdown-toggle::after {
    display: none !important;
    padding: 0 10px;
  }

  .settings-dropdown .dropdown-menu {
    width: 150px;
    padding: 1px !important;
  }

  .emails-dropdown .dropdown-menu {
    min-width: fit-content;
    width: inherit;
    padding: 1px;
  }

  .add-mailbox {
    justify-content: center;
    font-weight: bold;
  }

  .add-mailbox:active {
    background-color: rgba(255, 255, 255, 0.2);
  }
`

const HeaderLogo = ({ subscriptionsCount }) => {
  const context = useSubscriptions()
  const user = useSelector(state => state.app.user)
  const isScanning = useMemo(() => {
    return user?.scan_status === 'in_progress'
  }, [user])

  const isLoading =
    !isScanning &&
    (context.isLoadingSubscriptions ||
      context.isFetchingSubscriptions ||
      !user.id)

  const newData = useSelector(
    state =>
      state.SubscriptionsApi.queries['getSubscriptionsByState({"state":"new"})']
  )
  const totalCount = newData?.data?.pagination?.total_count || 0

  return (
    <>
      <div className="header-logo">Unroll.Me</div>
      {!isLoading && totalCount > 0 && (
        <div className="header-sublogo">{`${totalCount} New Subscription${
          totalCount > 1 ? 's' : ''
        }`}</div>
      )}
    </>
  )
}

const Header = () => {
  const [showSettings, setShowSettings] = useState(false)
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)
  const [showProceedArchiveModal, setShowProceedArchiveModal] = useState(false)
  const selectedUser = useSelector(state => state.app.user)
  const showUserOnboarding = useSelector(state => state.app.isUserOnboarding)

  const addMailboxModalState = useSelector(state => state.app.addMailboxModal)

  const dispatch = useDispatch()

  const { logoutCurrentUser, hasUsers } = useAuth()
  const [showAddMailbox, setShowAddMailbox] = useState(false)

  const handleToggleUserMenu = (isOpen, event, metadata) => {
    if (!isOpen) {
      setShowAddMailbox(false)
    }
  }

  const handleCloseProceedAccountModal = () => {
    setShowProceedArchiveModal(false)
    setShowSettings(true)
  }

  const handleShowProceedAccountModal = () => {
    setShowSettings(false)
    setShowProceedArchiveModal(true)
  }

  return (
    <Styled>
      <Navbar className="header">
        <Container fluid style={{ justifyContent: 'left' }}>
          <Nav.Item className="float-start logo logo-item">
            <NavLink
              to="/app/inbox"
              className="subscription-logo-item icon-logo">
              <Logo />
            </NavLink>
          </Nav.Item>
          <Nav.Item className="justify-content-center ">
            <NavLink
              to="/app/inbox"
              className="subscription-logo-item main-logo">
              <HeaderLogo />
            </NavLink>
          </Nav.Item>

          {hasUsers && (
            <Navbar.Collapse className="justify-content-end email-item">
              <Nav>
                <NavDropdown
                  // id="nav-dropdown-dark-example"
                  className="emails-dropdown"
                  align="end"
                  title={
                    selectedUser?.email ? (
                      <>
                        <>{selectedUser.email} </>
                        {selectedUser.scan_status === 'in_progress' && (
                          <Spinner
                            style={{
                              marginLeft: 5,
                              fontSize: 10,
                              marginBottom: -3
                            }}
                            animation="border"
                          />
                        )}
                      </>
                    ) : (
                      'Loading... '
                    )
                  }
                  onToggle={handleToggleUserMenu}
                  menuVariant="dark">
                  {!showAddMailbox ? (
                    <SelectMailbox setShowAddMailbox={setShowAddMailbox} />
                  ) : (
                    <AddMailbox setShowAddMailbox={setShowAddMailbox} />
                  )}
                </NavDropdown>
              </Nav>

              <Nav>
                <NavDropdown
                  id="nav-dropdown-dark-example"
                  // title={user?.email}
                  title={<TbSettings style={{ fontSize: 30 }} />}
                  align="end"
                  className="settings-dropdown"
                  menuVariant="dark">
                  <NavDropdown.Item onClick={() => setShowSettings(true)}>
                    <TbSettings /> Settings
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() =>
                      window.open(
                        'https://support.unroll.me/hc/en-us',
                        '_blank',
                        'noreferrer'
                      )
                    }>
                    <AiOutlineQuestionCircle /> Support
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() =>
                      window.open(
                        'https://support.unroll.me/hc/en-us/requests/new',
                        '_blank',
                        'noreferrer'
                      )
                    }>
                    <BiSupport /> Contact Us
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => logoutCurrentUser()}>
                    <TbLogout />
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          )}

          {!hasUsers && (
            <Navbar.Collapse className="justify-content-end email-item">
              <Nav>
                <NavDropdown
                  // id="nav-dropdown-dark-example"
                  className="emails-dropdown"
                  align="end"
                  title={'Add Mailbox'}
                  // onToggle={handleToggleUserMenu}
                  menuVariant="dark">
                  <AddMailbox setShowAddMailbox={setShowAddMailbox} />
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          )}

          {showUserOnboarding && <WelcomeInstructions />}

          {showSettings && !selectedUser.reauth && (
            <SettingsModal
              setShow={setShowSettings}
              show={showSettings}
              setShowDeleteModal={setShowDeleteAccountModal}
              handleShowProceedAccountModal={handleShowProceedAccountModal}
            />
          )}
          {addMailboxModalState.show && !addMailboxModalState.onLoad && (
            <AddMailboxModal />
          )}
          {addMailboxModalState.show && addMailboxModalState.onLoad && (
            <AuthAccountModal
              show={addMailboxModalState.onLoad}
              setShow={() => {
                dispatch(
                  updateAddMailboxModal({
                    show: false,
                    onLoad: false
                  })
                )
              }}
              email={addMailboxModalState.email}
              provider={addMailboxModalState.type}
            />
          )}
          {showDeleteAccountModal && (
            <DeleteAccountModal
              show={showDeleteAccountModal}
              setShow={setShowDeleteAccountModal}
              setShowSettings={setShowSettings}
            />
          )}
          {showProceedArchiveModal && (
            <AcceptCreateArchiveModal
              handleClose={handleCloseProceedAccountModal}
            />
          )}
          {selectedUser.reauth && (
            <ReauthAccountModal
              show={selectedUser.reauth}
              setShowSettings={setShowSettings}
            />
          )}
        </Container>
      </Navbar>
    </Styled>
  )
}

export default Header
