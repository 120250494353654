import React from 'react'
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom'
import { useParams } from 'react-router'
import { createGlobalStyle } from 'styled-components'
import { CookieConsent } from 'react-cookie-consent'

import theme from '../src/global/theme'
import { initializeEventTracking } from './global/eventTracking'
import { AuthProvider } from './hoc/AuthProvider'
import NotFound from './containers/NotFound'
import { Signup } from './containers/Signup'
import { RequireAuth } from './hoc/RequireAuth'
import SignupHandler from './containers/Signup/SignupHandler'
import MainPage from './pages/MainPage'
import ContentMenu from './components/new/ContentMenu'
import CustomAuth from './containers/CustomAuth'
import AppClip from './containers/AppClip'
import GoogleSignup from './containers/GoogleSignup'
import MagicLinkHandler from './containers/CustomAuth/MagicLinkHandler'
import ManageData from './containers/ManageData'
import DeleteAccount from './containers/DeleteAccount'
import Blocked from './containers/Blocked'
import { ArchiveProvider } from './contexts/Archive'
import SignupHandlerWrapper from './containers/Signup/SignupHandlerWrapper'
import SignupErrorHandler from './containers/Signup/SignupErrorHandler'

initializeEventTracking()

const NavigateToRolluped = () => {
  const { subscriptionId } = useParams()
  const [searchParams] = useSearchParams()

  return (
    <Navigate
      to={`/app/rolledup?subscriptionId=${subscriptionId}&selected=${searchParams.get(
        'email'
      )}`}
      replace={true}
    />
  )
}

const NavigateToInbox = () => {
  const [searchParams] = useSearchParams()

  return (
    <Navigate
      to={`/app/inbox?subscriptionId=${searchParams.get(
        'subscriptionId'
      )}&selected=${searchParams.get('email')}`}
      replace={true}
    />
  )
}

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/app/inbox" />} />
        <Route path="/a/login" element={<Navigate to="/login" />} />
        <Route path="/a/signup" element={<Navigate to="/signup" />} />
        <Route path="/reauthenticate" element={<NavigateToInbox />} />
        <Route path="/a/reauthenticate" element={<NavigateToInbox />} />
        <Route path="/a/settings" element={<Navigate to="/app/inbox" />} />
        <Route path="/a/signup" element={<Navigate to="/app/inbox" />} />
        <Route path="/signup" element={<Navigate to="/app/inbox" />} />

        <Route
          path="/a/subscriptions/unsubscribed"
          element={<Navigate to="app/subscriptions/blocked" />}
        />
        <Route
          path="app/subscriptions/unsubscribed"
          element={<Navigate to="app/subscriptions/blocked" />}
        />
        <Route
          path="/a/subscriptions/rollup"
          element={<Navigate to="app/subscriptions/rollup" />}
        />
        <Route path="/click/subscriptions/new" element={<NavigateToInbox />} />
        <Route path="/a/login" element={<Navigate to="/app/inbox" />} />

        <Route path="/login" element={<Navigate to="/app/inbox" />} />

        <Route
          path="/click/rollup/:date/email/:subscriptionId"
          element={<NavigateToRolluped />}
        />
        <Route path="/click/rollup" element={<NavigateToRolluped />} />

        <Route
          path="app"
          element={
            <AuthProvider>
              <RequireAuth>
                <ArchiveProvider>
                  <MainPage />
                </ArchiveProvider>
              </RequireAuth>
            </AuthProvider>
          }>
          <Route
            index
            path="inbox"
            element={<ContentMenu type="new" title="Inbox" />}
          />
          <Route
            path="inbox/:subscriptionId"
            element={<ContentMenu type="new" />}
          />
          <Route path="subscriptions/" element={<Navigate to="keep" />} />
          <Route
            path="subscriptions/:subtype"
            element={<ContentMenu type="subscriptions" title="Subscriptions" />}
          />
          <Route
            path="rolledup/"
            element={<ContentMenu type="rolledup" title="Rollup" />}
          />
        </Route>

        <Route
          path="login"
          element={<Signup title="Login" subTitle="Login" />}
        />
        <Route
          path="adduser"
          element={<Signup title="Add mailbox" subTitle="Add mailbox" />}
        />
        <Route
          path="signup"
          element={<Signup title="Signup" subTitle="Signup" />}
        />
        <Route path="signup" element={<Signup />} />
        <Route path="authenticate" element={<CustomAuth />} />
        <Route path="authenticate/validate" element={<CustomAuth />} />
        <Route path="appclip" element={<AppClip />} />
        <Route path="appPassword/login" element={<GoogleSignup />} />
        <Route path="appPassword/connect" element={<GoogleSignup />} />
        <Route
          path="click/authenticate/magic_link"
          element={<MagicLinkHandler />}
        />
        <Route
          path="/a/oauth-result/:provider"
          otherProvider
          element={<SignupHandler />}
        />
        <Route
          path="/a/oauth/:provider"
          otherProvider
          element={
            <SignupHandlerWrapper>
              <SignupHandler />
            </SignupHandlerWrapper>
          }
        />
        <Route path="/a/data" element={<ManageData />} />

        <Route path="/a/unsubscribe/:type" element={<Blocked />} />

        <Route
          exact
          path="/a/delete-my-account"
          title="Delete My Account"
          element={<DeleteAccount />}
        />

        <Route
          path="/signup-error"
          title="Delete My Account"
          element={<SignupErrorHandler />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <GlobalStyle />
      {/*<CookieConsent*/}
      {/*  location="bottom"*/}
      {/*  buttonText="Accept All Cookies"*/}
      {/*  cookieName="unrollme-cookie-consent"*/}
      {/*  style={{*/}
      {/*    padding: '10px 30px',*/}
      {/*    background: 'white',*/}
      {/*    color: '#060a45',*/}
      {/*    fontWeight: 400,*/}
      {/*    lineHeight: '24px',*/}
      {/*    fontSize: '20px',*/}
      {/*    boxShadow: '0 0 18px rgba(0, 0, 0, .2)'*/}
      {/*  }}*/}
      {/*  buttonStyle={{*/}
      {/*    background: '#00bcbb',*/}
      {/*    color: 'white',*/}
      {/*    fontSize: '24px',*/}
      {/*    fontWeight: 600,*/}
      {/*    borderRadius: '2px',*/}
      {/*    padding: '10px 15px'*/}
      {/*  }}*/}
      {/*  expires={150}>*/}
      {/*  By clicking “Accept All Cookies”, you agree to the storing of cookies on*/}
      {/*  your device to enhance site navigation, analyze site usage, and assist*/}
      {/*  in our marketing efforts.*/}
      {/*</CookieConsent>*/}
    </>
  )
}

const GlobalStyle = createGlobalStyle`
  html {
    ${theme.global.html}
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    ${theme.global.body}
  }

  body.white {
    background-color: white;
  }

  body.modal-open {
    overflow: hidden;
  }

  a {
    color: ${theme.colors.blue};
    text-decoration: none;
  }

  svg {
    display: block;
  }
`
export default App
