import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Badge, Col, Row } from 'react-bootstrap'
import { useSubscriptions } from '../context/SubscriptionsProvider'

const Styled = styled.div`
  height: 80px;

  border-bottom: 2px solid rgb(0, 0, 0, 0.05);
  background: ${props => (props.isSelected ? 'rgb(0,0,0,0.05)' : '#fff')};
  border-radius: 5px !important;

  .content-item:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  .content-item {
    width: calc(100% - 130px);
    padding: 12px;
    padding-right: 20px;
    align-items: end;
    display: flex;
  }

  .content-item img {
    width: 32px;
    height: 32px;
    margin-right: 20px;
  }

  .row {
    height: 100%;
  }

  .name {
    display: flex;
    font-size: 18px;
    font-weight: bold;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subject {
    font-size: 14px;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .body {
    font-size: 12px;
    overflow: hidden;
    max-width: 70%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .buttons {
    text-align: right;
    min-width: 320px !important;
    margin: auto;
  }

  .labels {
    width: 100%;
    overflow: hidden;
    padding-right: 30px;
    line-height: normal;
  }

  .domain-icon {
    display: inline-block;
    width: 25px;
    height: 25px;
  }

  .date-item {
    width: 110px;
    margin-left: auto;
    font-size: 15px;
    font-weight: 600;
    color: #808080;
    opacity: 0.8;
    padding-top: 25px !important;
    padding-right: 0;
    text-align: end;
  }

  .alternative-icon {
    background-color: #3cc2c8 !important;
  }

  .hide {
    display: none !important;
  }
`

const SubscriptionRollupItem = ({
  subscription,
  type,
  subtype,
  setSelectedSubscription,
  selectedSubscription
}) => {
  const [showPlaceHolder, setShowPlaceholder] = useState(false)
  const [loadImage, setLoadImage] = useState(true)
  const context = useSubscriptions()

  const isSelected = useMemo(() => {
    if (subscription && selectedSubscription) {
      return subscription?.id === selectedSubscription?.id
    }
    return false
  }, [subscription, selectedSubscription])

  const handleClickSelect = e => {
    switch (e.detail) {
      case 1:
        context.changeSelectedSubscription(subscription)
        break
      case 2:
        context.changeSelectedSubscription(subscription)
        context.setShowFullPreview(true)
        break
    }
  }

  const date = useMemo(() => {
    const date = moment(subscription.created_at)
    if (date.isSame(new Date(), 'day')) {
      return date.format('LT')
    }
    if (date.isSame(moment().subtract(1, 'days'), 'day')) {
      return 'Yesterday'
    }
    return date.format('L')
  }, [subscription])

  return (
    <Styled isSelected={isSelected}>
      <Row xs="auto">
        <Col className="content-item" xs="auto" onClick={handleClickSelect}>
          {(showPlaceHolder || loadImage) && (
            <Badge
              className="alternative-icon"
              style={{
                display: 'inline-flex',
                borderRadius: '12px',
                width: '40px',
                height: '40px',
                fontSize: 20,
                border: '1px solid rgb(0,0,0,0.05)',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: 20
              }}>{`${subscription.subscription.domain
              .charAt(0)
              .toUpperCase()}`}</Badge>
          )}
          {!showPlaceHolder && (
            <img
              style={{
                borderRadius: '12px',
                width: '40px',
                height: '40px',
                border: '1px solid rgb(0,0,0,0.05)'
              }}
              hidden={loadImage}
              alt={`${subscription.domain} logo`}
              src={`https://logo.clearbit.com/${subscription.subscription.domain}`}
              onLoad={() => setLoadImage(false)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null // prevents looping
                setShowPlaceholder(true)
              }}
            />
          )}
          <div className="labels">
            <div className="name">{subscription.subscription.name}</div>
            <div className="subject">{subscription.subject}</div>
          </div>
        </Col>
        <Col>
          <div className="date-item">{date}</div>
        </Col>
      </Row>
    </Styled>
  )
}

export default SubscriptionRollupItem
