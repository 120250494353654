import styled from 'styled-components'

export const EnterMailboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`

export const LogoWrapper = styled.div`
  margin-top: 50px;
`

export const Text = styled.div`
  margin-top: 25px;
  font-size: 22px;
  color: #404040;
  line-height: 1.53;
  padding: 0 30px;
`

export const LinkWrapper = styled.a`
  color: #00bcbb;
  text-decoration: none;

  &:hover {
    color: #00cccb;
    text-decoration: none;
  }
`

export const PrivacyWrapper = styled.div`
  width: 370px;
  word-break: break-word;
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
  font-size: 14px;
  padding-bottom: 10px;
  padding-top: 10px;
  line-height: 1.42;
  color: #404040;
`

export const InputStyles = {
  height: '50px',
  width: '340px',
  borderRadius: '25px',
  marginTop: '70px',
  fontSize: '16px'
}

export const SubmitButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '20px',
  backgroundColor: '#00bcbb',
  height: '50px',
  width: '340px',
  borderRadius: '25px',
  border: 'none',
  fontSize: '18px',
  fontWeight: 600
}
