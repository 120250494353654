import styled from 'styled-components'

export const SupportTeamLink = styled.a`
  color: inherit;
  font-weight: 600;

  :hover {
    cursor: pointer;
    color: inherit;
    opacity: 0.9;
  }
`
