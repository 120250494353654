import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import './styles.css'
import { updateAddMailboxModal } from '../../../redux/slice/app.slice'
import { SignupMailbox } from '../SignupMailbox'

const Styled = styled.div`
  .modal-title {
    font-size: 22px;
    font-weight: bold;
    color: #404040 !important;
    margin: auto;
    padding-top: 7px;
  }

  .header-item {
    color: black;
    font-weight: bold;
    align-items: center;
    padding: 15px;
    padding-bottom: 10px;
    text-align: center;
    display: flex;
    font-size: 19.8px;
  }

  .header-item button {
    color: rgb(40, 40, 40, 0.25);
    font-size: 18px;
    width: 27px;
    padding: 0;
    margin: auto;
  }

  .buttons {
    margin-bottom: 15px;
  }

  .buttons div div {
    text-align: center;
  }
`

const AddMailboxModal = () => {
  const { show, type, email: selectedEmail } = useSelector(
    state => state.app.addMailboxModal
  )
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(updateAddMailboxModal({ show: false, type: undefined }))
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      onHide={handleClose}
      dialogClassName="add-mailbox-modal"
      centered>
      <Styled>
        <Modal.Header closeButton>
          <Modal.Title id="add-auth-title">{`Add ${
            type === 'icloud'
              ? 'iCloud'
              : type.charAt(0).toUpperCase() + type.substring(1)
          } Mailbox`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SignupMailbox provider={type} mail={selectedEmail} />
        </Modal.Body>
      </Styled>
    </Modal>
  )
}

export default AddMailboxModal
