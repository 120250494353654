import React, { useState } from 'react'

import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { get } from 'lodash'
import { Formik, Form } from 'formik'
import { Input } from '../../components/Form'
import MainNav from '../../components/MainNav/MainNav'
import Page from '../../components/Page'
import Text from '../../components/Text'
import Box from '../../components/Box'
import Button from '../../components/Button'

import Wrapper from '../../components/Wrapper'

import { formatDate } from '../../i18n/utils'
import theme from '../../global/theme'
import { submitEmail } from './DeleteActions'
import DeleteAccountModalNew from './DeleteAccountModalNew'

export const messages = {
  title: 'Delete Your Account',
  para1P1: `We, at Unroll.Me, are dedicated to giving our users the `,
  para1P2: 'best possible experience',
  para1P3: ` while using our service.`,
  para2: `Sometimes, we may fall short, but that won’t stop our dedication to continuosly improve our service.`,
  para3: `Before you delete your account, we'd like to point out a couple of things.`,
  para4:
    'If you have concerns about the use of your information in our measurement products and services,',
  para5P1: 'you can',
  para5P2: ' opt out ',
  para5P3: 'by accessing the Unroll.me dashboard.',
  para6P1: `Additionally, if you're frustrated with certain features or the user experience, we'd like to know that.`,
  para6P2: `Please reach out and let us know what we can do to improve by `,
  para6P3: `contacting support`,
  para6P4: `We read each and every message and take your feedback very seriously.`,
  para7: `If there’s nothing else we can do, and you’d still like to delete your account, please enter an email address
    associated with your account below. A verification code will be sent to your email for final confirmation.`,
  emailPlaceholder: `Your email address`,
  btnSubmit: `Submit`,
  rightsReserved: 'Nielsen Consumer LLC. All rights reserved.',
  tos: 'Terms of Service',
  privacy: 'Privacy Policy',
  andSign: '&',
  dot: '.'
}

const Styled = styled.div`
  a {
    color: #41c1c8;
    text-decoration: none;
  }

  a:hover {
    color: #61e1e8;
  }
`

const DeletePage = styled(Box)`
  background-color: ${theme.colors.gray[5]};
  font-family: ${theme.fontFamily.ptSans};
`

const Nav = styled(MainNav)`
  background-color: ${theme.colors.gray[5]};
`

const EmailInput = styled(Input)`
  background-color: ${theme.colors.gray[5]};
  color: rgb(255, 255, 255);
  border: 0;
  margin: auto;
  margin-top: 25px;
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solid #646464;

  ::placeholder {
    opacity: 75%;
  }
`

const DeleteAccount = () => {
  const [showModal, setShowModal] = useState(false)
  const [email, setEmail] = useState()

  const showOnlyLogo = true
  const showWhiteLogo = true
  const redirectToLandingPage = true

  return (
    <Styled>
      <Page
        title={messages.title}
        showNav={false}
        showFooter={false}
        description={messages.description}>
        <Nav
          showOnlyLogo={showOnlyLogo}
          showWhiteLogo={showWhiteLogo}
          redirectToLandingPage={redirectToLandingPage}
        />
        <DeletePage
          display="flex"
          color="white"
          minHeight="100vh"
          flexDirection="column">
          <Wrapper
            width={['80%', '80%', '80%', '65%']}
            fontSize={['15px', '15px', '18px', '18px']}
            lineHeight="26px"
            mt={5}
            textAlign="center">
            <Box mb={5}>
              <Text
                as="p"
                fontWeight={3}
                fontSize={['25px', '25px', '28px', '30px']}
                m={0}
                mb={5}>
                {messages.title}
              </Text>
              <Text as="p" fontWeight={0} m={0} mb={0}>
                {messages.para1P1}
                <Text as="span" fontWeight={3}>
                  {messages.para1P2}
                </Text>
                {messages.para1P3}
              </Text>
              <Text as="p" fontWeight={0} m={0}>
                {messages.para2}
              </Text>
              <Text as="p" fontStyle="italic" m={0} mt={6}>
                {messages.para3}
              </Text>
              <Text as="p" fontWeight={0} m={0} mt={5}>
                {messages.para4}
              </Text>
              <Text as="p" fontWeight={0} m={0} mt={0}>
                {messages.para5P1}
                <a
                  lineHeight="1.6"
                  href="https://support.unroll.me/hc/en-us/articles/360037344092-How-to-Opt-Out-of-our-measurement-panel"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Text as="span" fontWeight={3}>
                    {messages.para5P2}
                  </Text>
                </a>
                {messages.para5P3}
              </Text>
              <Text as="p" fontWeight={0} m={0} mt={3}>
                <Text>{messages.para6P1}</Text>
                <Text>
                  {messages.para6P2}
                  <a
                    lineHeight="1.6"
                    href="https://support.unroll.me/hc/en-us/requests/new"
                    target="_blank"
                    rel="noopener noreferrer">
                    <Text as="span" fontWeight={3}>
                      {messages.para6P3}
                    </Text>
                  </a>
                  .
                </Text>
                <Text>{messages.para6P4}</Text>
              </Text>
              <Text as="p" fontWeight={0} m={0} mt={9}>
                {messages.para7}
              </Text>

              <Formik
                initialValues={{
                  email: ''
                }}
                validate={values => {
                  const errors = {}
                  if (!values.email) {
                    errors.email = 'Required'
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = 'Invalid email address'
                  }
                  return errors
                }}
                onSubmit={(values, actions) => {
                  setEmail(values.email)
                  actions.setSubmitting(true)

                  submitEmail(values.email)
                    .then(() => {
                      actions.setSubmitting(false)
                      setShowModal(true)
                      // this.updateStep(
                      //   `/delete-my-account/verify?${stringify(searchObj)}`
                      // )
                    })
                    .catch(err => {
                      let errMsg = 'Something went wrong. Try again.'

                      if (err.response && err.response.status === 400) {
                        errMsg = get(
                          err,
                          'response.data.detail',
                          'Email address is not associated with an Unroll.Me account'
                        )
                      }

                      actions.setErrors({ email: errMsg })
                      actions.setSubmitting(false)
                    })
                }}>
                {formProps => (
                  <Form>
                    <Box
                      margin="auto"
                      width={['220px', '220px', '250px', '350px']}>
                      <EmailInput
                        placeholder={messages.emailPlaceholder}
                        name="email"
                        value={formProps.values.email}
                        onChange={formProps.handleChange}
                        error={formProps.errors.email}
                      />
                    </Box>
                    <Box
                      textAlign="center"
                      color="red"
                      style={{ height: 26, fontSize: 16 }}>
                      {formProps.errors.email ? formProps.errors.email : null}
                    </Box>
                    <Button
                      disabled={formProps.isSubmitting}
                      type="submit"
                      primary
                      borderRadius="25px"
                      lineHeight={['2.4', '3']}
                      mt={2}
                      width={['220px', '220px', '250px', '350px']}
                      height="50px"
                      fontSize={['16px', '16px', '16px', '16px']}
                      display="block">
                      {formProps.isSubmitting ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ width: 18, height: 18, marginRight: 8 }}
                          />
                          Submitting...
                        </>
                      ) : (
                        <>{messages.btnSubmit}</>
                      )}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
            <Text mt={9} mb={6}>
              © {formatDate(new Date(), { year: 'numeric' })}{' '}
              {messages.rightsReserved}{' '}
              <a href="/legal/terms">{messages.tos}</a> {messages.andSign}{' '}
              <a href="/legal/privacy">{messages.privacy}</a>
              {messages.dot}
            </Text>
          </Wrapper>
        </DeletePage>

        <DeleteAccountModalNew
          show={showModal}
          setShow={setShowModal}
          email={email}
        />

        {/*<ModalRoute*/}
        {/*  component={DeleteAccountModal}*/}
        {/*  path="/delete-my-account/verify"*/}
        {/*  parentPath="/delete-my-account"*/}
        {/*  props={{*/}
        {/*    updateStep: this.updateStep*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<ModalRoute*/}
        {/*  component={DeleteSuccessModal}*/}
        {/*  path="/delete-my-account/success"*/}
        {/*  parentPath="/delete-my-account"*/}
        {/*/>*/}
      </Page>
    </Styled>
  )
}

export default DeleteAccount
