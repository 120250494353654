import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'
import Image from '../../components/Image'
import Box from '../../components/Box'
import Text from '../../components/Text'
import Logo from '../../components/Logo'

const WelcomeStep = ({
  isShowingLogo,
  title,
  description,
  linkName,
  linkUrl,
  image,
  imageMaxWidth,
  onButtonClick,
  children
}) => (
  <Box textAlign="center">
    {isShowingLogo && (
      <Box display="inline-block" mb={4}>
        <Logo height="40px" />
      </Box>
    )}

    {image ? (
      <Box pb={[2, 2, 4]}>
        <Image
          src={`${process.env.PUBLIC_URL}/images/onboarding/${image}`}
          m="auto"
          display="block"
          maxWidth={imageMaxWidth || '100%'}
        />
      </Box>
    ) : null}

    {children || null}

    <Text
      as="h4"
      color="gray.3"
      textAlign="center"
      display="block"
      lineHeight={1.5}
      fontSize={5}
      p={0}
      m={0}
      mb={1}
      fontWeight={2}>
      {title}
    </Text>

    <Box maxWidth={3} display="block" m="auto">
      {description ? (
        <Text mt={0} mb={[4, 4, 5]} fontSize={4} textAlign="center" as="p">
          {description}
        </Text>
      ) : null}

      {linkName && linkUrl ? (
        <Box textAlign="center" display="block">
          {linkUrl && (
            <Button
              as={Link}
              to={linkUrl}
              onClick={onButtonClick}
              lineHeight="3.2"
              fontSize={4}
              px={4}
              positive
              width="180px">
              {linkName}
            </Button>
          )}
        </Box>
      ) : null}
    </Box>
  </Box>
)

export default WelcomeStep
