import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import MainNav from '../MainNav'
import Box from '../Box'

import Footer from '../Footer'

const Page = ({
  bodyClassName,
  title,
  description,
  showNav,
  children,
  showFooter
}) => (
  <Fragment>
    <Helmet>
      <title>{title ? `${title} – Unroll.Me` : 'Unroll.Me'}</title>
      <meta name="description" content={description} />
      <body className={bodyClassName} />
    </Helmet>
    <Box minHeight="100vh">
      {showNav ? <MainNav /> : null}

      {children}
    </Box>

    {showFooter ? <Footer /> : null}
  </Fragment>
)

Page.defaultProps = {
  showNav: true,
  showFooter: true,
  bodyClassName: ''
}
Page.propTypes = {
  showNav: PropTypes.bool,
  showFooter: PropTypes.bool,
  bodyClassName: PropTypes.string
}

export default Page
