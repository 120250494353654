import React, { useEffect, useState } from 'react'
import { parse, stringify } from 'query-string'
import { omit } from 'lodash'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'
import Box from '../../components/Box'
import Loading from '../../components/Loading'
import NotFound from '../NotFound'
import { buildParams } from './utils'
import Logo from '../../components/Logo'
import { authenticateUser } from '../App/AppActions'

const MagicLinkHandler = () => {
  const [requestFinished, setRequestFinished] = useState(false)
  const [invalidRoute, setInvalidRoute] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const search = location.search

    const parsedString = parse(search)
    const { token, email } = parsedString

    if (token && email) {
      // Login Action
      const params = buildParams(
        {},
        email,
        token,
        parsedString.provider
        // history
      )
      const redirectToAfterLogin = null

      authenticateUser(
        {
          ...params,
          isMagicAuth: true,
          redirectToAfterLogin
        },
        true
      )
        .then(() => {
          setRequestFinished(true)
          navigate('/app/inbox')
        })
        .catch(() => {
          const searchObj = {
            ...omit(parsedString, ['token']),
            email,
            link_fail: true
          }

          navigate(`/authenticate/validate?${stringify(searchObj)}`)
        })
    } else {
      setInvalidRoute(true)
    }
  }, [])

  if (invalidRoute) return <NotFound />

  return (
    <Box
      minHeight="100vh"
      display="flex"
      alignItems="center"
      flexDirection="column"
      p="20px">
      <Box my="20px">
        <Logo height="35px" />
      </Box>
      {requestFinished ? null : (
        <Box
          flex={1}
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center">
          <Loading icon />
        </Box>
      )}
    </Box>
  )
}

export default MagicLinkHandler
