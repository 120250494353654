import styled from 'styled-components'

export const ScanningCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;

  .toggle-button {
    color: #707070;
    font-weight: 700 !important;

    &:hover {
      color: #888888 !important;
    }

    &.checked {
      background: #00bcbb !important;

      &:hover {
        color: white !important;
      }
    }
  }
`

export const LogoWrapper = styled.div`
  margin-top: 50px;
`

export const Text = styled.div`
  margin-top: 25px;
  font-size: 22px;
  color: #404040;
  max-width: 370px;
  text-align: center;
`

export const RequiredIosText = styled.div`
  margin-top: 10px;
  height: 10px;
  font-size: 12px;
  color: #000;
`

export const FooterText = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 18px;
  color: #000;
  line-height: 1.87;
  text-decoration: underline;
  cursor: pointer;
`
