import React from 'react'
import InputMask from 'react-input-mask'
import { width, fontSize } from 'styled-system'
import styled from 'styled-components'
import { Input } from '../../components/Form'

const PasswordInput = styled(Input)`
  ${width}
  ${fontSize}
  font-family: Courier;
  padding-left: 15px;

  &:focus {
    outline: none;
  }
`

const PasswordField = props => {
  return (
    <InputMask
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      alwaysShowMask="true"
      mask="* * * * / * * * * / * * * * / * * * *"
      maskChar="_">
      {inputProps => {
        const inputBoxProps = {
          ...inputProps,
          width: props.width,
          fontSize: props.fontSize
        }
        return <PasswordInput {...inputBoxProps} />
      }}
    </InputMask>
  )
}

export default PasswordField
