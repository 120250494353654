import React from 'react'
import PropTypes from 'prop-types'
import Box from '../Box'
import Image from '../Image'
import Text from '../Text'
import TextLoading from '../TextLoading'

const Header = ({
  title,
  logo,
  description,
  children,
  isLoading,
  justifyHeader = 'flex-start',
  ...props
}) => (
  <Box
    mb={[4, 4, 6]}
    pb={2}
    pt={1}
    borderBottom={1}
    borderColor="gray.1"
    lineHeight={1.4}
    {...props}>
    <Box display="flex" justifyContent={justifyHeader}>
      {logo && (
        <Image
          objectFit="contain"
          src={
            isLoading
              ? `${process.env.PUBLIC_URL}/images/PreviewUnavailable.png`
              : logo
          }
          maxWidth={140}
          alt={title}
        />
      )}
      <Text
        as="h2"
        fontWeight={2}
        fontSize={[4, 4, 6]}
        m={0}
        mb={[1, 1, 2]}
        color="gray.3">
        {isLoading ? (
          <TextLoading
            as="span"
            minWidth={200}
            maxWidth={300}
            bg="#7A828A"
            opacity="0.5"
            height="6px"
            display="inline-block"
          />
        ) : (
          title
        )}
      </Text>
    </Box>

    {description ? (
      <Text as="p" m="0" mt={1} color="gray.2" fontSize={[2, 2, 3]}>
        {isLoading ? (
          <TextLoading
            as="span"
            minWidth={80}
            maxWidth={140}
            bg="#7A828A"
            opacity="0.5"
            height="6px"
            display="inline-block"
          />
        ) : (
          description
        )}
      </Text>
    ) : null}

    {children}
  </Box>
)

Header.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default Header
