import React from 'react'
import Box from '../Box'

const Wrapper = ({ children, maxWidth, px, ...props }) => (
  <Box
    maxWidth={maxWidth || 8}
    m="auto"
    width="100%"
    px={px || [2, 2, 3, 4]}
    {...props}>
    {children}
  </Box>
)

export default Wrapper
