import React from 'react'
import Box from '../Box'
import { ReactComponent as AppButtonsApple } from './AppButtonsApple.svg'
import { ReactComponent as AppButtonsAndroid } from './AppButtonsAndroid.svg'
import eventNames from '../../global/eventConstants'

const AppButtons = ({ track }) => (
  <Box display="flex" justifyContent="space-between">
    <Box
      as="a"
      href="https://bnc.lt/IPig/wQevBYZ8xA"
      display="block"
      onClick={() => {
        track(eventNames.common.clickedAppButton, {
          'App Type': 'iOS'
        })
      }}>
      <AppButtonsApple width="100%" height="100%" />
    </Box>
    <Box
      as="a"
      href="https://bnc.lt/IPig/kkmlSZ2Q1J"
      ml={3}
      display="block"
      onClick={() => {
        track(eventNames.common.clickedAppButton, {
          'App Type': 'Android'
        })
      }}>
      <AppButtonsAndroid width="100%" height="100%" />
    </Box>
  </Box>
)

export default AppButtons
