export const buildParams = (
  searchParams = {},
  email,
  code,
  provider,
  history
) => {
  const params = {
    provider,
    secret: code,
    method: 'custom',
    email,
    history,
    isCustomAuth: true,
    reauth: !!searchParams.reauth
  }

  return params
}
