import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Outlet } from 'react-router-dom'
import Header from '../../components/new/Header'
import Sidebar from '../../components/new/SideBarMenu'
import { useAuth } from '../../hook/useAuth'
import LoginContainer from '../../components/new/LoginContainer'

const Styled = styled.div`
  font-family: 'PT Sans', sans-serif;

  .content {
    flex-wrap: nowrap;
    overflow-x: scroll;
    background-color: white;
  }

  .sidebar-menu {
    padding-right: 0;
    background-color: rgb(0, 0, 0, 0.02);
    border-right: 2px solid rgb(40, 40, 40, 0.05) !important;
  }
`
const MainPage = () => {
  const { hasUsers } = useAuth()
  return (
    <Styled>
      <Header />

      <Container fluid>
        {hasUsers ? (
          <Row className="content">
            <Col xs="auto" className="sidebar-menu">
              <Sidebar />
            </Col>
            <Col style={{ marginLeft: '30px', overflow: 'hidden' }}>
              <Outlet />
            </Col>
          </Row>
        ) : (
          <LoginContainer />
        )}
      </Container>
      {/*<Footer />*/}
    </Styled>
  )
}

export default MainPage
