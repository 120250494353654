import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'

import { Text, Wrapper } from '../../styled'
import { SubmitButtonStyles } from '../EnterMailbox/styled'
import { Link } from './styled'

const Styled = styled.div`
  a {
    color: #00bcbb;
    font-weight: bold;

    &:hover {
      color: #00bcbb;
      opacity: 0.7;
    }
  }
`

export const ConnectYourMailbox = ({ nextStep, hideTitle }) => {
  return (
    <Wrapper>
      <Styled>
        <Text style={{ maxWidth: '750px', textAlign: 'start' }}>
          {!hideTitle && (
            <h1 style={{ textAlign: 'center' }}>
              <strong>Connect Your Mailbox</strong>
            </h1>
          )}
          <p>
            <strong>Your Consent to Collect and Share Your Data </strong>
          </p>
          <p>
            <strong>Your Privacy is our Priority.</strong>
            <br />
            <strong>
              Unroll.Me’s customers are not allowed to target you or attempt to
              sell to you.
            </strong>{' '}
            To keep Unroll.Me free for you, however, we offer services to our
            customers that may involve sharing or selling your personal
            information, based on this consent you are providing.
          </p>
          <p>
            <strong>How Does it Work?</strong>
            <br />
            <strong>
              By clicking “Let’s Go” you consent to Unroll.Me collecting
            </strong>{' '}
            copies of and content from your commercial emails, potentially
            including sensitive or special category data, and you consent to
            Unroll.Me sharing your personal information to our customers for
            purposes like market research, analytics, developing advertising
            models, or measurement of ad performance. You can withdraw your
            consent at any time through Unroll.Me’s platform "Settings" menu.
          </p>

          <p>
            For more information, please review our{' '}
            <Link
              style={{ color: '#00bcbb' }}
              href="https://unroll.me/legal/terms/"
              target="_blank">
              Terms of Service
            </Link>
            ,{' '}
            <Link href="https://unroll.me/legal/privacy/" target="_blank">
              Privacy Notice
            </Link>
            , and{' '}
            <Link href="https://unroll.me/your-data" target="_blank">
              Health Data Notice
            </Link>
            .
          </p>
          <p>
            <strong>Click “Let’s Go” to consent.</strong>
          </p>
        </Text>

        <Button
          onClick={nextStep}
          style={{
            ...SubmitButtonStyles,
            margin: ' 10px auto 20px',
            marginBottom: '20px'
          }}>
          Let's Go!
        </Button>
      </Styled>
    </Wrapper>
  )
}
