import React from 'react'
import styled from 'styled-components'
import { Button, Modal } from 'react-bootstrap'
import { useArchives } from '../../../contexts/Archive'

const Styled = styled.div`
  font-size: 18px;

  .modal-title {
    font-size: 22px;
    font-weight: bold;
    color: #404040;
    padding-left: 40px;
    margin: auto;
  }

  .modal-title svg {
    margin: auto;
    font-size: 28px;
  }

  .modal-header {
    padding-top: 10px;
    height: 85px;
    border-bottom: none;
  }

  .modal-header button {
    margin: 0 !important;
    margin-right: 20px !important;
    font-size: 10px;
    padding: 10px;
    border: 1px solid rgb(0, 0, 0, 0.5);
    border-radius: 50%;
  }

  .modal-body {
    color: rgb(0, 0, 0, 0.6);
    font-size: 18px;
    padding: 0px 30px 20px;
    text-align: center;
  }

  .accept-btn {
    font-size: 18px;
    font-weight: 700;
    padding: 10px;
    margin: 15px auto;
    width: auto;
    padding-left: 0;
    border: none;
    text-decoration: none;
    color: #00bca8;
  }

  .accept-btn:hover {
    color: #00bca8;
    opacity: 0.7;
  }
`
export const AcceptCreateArchiveModal = ({ handleClose }) => {
  const { handleCreateArchive } = useArchives()

  return (
    <Modal show={true} centered={true} onHide={handleClose}>
      <Styled>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>Are you sure?</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          We will prepare a file with all your data stored in Unroll.Me. Do you
          wish to proceed?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="accept-btn"
            onClick={() => {
              handleCreateArchive()
              handleClose()
            }}>
            Create Archive
          </Button>
        </Modal.Footer>
      </Styled>
    </Modal>
  )
}
