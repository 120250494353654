import { v4 as uuid } from 'uuid'
import { getCookie, getCookieForUserAuthentication } from '../global/cookies'
import {
  addNotification,
  removeNotification,
  updateUserReauth
} from '../redux/slice/app.slice'

export const setUpHttpInterceptors = (store, httpApis) => {
  httpApis.forEach(api => {
    api.interceptors.request.use(
      async config => {
        //   store.dispatch(showProgress());
        const currentUser = store.getState().app.user
        const token =
          getCookie('tempAuthToken') || getCookieForUserAuthentication()
        // if(!token){
        //   window.location="/login"
        // }
        // config.headers.common['Access-Control-Allow-Origin'] = '*'
        if (currentUser) {
          config.headers.common['authorization'] = `Bearer ${token}`
        }

        return config
      },
      async error => {
        //     store.dispatch(hideProgress());
        return Promise.reject(error)
      }
    )

    api.interceptors.response.use(
      response => {
        //   store.dispatch(hideProgress());
        return response
      },
      error => {
        if (error.message === 'Network Error') {
          const id = uuid()
          store.dispatch(
            addNotification({
              id: id,
              message: <>Looks like something goes wrong with your network.</>,
              type: 'danger'
            })
          )
          setTimeout(() => {
            store.dispatch(removeNotification(id))
          }, 7000)
        }
        if (error.response?.status === 401) {
          error.message = 'Please login and reload page'
          console.error('response data:', error.response)
          store.dispatch(updateUserReauth(true))
        }

        if (error.response?.status === 403) {
          error.message =
            "You haven't permissions to the current resource. Please contact admin"
          store.dispatch(updateUserReauth(true))
        }

        return Promise.reject(error)
      }
    )
  })
}
