import React, { useContext } from 'react'
import styled from 'styled-components'
import {
  Accordion,
  AccordionContext,
  Card,
  Col,
  Row,
  useAccordionButton
} from 'react-bootstrap'
import SubscriptionSingleItem from './SubscriptionSingleItem'

function ContextAwareToggle({ children, eventKey, callback, subscription }) {
  const { activeEventKey } = useContext(AccordionContext)

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = activeEventKey === eventKey

  return (
    <Row>
      <Col>
        <SubscriptionSingleItem subscription={subscription} />
      </Col>
      <Col xs="auto">
        <button
          type="button"
          style={{ backgroundColor: isCurrentEventKey ? 'pink' : 'lavender' }}
          onClick={decoratedOnClick}>
          {children}
        </button>
      </Col>
    </Row>
  )
}

const Styled = styled.div`
  min-height: 85px;

  .card {
    border: none;
  }
`

const SubscriptionGroupItem = ({ groupSubscriptions }) => {
  return (
    <Styled>
      <Accordion defaultActiveKey="0">
        <Card eventKey="0">
          <Card.Header eventKey="0">
            <ContextAwareToggle
              subscription={groupSubscriptions[0]}
              eventKey="0"
            />
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <>
              {groupSubscriptions.map(subscription => (
                <SubscriptionSingleItem subscription={subscription} />
              ))}
            </>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Styled>
  )
}

export default SubscriptionGroupItem
