// This file contains all the formatting functions needed for internationalization.
// Internal implementations of these functions may change, thus not library dependent.
import { forOwn } from 'lodash'
import { intl } from './setup'

export const formatMessage = (messageDescriptor, valuesObj) =>
  intl.formatMessage(messageDescriptor, valuesObj)

export const formatMessages = messages => {
  const formattedMessages = {}

  forOwn(messages, (msgDesc, key) => {
    if (msgDesc.skipGroupFormatting) {
      return
    }
    formattedMessages[key] = formatMessage(msgDesc)
  })

  return formattedMessages
}
export const formatNumber = (number, options) =>
  intl.formatNumber(number, options)

export const formatDate = (date, options) => intl.formatDate(date, options)
