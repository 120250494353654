import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import Box from '../Box'
import Wrapper from '../Wrapper'
import Text from '../Text'
import Button from '../Button'
import Icon from '../Icon'
import theme from '../../global/theme'
import { removeNotification } from './NotificationActions'

const Notification = ({ notifications, close }) => {
  if (notifications) {
    return (
      <Box position="absolute" left="0" right="0">
        {Object.keys(notifications).map(notification => {
          const alert = notifications[notification]
          return alert ? (
            <NotificationWrapper key={alert.id} context={alert.context}>
              <Wrapper py={0} display="flex" justifyContent="space-between">
                <Text color="white" fontWeight={2} py="7px">
                  {alert.text}
                </Text>
                <Button onClick={() => close(alert.id)} link>
                  <Icon type="delete" fill="white" />
                </Button>
              </Wrapper>
            </NotificationWrapper>
          ) : null
        })}
      </Box>
    )
  }
  return null
}

const NotificationWrapper = styled(Box)`
  background: ${theme.colors.blue};
  ${props =>
    props.context === 'negative' &&
    css`
      background: ${theme.colors.red};
    `}
  ${props =>
    props.context === 'positive' &&
    css`
      background: ${theme.colors.green};
    `}
`

Notification.propTypes = {
  close: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      context: PropTypes.string
    })
  ).isRequired
}

const mapStateToProps = state => ({
  notifications: state.notification
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      close: removeNotification
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notification)
