import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router'
import { get } from 'lodash'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { defineMessages } from 'react-intl.macro'
import { Form, Formik } from 'formik'
import { parse } from 'query-string'
import { Input } from '../../components/Form'
import Modal from '../../components/Modal'
import Text from '../../components/Text'
import Button from '../../components/Button'
import Box from '../../components/Box'
import { formatMessages } from '../../i18n/utils'
import theme from '../../global/theme'
import { authenticateUser, submitEmailForLogin } from '../App/AppActions'
import { updateAddMailboxModal } from '../../redux/slice/app.slice'
import { useAuth } from '../../hook/useAuth'

const codeExpiredErr =
  'Verification code expired. Click "Resend" above and try again.'

const messageDescriptors = defineMessages({
  titleVerify: {
    id: 'containers.DeleteAccountModal.titleVerify',
    defaultMessage: 'Verification Code'
  },
  verifyText: {
    id: 'containers.DeleteAccountModal.verifyText',
    defaultMessage: `A verification code has been sent to the email address you provided. Please check your email (and junk folder, if you don't see it) and enter the code below to continue logging in to your account. `
  },
  didntReceiveCodeText: {
    id: 'containers.DeleteAccountModal.didntReceiveCodeText',
    defaultMessage: `Didn't receive a Code?`
  },
  codePlaceholder: {
    id: 'containers.DeleteAccountModal.codePlaceholder',
    defaultMessage: 'Enter verification code'
  },
  btnContinue: {
    id: 'containers.DeleteAccountModal.btnContinue',
    defaultMessage: 'Continue'
  },
  btnResend: {
    id: 'containers.DeleteAccountModal.btnResend',
    defaultMessage: 'Resend'
  }
})

const CodeInput = styled(Input)`
  color: black;
  border: 0;
  margin: auto;
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solid #646464;
`

const ModalBox = styled(Box)`
  font-family: ${theme.fontFamily.ptSans};
`

const ValidateModal = ({ buildParams, email, provider }) => {
  const dispatch = useDispatch()
  const { loadNewUsers } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const [code, setCode] = useState(null)
  const [showResendBtn, setResendBtnState] = useState(false)
  const [formPristine, setFormPristine] = useState(true)
  const messages = formatMessages(messageDescriptors)
  const parsedSearch = parse(location.search)

  useEffect(() => {
    // If redirected from magic link failure, show resend btn instantly
    if (parsedSearch.link_fail) {
      setResendBtnState(true)
    }
  }, [])

  useEffect(() => {
    if (code && formPristine) {
      setFormPristine(false)
    }

    const timeout = setTimeout(() => {
      if (!code) {
        setResendBtnState(true)
      }
    }, 15000)

    return () => {
      clearTimeout(timeout)
    }
  }, [code, showResendBtn])

  return (
    <ModalBox fontSize={['17px', '17px', '18px', '18px']} lineHeight="26px">
      <Modal
        forceModal
        disableBackgroundClick
        bg="dark"
        maxWidth="650px"
        borderRadius="25px"
        title={messages.titleVerify}
        titleColor="black"
        titleFontWeight={3}
        titleTopMargin={4}
        titleSize={['23px', '23px', '28px', '28px']}
        titleTextAlign="center">
        <Formik
          initialValues={{
            email: parsedSearch.email ?? email,
            code: null
          }}
          validateOnChange={false}
          validate={values => {
            const errors = {}

            if (!values.code || values.code.length < 6) {
              errors.code =
                'The 6-digit code you entered was incorrect. Try again.'
            } else if (!/^[0-9]{6}$/i.test(values.code)) {
              errors.code =
                'Please enter the valid numeric code from the email.'
            }

            return errors
          }}
          onSubmit={(values, actions) => {
            setResendBtnState(false)
            const params = buildParams(
              parsedSearch,
              values.email,
              values.code,
              parsedSearch.provider ?? provider
            )
            const redirectToAfterLogin = parsedSearch.redirect || null

            authenticateUser({ ...params, redirectToAfterLogin }, true)
              .then(() => {
                loadNewUsers()
                dispatch(
                  updateAddMailboxModal({ show: false, type: undefined })
                )
                // navigate('/app/inbox')
              })
              .catch(err => {
                actions.setSubmitting(false)
                let errMsg = 'Something went wrong. Try again.'

                if (err.response && err.response.status === 400) {
                  errMsg = get(err, 'response.data.detail', errMsg)
                  if (errMsg === 'Code is expired') {
                    errMsg = codeExpiredErr
                  }
                }
                actions.setErrors({ code: errMsg })
                setResendBtnState(true)
              })
          }}>
          {formProps => (
            <Form>
              <Box width="80%" margin="auto">
                <Text as="p" mt={6} textAlign="center" color="black">
                  {messages.verifyText}
                </Text>

                <Box mt={5} height="36px">
                  {showResendBtn ? (
                    <Text
                      as="p"
                      textAlign="center"
                      fontWeight="bold"
                      color="black">
                      {messages.didntReceiveCodeText}
                      <Button
                        link
                        color={theme.colors.blue}
                        mb={1}
                        display={['block', 'block', 'inline-block']}
                        width={['100%', '100%', 'auto']}
                        fontSize={['17px', '17px', '18px', '18px']}
                        as="a"
                        onClick={() => {
                          submitEmailForLogin(formProps.values.email, true)
                            .then(() => {
                              formProps.setErrors({})
                              setResendBtnState(false)
                            })
                            .catch(err => {
                              let errMsg = 'Something went wrong. Try again.'

                              if (err.response && err.response.status === 400) {
                                errMsg = get(
                                  err,
                                  'response.data.detail',
                                  'Email address is not associated with an Unroll.Me account'
                                )
                              }

                              formProps.setErrors({ email: errMsg })
                            })
                        }}>
                        {messages.btnResend}
                      </Button>
                    </Text>
                  ) : null}
                </Box>
                <Box
                  textAlign="center"
                  color="red"
                  mt={2}
                  fontSize={['17px', '17px', '16px', '16px']}>
                  {formProps.errors.email ? formProps.errors.email : null}
                </Box>
                <Box
                  textAlign="center"
                  color="red"
                  mt={7}
                  fontSize={['17px', '17px', '16px', '16px']}>
                  {formProps.errors.code ? formProps.errors.code : null}
                </Box>
                {formPristine ? (
                  <Box
                    textAlign="center"
                    color="red"
                    mt={7}
                    fontSize={['17px', '17px', '16px', '16px']}>
                    {parsedSearch.link_fail ? codeExpiredErr : null}
                  </Box>
                ) : null}
                <Box margin="auto" width={['220px', '220px', '250px', '350px']}>
                  <CodeInput
                    placeholder={messages.codePlaceholder}
                    name="code"
                    maxLength="6"
                    value={formProps.values.code}
                    onChange={event => {
                      formProps.setErrors({})
                      setCode(event.target.value)
                      formProps.handleChange(event)
                    }}
                  />
                </Box>
                <Box display="flex">
                  <Button
                    type="submit"
                    disabled={formProps.isSubmitting}
                    primary
                    margin="auto"
                    borderRadius="30px"
                    lineHeight={['2.4', '3.4']}
                    mt={5}
                    width={['220px', '220px', '250px', '350px']}
                    fontSize={['15px', '15px', '16px', '16px']}
                    display="block">
                    {messages.btnContinue}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>
    </ModalBox>
  )
}

export default ValidateModal
