import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { Badge, Col, Placeholder, Row } from 'react-bootstrap'
import SubscriptionButtonsGroup from '../SubscriptionButtonsGroup'
import { useSubscriptions } from '../context/SubscriptionsProvider'

const Styled = styled.div`
  height: 80px;
  display: flex;
  width: 100%;

  border-bottom: 2px solid rgb(0, 0, 0, 0.05);
  background: ${props => (props.isSelected ? 'rgb(0,0,0,0.05)' : 'inherit')};
  border-radius: 5px !important;

  .content-item {
    width: calc(100% - 320px);
    margin: auto;
    height: 40px;
    padding: 0px 20px 0px 12px;
    align-items: end;
    display: flex;
  }

  .content-item:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  .content-item img {
    width: 32px;
    height: 32px;
    margin-right: 20px;
  }

  .row {
    height: 100%;
  }

  .domain {
    font-size: 18px;
    font-weight: bold;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .email {
    font-size: 14px;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .buttons {
    text-align: right;
    min-width: 320px !important;
    margin: auto;
  }

  .labels {
    width: 100%;
    padding-right: 30px;
    line-height: normal;
  }

  .domain-icon {
    display: inline-block;
    width: 25px;
    height: 25px;
  }

  .alternative-icon {
    background-color: #3cc2c8 !important;
  }

  .hide {
    display: none !important;
  }
`

const SubscriptionSingleItem = ({
  subscription,
  type,
  subtype,
  setSelectedSubscription,
  selectedSubscription
  // searchStr
}) => {
  const context = useSubscriptions()
  const [showPlaceHolder, setShowPlaceholder] = useState(false)
  const [loadImage, setLoadImage] = useState(true)

  const handleClickSelect = e => {
    switch (e.detail) {
      case 1:
        context.changeSelectedSubscription(subscription)
        break
      case 2:
        context.changeSelectedSubscription(subscription)
        context.setShowFullPreview(true)
        break
    }
  }

  const isSelected = useMemo(() => {
    if (subscription && selectedSubscription) {
      return subscription?.id === selectedSubscription?.id
    }
    return false
  }, [subscription, selectedSubscription])
  return (
    <Styled isSelected={isSelected}>
      <div className="content-item" onClick={handleClickSelect}>
        {(showPlaceHolder || loadImage) && (
          <Badge
            className="alternative-icon"
            style={{
              display: 'inline-flex',
              borderRadius: '12px',
              width: '40px',
              height: '40px',
              fontSize: 20,
              border: '1px solid rgb(0,0,0,0.05)',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: 20
            }}>{`${subscription.domain.charAt(0).toUpperCase()}`}</Badge>
        )}
        {!showPlaceHolder && (
          <img
            style={{
              borderRadius: '12px',
              width: '40px',
              height: '40px',
              objectFit: 'contain',
              border: '1px solid rgb(0,0,0,0.05)'
            }}
            hidden={loadImage}
            alt={`${subscription.domain} logo`}
            src={`https://logo.clearbit.com/${subscription.domain}`}
            onLoad={() => setLoadImage(false)}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null // prevents looping
              setShowPlaceholder(true)
            }}
          />
        )}
        <div className="labels">
          <div className="domain">{subscription.name}</div>
          <div className="email">{subscription.email}</div>
        </div>
      </div>
      <div className="buttons subscription-delimiter">
        <SubscriptionButtonsGroup
          subscription={subscription}
          setSelectedSubscription={context.changeSelectedSubscription}
          selectedSubscription={context.selectedSubscription}
          subtype={subtype}
          type={type}
          // searchStr={searchStr}
        />
      </div>
    </Styled>
  )
}

export default SubscriptionSingleItem
