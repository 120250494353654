import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  line-height: 100%;
`

export const Text = styled.div`
  margin-top: 25px;
  font-size: 22px;
  color: #404040;
  max-width: 370px;
  text-align: center;
  line-height: 1.53;

  & > p {
    margin: 15px;
    font-size: 16px;
  }
`

export const VerificationInputStyles = {
  height: '50px',
  width: '340px',
  borderRadius: '25px',
  marginTop: '10px',
  fontSize: '16px'
}

export const FooterText = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 18px;
  color: #000;
  line-height: 1.87;
  text-decoration: underline;
  cursor: pointer;
`

export const BackButton = styled.div`
  position: absolute;
  left: 40px;
  top: 40px;
  cursor: pointer;

  & > svg {
    width: 26px;
    height: 25px;
    fill: #0000007f;
  }
`
