import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import Page from '../../components/Page'
import Wrapper from '../../components/Wrapper'
import Box from '../../components/Box'
import Text from '../../components/Text'
import { formatDate } from '../../i18n/utils'
import { messages } from '../DeleteAccount/DeleteAccount'
import MainNav from '../../components/MainNav'
import theme from '../../global/theme'
import { reauthUnsubscribe } from '../App/AppActions'

const Styled = styled.div`
  a {
    color: #41c1c8;
    text-decoration: none;
  }

  a:hover {
    color: #61e1e8;
  }
`

const Nav = styled(MainNav)`
  background-color: ${theme.colors.gray[5]};
`

const BlockPage = styled(Box)`
  background-color: ${theme.colors.gray[5]};
  font-family: ${theme.fontFamily.ptSans};
`

const Blocked = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const token = searchParams.get('token')
    reauthUnsubscribe(token)
  }, [])

  return (
    <Styled>
      <Page
        title="Unroll.Me Blocked"
        showNav={false}
        showFooter={false}
        description="">
        <Nav
          showOnlyLogo={true}
          showWhiteLogo={true}
          redirectToLandingPage={true}
        />
        <BlockPage
          display="flex"
          color="white"
          minHeight="260px"
          height="calc(100vh - 70px)"
          flexDirection="column">
          <Wrapper
            mt={2}
            width="100%"
            fontSize={['15px', '15px', '18px', '18px']}
            lineHeight="26px"
            height="calc(100vh - 70px)"
            minHeight="250px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            textAlign="center">
            <Box mb={5} mt={5}>
              <Text
                as="p"
                fontWeight={3}
                fontSize={['25px', '25px', '28px', '30px']}
                m={0}
                mb={5}>
                Your Email subscription successfully blocked
              </Text>
              <Text as="p" fontWeight={0} m={0}>
                Be sure to check your account often because blocking means we’re
                unable to tell you about new subscriptions we’ve found.
              </Text>
            </Box>
            <Text mb={2}>
              © {formatDate(new Date(), { year: 'numeric' })}{' '}
              {messages.rightsReserved}{' '}
              <a href="/legal/terms">{messages.tos}</a> {messages.andSign}{' '}
              <a href="/legal/privacy">{messages.privacy}</a>
              {messages.dot}
            </Text>
          </Wrapper>
        </BlockPage>
      </Page>
    </Styled>
  )
}

export default Blocked
