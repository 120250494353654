import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import styled from 'styled-components'

const Styled = styled.div`
  font-size: 18px;

  .modal-title {
    font-size: 18px;
    font-weight: bold;
    color: #404040;
    padding-left: 40px;
    margin: auto;
  }

  .modal-title svg {
    margin: auto;
    font-size: 28px;
  }

  .modal-header {
    padding-top: 10px;
    height: 85px;
    border-bottom: none;
  }

  .modal-header button {
    margin: 0 !important;
    margin-right: 20px !important;
    font-size: 10px;
    padding: 10px;
    border: 1px solid rgb(0, 0, 0, 0.5);
    border-radius: 50%;
  }

  .modal-body {
    color: rgb(0, 0, 0, 0.6);
    font-size: 16px;
    padding: 0px 40px 20px;
    text-align: center;
  }

  .enable-imap-btn {
    font-size: 16px;
    font-weight: 700;
    padding: 10px;
    margin: 15px auto;
    width: auto;
    padding-left: 0;
    border: none;
    text-decoration: none;
    color: #4ab9ba;
  }

  .enable-imap-btn:hover {
    color: #4ab9ba;
    opacity: 0.7;
  }
`

const ImapErrorModal = ({ show, setShow, provider }) => {
  const handleClick = () => {
    setShow(false)
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <Modal show={show} centered={true} onHide={handleClose}>
      <Styled>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>
              <div style={{ textAlign: 'center', fontSize: 26 }}>&#128563;</div>
              Connection Error
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Looks like you're trying to connect your {provider} account, but your
          account is <b>not enabled</b> for IMAP use. Please visit your{' '}
          {provider} settings page and enable your account for IMAP access.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="enable-imap-btn"
            href="https://support.unroll.me/hc/en-us/articles/16978429189133-How-to-enable-IMAP"
            target="_blank"
            onClick={handleClick}>
            Enable IMAP
          </Button>
        </Modal.Footer>
      </Styled>
    </Modal>
  )
}

export default ImapErrorModal
