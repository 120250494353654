import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import { Button, Col, Form, Spinner } from 'react-bootstrap'
import { VerificationInputStyles, Wrapper } from '../../styled'
import { SubmitButtonStyles, Text } from '../EnterMailbox/styled'
import { schema } from './schema'
import {
  authenticateUser,
  submitEmailForLogin
} from '../../../../../containers/App/AppActions'
import { updateAddMailboxModal } from '../../../../../redux/slice/app.slice'
import { buildParams } from '../../../../../containers/CustomAuth/utils'
import { useAuth } from '../../../../../hook/useAuth'
import { useNotifications } from '../../../../../contexts/Notifications'

export const VerificationCode = ({ email, provider }) => {
  const { loadNewUsers } = useAuth()
  const [showResend, setShowResend] = useState(false)
  const [sendingResend, setSendingResend] = useState(false)
  const dispatch = useDispatch()
  const { notifyError } = useNotifications()

  const [isResendDisabled, setResendDisabled] = useState(false)
  const [countdown, setCountdown] = useState(0)
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    let countdownTimer

    if (isResendDisabled) {
      countdownTimer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1)
      }, 1000)
    }

    return () => {
      clearInterval(countdownTimer)
    }
  }, [isResendDisabled])

  useEffect(() => {
    setTimeout(() => {
      setShowResend(true)
    }, 30000)
  }, [])

  const handleResend = (event, formProps) => {
    setResendDisabled(true)
    setSendingResend(true)
    submitEmailForLogin(email, true)
      .then(() => {
        setCountdown(30)
        setResendDisabled(true)

        setTimeout(() => {
          setResendDisabled(false)
        }, 30000)

        formProps.setErrors({})
        // setShowResend(false)
      })
      .catch(err => {
        setSendingResend(false)
        let errMsg = 'Something went wrong. Try again.'
        if (err.response && err.response.status === 400) {
          errMsg = get(
            err,
            'response.data.detail',
            'Email address is not associated with an Unroll.Me account'
          )
        }

        formProps.setErrors({ email: errMsg })
      })
  }

  const handleSubmit = async (data, actions) => {
    setShowResend(false)
    const params = buildParams({}, email, data.code, provider)

    try {
      await authenticateUser({ ...params }, true)
      loadNewUsers()
      dispatch(updateAddMailboxModal({ show: false, type: undefined }))
    } catch (err) {
      actions.setSubmitting(false)
      let errMsg = 'Something went wrong. Try again.'

      if (err?.response?.status === 400) {
        errMsg = get(err, 'response.data.detail', errMsg)
        if (errMsg === 'Code is expired') {
          errMsg = codeExpiredErr
        }
      }

      if (err?.response?.status === 500) {
        errMsg = 'Wrong code'
        notifyError(
          'An invalid verification code was entered. Please ensure that you enter the code from your last verification email.'
        )
      }
      actions.setErrors({ code: errMsg })
      setShowResend(true)
    }
  }

  const initialValues = {
    code: ''
  }

  return (
    <Wrapper>
      <Text
        style={{ fontSize: '18px', textAlign: 'center', maxWidth: '500px' }}>
        A verification code has been sent to the email address you provided.
        Please check your email (and junk folder, if you don't see it) and enter
        the code below to continue logging in to your account.
      </Text>

      {showResend && (
        <>
          <Text
            style={{
              fontSize: '18px',
              textAlign: 'center',
              fontWeight: 'bold',
              marginTop: '15px'
            }}>
            Didn't receive a Code?
          </Text>
          <Button
            onClick={handleResend}
            disabled={isResendDisabled}
            style={{
              color: isResendDisabled && !sendingResend ? '#666666' : '#00bcbb',
              fontSize: '18px',
              textAlign: 'center',
              fontWeight: 'bold',
              marginTop: '0px',
              border: 'none'
            }}
            variant="text">
            {sendingResend ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{
                    width: 18,
                    height: 18,
                    marginRight: 8,
                    color: '#00bcbb'
                  }}
                />
                Sending...
              </>
            ) : (
              <>Resend</>
            )}
          </Button>

          <p
            style={{
              fontSize: '14px',
              textAlign: 'center',
              color: '#404040',
              margin: 0,
              height: 10
            }}>
            {isResendDisabled &&
              countdown > 0 &&
              `Resend will be available in ${countdown} seconds`}
          </p>
        </>
      )}

      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        initialValues={initialValues}>
        {({
          handleSubmit,
          isSubmitting,
          handleChange,
          values,
          touched,
          errors
        }) => (
          <Form
            noValidate
            onSubmit={handleSubmit}
            style={{ marginBottom: '20px' }}>
            <Form.Group as={Col} controlId="emailValidation">
              <Form.Control
                style={VerificationInputStyles}
                disabled={isSubmitting}
                className="text-center"
                maxLength={6}
                type="text"
                name="code"
                value={values.code}
                onChange={handleChange}
                isInvalid={!!errors.code}
                placeholder="Verification Code"
              />
              <Form.Control.Feedback
                type="invalid"
                style={{
                  fontSize: '14px',
                  textAlign: 'center',
                  marginTop: 10
                }}>
                {errors.code}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              style={SubmitButtonStyles}
              type="submit"
              disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ width: 18, height: 18, marginRight: 8 }}
                  />
                  Checking...
                </>
              ) : (
                <>Continue</>
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}
