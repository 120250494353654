import React from 'react'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import SubscriptionButtonsGroup from '../SubscriptionButtonsGroup'
import { useSubscriptions } from '../context/SubscriptionsProvider'

const Styled = styled.div`
  display: flex;
`

const STATE_SUBTYPE_MAP = new Map([
  ['inbox', 'keep'],
  ['blocked', 'blocked'],
  ['rollup', 'rollup']
])
const Subtitle = () => {
  const context = useSubscriptions()
  const handleNextClick = () => {
    context.selectNextSubscription()
  }

  const handlePreviousClick = () => {
    context.selectPreviousSubscription()
  }

  return (
    <Styled className="header-subtitle">
      <div style={{ minWidth: 120 }}>
        {context.hasPrevious && (
          <Button
            disabled={!context.hasPrevious}
            variant="link"
            className="navigation-btn"
            onClick={handlePreviousClick}>
            <MdNavigateBefore />
            Previous
          </Button>
        )}
      </div>
      <SubscriptionButtonsGroup
        subscription={
          context.type === 'rolledup'
            ? context.selectedSubscription.subscription
            : context.selectedSubscription
        }
        subtype={
          context.type === 'rolledup'
            ? STATE_SUBTYPE_MAP.get(
                context.selectedSubscription.subscription.state
              )
            : context.subtype
        }
        type={context.type}
        selectedSubscription={context.selectedSubscription}
        setSelectedSubscription={
          context.type === 'rolledup'
            ? null
            : context.changeSelectedSubscription
        }
      />
      <div style={{ minWidth: 120 }}>
        {context.hasNext && (
          <Button
            disabled={!context.hasNext}
            variant="link"
            className="navigation-btn"
            onClick={handleNextClick}>
            Next <MdNavigateNext />
          </Button>
        )}
      </div>
    </Styled>
  )
}

export default Subtitle
