import url from 'url'
import crypto from 'crypto'
import { cloneDeep } from 'lodash'
import { parse, stringify } from 'query-string'
import { providerData as providerDataAll } from './config'

const invalidPrototcolRegex = /^(%20|\s)*(javascript|data)/im

const genNonce = () => {
  const nonceLen = 32

  return crypto
    .randomBytes(Math.ceil((nonceLen * 3) / 4))
    .toString('base64')
    .slice(0, nonceLen)
}

const sanitizeUrl = url => {
  if (invalidPrototcolRegex.test(url)) {
    return ''
  }

  return url
}

// forceOauthSignup is for testing purpose only. Should be removed after testing is finished.
const showCustomAuth = (provider, isAuthorization, forceOauthSignup) => {
  if (provider === 'google' && !isAuthorization && !forceOauthSignup) {
    return true
  }
  if (provider === 'icloud') {
    return true
  }

  return false
}

const loginMiddleware = (
  selectedProvider,
  afterLoginRedirect,
  email,
  isAuthorization,
  isReauthenticating
) => {
  // Two scenarios
  // 1. Button Click
  // 2. programatical
  const providerData = cloneDeep(providerDataAll)
  const parsedSearch = parse(window.location.search)
  const avoidIosUnrollMeRedirectProviders = ['outlook']

  const redirectOptions = {
    query: {
      redirect: isReauthenticating ? '' : afterLoginRedirect,
      ...parsedSearch
    }
  }

  if (!avoidIosUnrollMeRedirectProviders.includes(selectedProvider)) {
    redirectOptions.redirect_uri = `${window.location.origin}/a/oauth-result/${selectedProvider}`
  }

  redirectOptions.query.redirect = sanitizeUrl(redirectOptions.query.redirect)

  if (isReauthenticating) {
    redirectOptions.query.reauth = true
  }

  // Support Custom Auth for Google
  if (
    showCustomAuth(
      selectedProvider,
      isAuthorization,
      parsedSearch.signup_method === 'oauth'
    )
  ) {
    return `${window.location.origin}/authenticate?${stringify({
      ...redirectOptions.query,
      provider: selectedProvider
    })}`
  }

  if (isAuthorization) {
    redirectOptions.query = {
      redirect: '',
      email,
      isAuthorization
    }
    if (selectedProvider === 'google') {
      providerData[selectedProvider].authUrl.query.include_granted_scopes = true
      providerData[selectedProvider].authUrl.query.nonce = genNonce()
      providerData[selectedProvider].authUrl.query.access_type = 'offline'
      providerData[selectedProvider].authUrl.query.scope =
        providerData[selectedProvider].authorizeScope
    }
    if (selectedProvider === 'yahoo') {
      providerData[selectedProvider].authUrl.query.access_type = 'offline'
      providerData[selectedProvider].authUrl.query.scope =
        providerData[selectedProvider].authorizeScope
    }
  }

  const objJsonStr = window.btoa(JSON.stringify(redirectOptions))
  providerData[selectedProvider].authUrl.query.state = objJsonStr

  if (email) {
    const { emailSelectorParam } = providerData[selectedProvider]
    providerData[selectedProvider].authUrl.query[emailSelectorParam] = email
  }

  const constructedAuthUrl = url.format(providerData[selectedProvider].authUrl)

  return constructedAuthUrl
}

export default loginMiddleware
