import '@fontsource/pt-sans'

const borders = [0, '1px solid']

const colors = {
  green: '#00d9b9',
  red: '#d06d6d',
  redDark: '#9D0000',
  yellow: '#ffb915',
  orange: '#f7931d',
  blue: '#41c1c8',
  blueDark: '#00587f',
  blueLogin: '#4B84B9',
  white: 'white',
  black: 'black',
  gray: [
    '#FAFAFA',
    '#e7e7e7',
    '#848484',
    '#3E4852',
    '#3E4852',
    'rgb(64,64,64)',
    '#b7b2b2'
  ],
  provider: {
    google: '#4285f4',
    yahoo: '#61399d',
    outlook: '#0072c6',
    aol: '#000000',
    icloud: '#54A2F2'
  },
  stats: ['#ff7a64', '#ffb915', '#00d9b9']
}
const maxWidths = ['100%', 280, 360, 420, 580, 680, 800, 1024, 1280, 1440]
const space = [0, 5, 10, 15, 20, 30, 40, 50, 60, 80]
const shadows = [
  '0 0 0 0 transparent',
  '0 4px 3px 0 rgba(0,0,0,0.04)',
  '0 4px 10px 0 rgba(0,0,0,0.1)'
]
const radii = [0, 3]

// Typography
const fontFamily = {
  default:
    '"Open Sans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
  ptSans: '"PT Sans"'
}
const fontSizes = [9, 11, 13, 14, 16, 20, 22, 26, 32, 48, 64, 72, 100]
const fontWeights = [300, 400, 600, 700]
const textStyles = {
  caps: {
    textTransform: 'uppercase',
    letterSpacing: '0.02em'
  },
  capitalize: {
    textTransform: 'capitalize'
  }
}

const buttons = {
  shadows: {
    normal: '0 4px 3px 0 rgba(0,0,0,0.04)',
    hover: '0 4px 4px 0 rgba(0,0,0,0.05)',
    active: '0 2px 2px 0 rgba(0,0,0,0.07)'
  }
}

const breakpoints = ['375px', '580px', '726px', '1024px', '1140px', '1280px']

const global = {
  html: {
    boxSizing: 'border-box',
    '-webkit-text-size-adjust': '100%',
    '-ms-text-size-adjust': '100%',
    fontSize: '62.5%',
    height: '100%',
    width: '100%',
    margin: '0',
    padding: '0'
  },
  body: {
    '-webkit-font-smoothing': 'antialiased',
    color: colors.gray[3],
    fontFamily: fontFamily.default,
    fontSize: `${fontSizes[3]}px`,
    lineHeight: '1.6em',
    fontWeight: '400',
    fontStyle: 'normal',
    backgroundColor: colors.gray[0],
    height: '100%',
    width: '100%',
    margin: '0',
    padding: '0'
  }
}

const theme = {
  global,
  borders,
  buttons,
  breakpoints,
  colors,
  space,
  shadows,
  radii,
  fontFamily,
  fontWeights,
  fontSizes,
  maxWidths,
  textStyles
}

export default theme
