import React from 'react'
import styled from 'styled-components'
import { ReactComponent as InboxIcon } from '../SideBarMenu/images/inboxIcon.svg'
import { ReactComponent as RollupIcon } from '../SideBarMenu/images/rollupIcon.svg'
import { ReactComponent as SubscriptionsIcon } from '../SideBarMenu/images/subscriptionsIcon.svg'
import { useSubscriptions } from '../context/SubscriptionsProvider'

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .header-item svg {
    width: 65px;
    height: 65px;
    stroke-width: 0.5;
    stroke: rgb(0, 0, 0, 0.2);
    fill: rgb(0, 0, 0, 0.2);
    padding-bottom: 10px;
  }
  .description-item {
    font-size: 18px;
    font-weight: 600;
    color: rgb(132, 132, 132);
    text-align: center;
  }
`

let EMPTY_SUBSCRIPTIONS_MAP = new Map([
  [
    'new',
    [
      <InboxIcon />,
      'New subscriptions will appear here, ready for you to edit them and keep your inbox clean'
    ]
  ],
  ['subscriptionskeep', [<SubscriptionsIcon />, 'No Subscriptions Kept']],
  ['subscriptionsblocked', [<SubscriptionsIcon />, 'No Subscriptions Blocked']],
  [
    'subscriptionsrollup',
    [<SubscriptionsIcon />, 'No Subscriptions Rolled Up']
  ],
  ['rolledup', [<RollupIcon />, 'No Rolled Up Emails Yet']]
])

let EMPTY_SUBSCRIPTIONS_SEARCH_MAP = new Map([
  ['new', "Looks like you don't have any inboxes that match that search"],
  [
    'subscriptionskeep',
    "Looks like you don't have any keep subscriptions that match that search"
  ],
  [
    'subscriptionsBlocked',
    "Looks like you don't have any blocked subscriptions that match that search"
  ],
  [
    'subscriptionsrollup',
    "Looks like you don't have any rolluped subscriptions that match that search"
  ],
  ['rolledup', "Looks like you don't have any rollups that match that search"]
])

const EmptySubscriptions = ({ type, subtype }) => {
  const key = [type, subtype].join('')
  const { searchStr, sortedData, data } = useSubscriptions()

  const isSearched = !!searchStr
  return (
    <Styled>
      {!isSearched && (
        <div className="header-item">{EMPTY_SUBSCRIPTIONS_MAP.get(key)[0]}</div>
      )}
      <div className="description-item">
        {isSearched
          ? EMPTY_SUBSCRIPTIONS_SEARCH_MAP.get(key)
          : EMPTY_SUBSCRIPTIONS_MAP.get(key)[1]}
      </div>
    </Styled>
  )
}

export default EmptySubscriptions
