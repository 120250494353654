const events = {
  login: {
    pageName: 'Log In'
  },
  signup: {
    pageName: 'Sign Up'
  },
  reauthenticate: {
    pageName: 'Reauthenticate'
  },
  reauthenticateFinalize: {
    pageName: 'Reauthenticate'
  },
  appPasswordLogin: {
    pageName: 'App Password Login',
    dontChargeModalClicked: 'Modal: What We Do With Your Data',
    twoStepClicked: 'App Password: Enable 2 Step',
    createPasswordClicked: 'App Password: Create App Password',
    cancelClicked: 'App Password: Cancel Password Creation',
    continueClicked: 'App Password: Continue To Connect Page',
    supportButtonClicked: 'Requested Support',
    blogLinkClicked: 'External Link: Rakuten Intelligence Blog'
  },
  appPasswordConnect: {
    pageName: 'App Password Connect',
    dontChargeModalClicked: 'Modal: What We Do With Your Data',
    backClicked: 'App Password: Back to Password Creation',
    continueClicked: 'App Password: Continue to Account Login',
    passwordError: 'App Password: Password Error',
    supportButtonClicked: 'Requested Support',
    loginErrorEvent: 'App Password: Password Error',
    loginErrorProp: 'Error Message'
  },
  oauthResult: {
    pageName: 'Oauth Result',
    failed: 'Connect an Account: Error',
    errMsgProp: 'Error Message',
    declinedAuth: 'Declined Authorization'
  },
  tosModal: {
    modalLoad: 'Modal: Terms of Service',
    clickedAccept: 'Connect an Account: Accept Privacy Terms',
    clickedCancel: 'Connect an Account: Decline Privacy Terms'
  },
  toErrorProviderModal: {
    modalLoad: 'Modal: Error Provider',
    clickedAccept: 'Redirect to correct Mail Provider',
    clickedCancel: 'Decline redirect to correct Mail Provider'
  },
  dontChargeModal: {
    blogLinkClicked: 'External Link: Rakuten Intelligence Blog'
  },
  manageData: {
    pageName: 'Manage My Data',
    createFile: 'Create Data File',
    downloadFile: 'Download Data File',
    researchOptOut: 'Research Opt Out',
    adOptOut: 'Ad Tracking Opt Out'
  },
  common: {
    userTypeProp: 'User Type',
    userType: {
      organic: 'Organic User',
      paid: 'Paid User',
      reauthenticating: 'Reauthenticating User'
    },
    providerProp: 'Selected Provider',
    providerSelected: {
      google: 'Connect an Account: Selected Google',
      yahoo: 'Connect an Account: Selected Yahoo',
      outlook: 'Connect an Account: Selected Outlook',
      aol: 'Connect an Account: Selected AOL',
      icloud: 'Connect an Account: Selected iCloud'
    },
    providerLabel: {
      google: 'Google',
      yahoo: 'Yahoo',
      outlook: 'Outlook',
      aol: 'AOL',
      icloud: 'iCloud'
    },
    clickedAppButton: 'Visited App Store',
    signedUp: 'Signed Up',
    loggedIn: 'Logged In',
    isAppPassword: 'Is App Password',
    signupDate: 'Signup Date',
    isReauth: 'Is Reauth'
  },
  userProperties: {
    hasAppPassword: 'Has App Password',
    signupDate: 'Signup Date',
    provider: 'Provider',
    researchOptOut: 'Research Opt Out',
    adOptOut: 'Ads Opt Out'
  }
}

export default events
