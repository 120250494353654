import React, { useState } from 'react'
import Image from 'react-bootstrap/Image'
import { ButtonGroup, ToggleButton } from 'react-bootstrap'
import { FooterText, RequiredIosText, ScanningCodeWrapper } from './styled'
import { Text } from '../../styled'

export const ScanningCode = ({ nextStep }) => {
  const [selectedPlatform, setSelectedPlatform] = useState('ios')
  return (
    <ScanningCodeWrapper selectedPlatform={selectedPlatform}>
      <Text>
        Automatically connect your account by <b>scanning the code</b> for your
        device.
      </Text>

      <ButtonGroup
        style={{
          height: '50px',
          width: '340px',
          fontSize: '20px',
          border: '1px solid #404040',
          borderRadius: '25px',
          marginTop: '30px'
        }}>
        <ToggleButton
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
            fontWeight: 'bold',
            borderRadius: '25px',
            width: '170px',
            border: 'none'
          }}
          className={`toggle-button ${
            selectedPlatform === 'ios' ? 'checked' : ''
          }`}
          type="radio"
          id="radio-ios"
          variant="outline-success"
          name="radio"
          value={'ios'}
          checked={selectedPlatform === 'ios'}
          onChange={e => setSelectedPlatform('ios')}>
          iOS
        </ToggleButton>
        <ToggleButton
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            fontSize: '20px',
            borderRadius: '25px',
            width: '170px',
            border: 'none'
          }}
          className={`toggle-button ${
            selectedPlatform !== 'ios' ? 'checked' : ''
          }`}
          type="radio"
          id="radio-android"
          variant="outline-success"
          name="radio"
          value={'android'}
          checked={selectedPlatform === 'android'}
          onChange={e => setSelectedPlatform('android')}>
          Android
        </ToggleButton>
      </ButtonGroup>
      {selectedPlatform === 'ios' ? (
        <Image
          style={{ paddingTop: '20px', height: '204px' }}
          width={150}
          src="https://unroll.me/assets/img/ios-appclip-code.png"
        />
      ) : (
        <Image
          style={{ paddingTop: '20px', height: '204px' }}
          width={150}
          src="https://unroll.me/assets/img/android-qr-code.png"
        />
      )}

      <RequiredIosText>
        {selectedPlatform === 'ios' ? 'Requires iOS 14.3 or later' : ''}
      </RequiredIosText>

      <FooterText onClick={nextStep}>
        Sign up <b>manually</b> instead
      </FooterText>
    </ScanningCodeWrapper>
  )
}
