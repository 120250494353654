import React from 'react'
import styled from 'styled-components'
import SubscriptionGroupItem from './SubscriptionGroupItem'
import SubscriptionSingleItem from './SubscriptionSingleItem'
import SubscriptionRollupItem from './SubscriptionRollupItem'

const Styled = styled.div`
  // height: 85px;
`

const SubscriptionItem = ({
  subscription,
  type,
  subtype,
  setSelectedSubscription,
  selectedSubscription
}) => {
  return (
    <Styled>
      {type === 'rolledup' ? (
        <SubscriptionRollupItem
          subscription={subscription}
          type={type}
          subtype={subtype}
          setSelectedSubscription={setSelectedSubscription}
          selectedSubscription={selectedSubscription}
        />
      ) : (
        <SubscriptionSingleItem
          subscription={subscription}
          type={type}
          subtype={subtype}
          setSelectedSubscription={setSelectedSubscription}
          selectedSubscription={selectedSubscription}
        />
      )}
    </Styled>
  )
}

export default SubscriptionItem
