import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl.macro'
import Button from '../Button'
import Box from '../Box'
import Text from '../Text'
import Badge from '../Badge'
import { fetchSubscriptions } from '../../containers/Subscriptions/SubscriptionsActions'
import { logoutUser } from '../../containers/App/AppActions'
import UserMenu from './UserMenu'
import { track } from '../../global/eventTracking'
import { formatMessages } from '../../i18n/utils'

const messageDescriptors = defineMessages({
  your: {
    id: 'components.MainNav.Authenticated.your',
    defaultMessage: 'Your '
  },
  rollup: {
    id: 'components.MainNav.Authenticated.rollup',
    defaultMessage: 'Rollup'
  },
  edit: {
    id: 'components.MainNav.Authenticated.edit',
    defaultMessage: 'Edit'
  },
  subscriptions: {
    id: 'components.MainNav.Authenticated.subscriptions',
    defaultMessage: 'Subscriptions'
  },
  new: {
    id: 'components.MainNav.Authenticated.new',
    defaultMessage: 'new'
  }
})

class MainNavAuthenticated extends PureComponent {
  componentDidMount() {
    const {
      isNewSubscriptionsLoading,
      isNewSubscriptionsFetching,
      fetchSubscriptions
    } = this.props
    // if already got subscriptions (when going through onboarding)
    // then don't grab them again
    if (isNewSubscriptionsLoading && !isNewSubscriptionsFetching) {
      fetchSubscriptions()
    }
  }

  render() {
    const {
      userEmail,
      newSubscriptionCount,
      authenticateUser,
      isCCPAWhitelisted
    } = this.props
    const messages = formatMessages(messageDescriptors)
    return (
      <Box>
        <Button
          to="/rollup"
          onClick={() =>
            track('main_navigation_action', { clickedOn: 'Your Rollup' })
          }
          link
          as={NavLink}
          fontSize={[2, 3]}
          py={[2, 3]}
          pr={5}>
          <Text as="span" display={['none', 'none', 'none', 'inline']}>
            {messages.your}
          </Text>
          {messages.rollup}
        </Button>

        <Button
          to="/subscriptions/new"
          onClick={() =>
            track('main_navigation_action', {
              clickedOn: 'Edit Susbscriptions'
            })
          }
          link
          as={NavLink}
          fontSize={[2, 3]}
          py={[2, 3]}
          pr={5}>
          {messages.edit}
          <Text as="span" display={['none', 'none', 'none', 'inline']}>
            {' '}
            {messages.subscriptions}
          </Text>
          {newSubscriptionCount > 0 ? (
            <Badge
              count={newSubscriptionCount}
              overflowCount={9999}
              text={messages.new}
              position="absolute"
              top="5px"
              right="7px"
              bg="green"
              display={['none', 'inline-flex']}
            />
          ) : null}
        </Button>
        <UserMenu
          userEmail={userEmail}
          isCCPAWhitelisted={isCCPAWhitelisted}
          authenticateUser={authenticateUser}
        />
      </Box>
    )
  }
}

MainNavAuthenticated.propTypes = {
  userEmail: PropTypes.string,
  authenticateUser: PropTypes.func.isRequired,
  newSubscriptionCount: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  isNewSubscriptionsLoading: state.subscriptions.getIn([
    'isLoadingFilters',
    'new'
  ]),
  isNewSubscriptionsFetching: state.subscriptions.getIn([
    'isFetchingFilters',
    'new'
  ]),
  newSubscriptionCount: state.subscriptions.getIn([
    'subscriptionCounts',
    'new'
  ]),
  isCCPAWhitelisted: state.app.isCCPAWhitelisted
})

const mapDispatchToProps = {
  fetchSubscriptions,
  authenticateUser: logoutUser
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainNavAuthenticated)
