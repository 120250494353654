import React, { useEffect, useRef, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNotifications } from '../../../contexts/Notifications'
import {
  Content,
  NotificationsWrapper,
  OpacityBottom,
  OpacityTop
} from './styled'

const Notificator = () => {
  const { removeNotificationById } = useNotifications()
  const [showBottom, setShowBottom] = useState(false)
  const ref = useRef(null)

  const notifications = useSelector(store => {
    return store.app.notifications
  })

  useEffect(() => {
    setShowBottom(ref?.current?.offsetHeight >= 260)
  }, [ref?.current?.offsetHeight])

  return (
    <NotificationsWrapper>
      {notifications?.length > 0 && <OpacityTop />}

      <Content data-testid="notifications" ref={ref}>
        {notifications.map(notification => (
          <Alert
            style={{ margin: 0 }}
            key={notification.id}
            dismissible
            onClose={() => {
              removeNotificationById(notification.id)
            }}
            variant={notification.type}>
            {notification.title && (
              <Alert.Heading>{notification.title}</Alert.Heading>
            )}
            <p>{notification.message}</p>
          </Alert>
        ))}
      </Content>

      {showBottom && <OpacityBottom />}
    </NotificationsWrapper>
  )
}

export default Notificator
