import React from 'react'
import { Button, Modal } from 'react-bootstrap'

import styled from 'styled-components'
import { useAuth } from '../../../hook/useAuth'

const Styled = styled.div`
  font-size: 18px;

  .modal-title {
    font-size: 22px;
    font-weight: bold;
    color: #404040;
    padding-left: 40px;
    margin: auto;
    text-transform: capitalize;
  }

  .modal-title svg {
    margin: auto;
    font-size: 28px;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .modal-header {
    padding-top: 10px;
    height: 85px;
    border-bottom: none;
  }

  .modal-header button {
    margin: 0 !important;
    margin-right: 20px !important;
    font-size: 10px;
    padding: 10px;
    border: 1px solid rgb(0, 0, 0, 0.5);
    border-radius: 50%;
  }

  .modal-body {
    color: rgb(0, 0, 0, 0.6);
    font-size: 18px;
    padding: 0px 30px 20px;
    text-align: center;
  }

  .continue-auth-btn {
    font-size: 16px;
    font-weight: 700;
    padding: 10px;
    margin: 15px auto;
    width: auto;
    padding-left: 0;
    border: none;
    text-decoration: none;
    color: #4ab9ba;
  }

  .continue-auth-btn:hover {
    color: #4ab9ba;
    opacity: 0.7;
  }
`

const LinkWrapper = styled.a`
  color: #00bcbb;
  text-decoration: none;

  &:hover {
    color: #00cccb;
    text-decoration: none;
  }
`

const PrivacyWrapper = styled.div`
  width: 80%;

  margin: auto;
  margin-top: 10px;
  word-break: break-word;
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
  font-size: 16px;
  padding-bottom: 10px;
`

const AuthAccountModal = ({ show, setShow, provider, email }) => {
  const { showAddMailboxWindow } = useAuth()
  const handleAccept = () => {
    showAddMailboxWindow(provider)
    setShow(false)
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <Modal show={show} centered={true} onHide={handleClose}>
      <Styled>
        <Modal.Header closeButton>
          <Modal.Title>Connect {provider} Mailbox</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <PrivacyWrapper>
              By connecting your <b>{email}</b> mailbox with Unroll.Me, you
              understand that you may be enrolled in our{' '}
              <LinkWrapper target="_blank" href="/your-data">
                measurement panel
              </LinkWrapper>
              , and you are agreeing to our{' '}
              <LinkWrapper target="_blank" href="/legal/terms">
                Terms of Service
              </LinkWrapper>{' '}
              and{' '}
              <LinkWrapper target="_blank" href="/legal/privacy">
                Privacy Policy
              </LinkWrapper>
              .
            </PrivacyWrapper>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="continue-auth-btn"
            onClick={handleAccept}>
            Continue Connect
          </Button>
        </Modal.Footer>
      </Styled>
    </Modal>
  )
}

export default AuthAccountModal
