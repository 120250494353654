import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Icon from '../Icon'
import Box from '../Box'
import Text from '../Text'
import Button from '../Button'

const Modal = ({
  closeModal,
  title,
  maxWidth,
  disableBackgroundClick = false,
  p,
  history,
  titleSize,
  subTitle,
  children,
  forceModal,
  borderRadius,
  titleTextAlign,
  titleColor,
  titleFontWeight,
  titleTopMargin,
  bg,
  ...props
}) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{title} – Unroll.Me</title>
        <body className="modal-open" />
      </Helmet>
      <Box
        position="fixed"
        top={0}
        right={0}
        bottom={0}
        left={0}
        display="flex"
        bg={bg === 'dark' ? 'rgba(0,0,0,0.7)' : 'rgba(255,255,255,0.7)'}
        py={6}
        px={[3, 3, 4]}
        overflow="auto"
        flexDirection="column"
        zIndex={1000}>
        <ModalContent
          role="dialog"
          aria-labelledby={title}
          aria-describedby={title}
          tabindex="0"
          bg="white"
          boxShadow={1}
          border={1}
          borderColor="gray.1"
          borderRadius={borderRadius || 1}
          m="auto"
          width="100%"
          maxWidth={maxWidth || '480px'}
          p={p || 4}
          position="relative"
          zIndex={50}>
          {!forceModal && (
            <Box position="absolute" top="-32px" left="100%" ml="-38px">
              <Button
                onClick={() => (closeModal ? closeModal() : history.goBack())}
                aria-label="close"
                link>
                <Icon type="delete" />
              </Button>
            </Box>
          )}
          <Box position="relative" mb={4} {...props} p={0}>
            <Text
              as="h4"
              fontWeight={titleFontWeight || 1}
              fontSize={titleSize || 5}
              m={0}
              mt={titleTopMargin || 0}
              lineHeight={['1.3em']}
              textAlign={titleTextAlign || 'initial'}
              color={titleColor || 'gray.3'}
              display="block">
              {title}
            </Text>
            {subTitle ? (
              <Text
                as="h4"
                fontWeight={0}
                fontSize={[2, 3, 4]}
                m={0}
                mt={[0, 1, 2]}
                color="gray.3">
                {subTitle}
              </Text>
            ) : null}
          </Box>
          <Box>{children}</Box>
        </ModalContent>
        <Box
          position="fixed"
          top="0"
          bottom="0"
          left="0"
          right="0"
          zIndex={40}
          onClick={() => {
            if (disableBackgroundClick) return null
            return closeModal ? closeModal() : history.goBack()
          }}
        />
      </Box>
    </React.Fragment>
  )
}

const ModalContent = styled(Box)`
  -ms-flex-negative: 0;
`

Modal.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  closeModal: PropTypes.func
}

export default connect(null)(Modal)
