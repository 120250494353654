import { axiosMethods as axios } from '../../global/axiosHelpers'
import { addNotification } from '../../components/Notification/NotificationActions'
import { buildNotificationText } from './SubscriptionsUtilities'
import { track } from '../../global/eventTracking'

export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS'
export const UPDATE_SUBSCRIPTION_STATE = 'UPDATE_SUBSCRIPTION_STATE'
export const UPDATE_SUBSCRIPTION_CATEGORY = 'UPDATE_SUBSCRIPTION_CATEGORY'
export const OPEN_FORCE_SHARE_MODAL = 'OPEN_FORCE_SHARE_MODAL'
export const INITIAL_EDITING_DONE = 'INITIAL_EDITING_DONE'
export const SET_SUBSCRIPTION_AWAITING_ROLLUP_ACTIVATION =
  'SET_SUBSCRIPTION_AWAITING_ROLLUP_ACTIVATION'
export const FETCH_SUBSCRIPTIONS_REQUEST = 'FETCH_SUBSCRIPTIONS_REQUEST'
export const FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS'
export const SET_SUBSCRIPTIONS_FILTER = 'SET_SUBSCRIPTIONS_FILTER'
export const SET_ROW_EXPANDED = 'SET_ROW_EXPANDED'
export const ROLLUP_RECOMMENDATIONS_FETCHED = 'ROLLUP_RECOMMENDATIONS_FETCHED'
export const SET_CURRENT_SUBSCRIPTION_FILTER = 'SET_CURRENT_SUBSCRIPTION_FILTER'
export const RESET_STATE = 'RESET_STATE'

function fetchSubscriptionsRequest(filter) {
  return {
    type: FETCH_SUBSCRIPTIONS_REQUEST,
    payload: {
      filter
    }
  }
}

export function fetchSubscriptionsAsync(filter, subscriptions, total, nextUrl) {
  return {
    type: FETCH_SUBSCRIPTIONS,
    payload: {
      filter,
      subscriptions,
      total,
      nextUrl
    }
  }
}

export function fetchSubscriptions(filter = 'new') {
  return async (dispatch, getState) => {
    // get next page url from redux state
    let url = getState().subscriptions.getIn(['fetchUrls', filter])
    const isRollupActive = getState().app.user.settings.rollups.is_active
    if (isRollupActive === false && filter === 'inbox') {
      url += ',deactivated_rollup'
    }
    // if url is null then no more subscriptions
    if (!url) {
      return
    }
    // get last domain name of last object in current subs list so can
    // compare to new list that is pulled from api. If they're the same,
    // then keep grabbing more subs or else infinite scrolling loader will
    // stall out. Happens when there is a group of > 20 subs in a single row
    let domain = null
    const lastItem = getState()
      .subscriptions.getIn(['subscriptionsGroups', filter])
      .last()
    if (lastItem) {
      domain = lastItem.get('domain')
    }
    dispatch(fetchSubscriptionsRequest(filter))
    const fetchSubs = async (url, entities) => {
      const res = await axios.get(url)
      entities = entities.concat(res.data.data)
      const lastDomain =
        entities[entities.length - 1] && entities[entities.length - 1].domain
      if (lastDomain === domain && res.data.links.next) {
        return fetchSubs(res.data.links.next, entities)
      }
      return {
        filter,
        entities,
        total: res.data.pagination.total_count,
        next: res.data.links.next
      }
    }

    try {
      // const res = await axios.get(url)
      const res = await fetchSubs(url, [])
      dispatch(
        fetchSubscriptionsAsync(filter, res.entities, res.total, res.next)
      )
    } catch (err) {
      console.error('error: ', err)
      dispatch(addNotification({ context: 'negative' }))
    }
  }
}

export function setSubscriptionsFilter(filter) {
  return {
    type: SET_SUBSCRIPTIONS_FILTER,
    payload: { filter }
  }
}

export function setRowExpanded(filter, domain, rowIndex, isExpanded) {
  return {
    type: SET_ROW_EXPANDED,
    payload: {
      filter,
      domain,
      rowIndex,
      isExpanded
    }
  }
}

export function updateSubscriptionStateAsync(
  subscriptionId,
  filter,
  currentFilter,
  inGroup,
  groupSize,
  inGroupIds
) {
  return {
    type: UPDATE_SUBSCRIPTION_STATE,
    payload: {
      subscriptionId,
      filter,
      currentFilter,
      inGroup,
      groupSize,
      inGroupIds
    }
  }
}

export function updateSubscriptionState(
  subscriptionId,
  filter,
  currentFilter,
  domainName,
  inGroup = false,
  groupSize,
  inGroupIds
) {
  return async (dispatch, getState) => {
    const subsIds = subscriptionId.split(',')
    let notifyName = domainName
    // if name not a parameter, get from state
    if (!domainName) {
      const subsFromState = getState().subscriptions
      const combinedSubs = subsFromState
        .get('subscriptionsById')
        .merge(subsFromState.get('recommendationsById'))
      const domainNames = subsIds.reduce((uniqDomains, subscriptionId) => {
        const domain = combinedSubs.get(subscriptionId).getIn([0, 'domain'])
        if (uniqDomains[domain]) return uniqDomains
        return { ...uniqDomains, [domain]: domain }
      }, {})
      notifyName = Object.keys(domainNames).join(', ')
    }
    dispatch(addNotification(buildNotificationText(notifyName, filter)))
    dispatch(
      updateSubscriptionStateAsync(
        subscriptionId,
        filter,
        currentFilter,
        inGroup,
        groupSize,
        inGroupIds
      )
    )
    try {
      await axios.patch(
        `${process.env.REACT_APP_API}/subscriptions/${subscriptionId}`,
        {
          state: filter
        }
      )
    } catch (err) {
      dispatch(addNotification({ context: 'negative' }))
    }
  }
}

export function updateSubscriptionCategoryAsync(subscriptionId, category) {
  return dispatch => {
    dispatch({
      type: UPDATE_SUBSCRIPTION_CATEGORY,
      payload: {
        subscriptionId,
        category
      }
    })
  }
}

export function updateSubscriptionCategory(
  subscriptionId,
  category,
  notification
) {
  return async dispatch => {
    dispatch(addNotification(notification))
    try {
      await axios.patch(
        `${process.env.REACT_APP_API}/subscriptions/${subscriptionId}`,
        {
          category
        }
      )
      dispatch(updateSubscriptionCategoryAsync(subscriptionId, category))
    } catch (err) {
      console.error('error: ', err)
      dispatch(addNotification({ context: 'negative' }))
    }
  }
}

export function openForceShareModal() {
  track('onboarding_share_modal_action', {
    status: 'Initial Edit Share'
  })
  return dispatch => {
    dispatch({
      type: OPEN_FORCE_SHARE_MODAL
    })
  }
}

export function doneInitialEditing() {
  return dispatch => {
    dispatch({
      type: INITIAL_EDITING_DONE
    })
  }
}

export function setSubscriptionAwaitingRollupActivation(id, name) {
  return dispatch => {
    dispatch({
      type: SET_SUBSCRIPTION_AWAITING_ROLLUP_ACTIVATION,
      payload: {
        id,
        name
      }
    })
  }
}

export function getRollupRecommendations() {
  return async dispatch => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/subscriptions?is_rollup_recommended=true&state=new`
      )
      dispatch({ type: ROLLUP_RECOMMENDATIONS_FETCHED, payload: res.data.data })
    } catch (err) {
      console.error('error: ', err)
      dispatch(addNotification({ context: 'negative' }))
    }
  }
}

export function setCurrentSubscriptionFilter(filter) {
  return {
    type: SET_CURRENT_SUBSCRIPTION_FILTER,
    payload: {
      filter
    }
  }
}

function resetState(filter) {
  return {
    type: RESET_STATE,
    payload: {
      filter: filter
    }
  }
}

export function resetForRollupActivation() {
  // reset inbox and rollup and refetch
  return dispatch => {
    dispatch(resetState('inbox'))
    dispatch(resetState('rollup'))
    dispatch(fetchSubscriptions('inbox'))
    dispatch(fetchSubscriptions('rollup'))
  }
}
