import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { TbChevronRight } from 'react-icons/tb'

const Styled = styled.div`
  position: relative;
  left: ${props => (props.collapsed ? '25px' : '100px')};
  top: -180px;

  -webkit-transition: 300ms linear;
  -moz-transition: 300ms linear;
  -o-transition: 300ms linear;
  -ms-transition: 300ms linear;
  transition: 300ms linear;

  .toggle-button {
    display: block;
    margin: auto;
  }
  .toggle-button svg {
    padding-left: 2px;
    border-radius: 50%;
    background: white;
    color: rgb(0, 0, 0, 0.25);
    border: 2px solid rgb(0, 0, 0, 0.25);
    font-size: 30px;
    transform: ${props => (props.collapsed ? '' : 'rotate(180deg)')};
  }

  :hover {
    opacity: 0.8;
  }
`

const ToggleButton = ({ collapsed, setCollapsed }) => {
  return (
    <Styled collapsed={collapsed}>
      <Button
        variant="link"
        className="toggle-button"
        onClick={() => setCollapsed(!collapsed)}>
        <TbChevronRight />
      </Button>
    </Styled>
  )
}

export default ToggleButton
