import React from 'react'
import { Button } from 'react-bootstrap'
import { FooterText, Text, Wrapper } from '../../styled'
import { YouTubeWrapper } from './styled'
import { SubmitButtonStyles } from '../EnterMailbox/styled'

export const Enable2Step = ({ email, nextStep, provider }) => {
  const handleButtonClick = () => {
    nextStep()
  }

  const link =
    provider === 'google'
      ? 'https://myaccount.google.com/signinoptions/two-step-verification'
      : 'https://appleid.apple.com/account/manage'

  return (
    <Wrapper>
      <Text style={{ width: '445px', maxWidth: '445px' }}>
        Enable 2-step verification for
        <br />
        <b>{email}</b>
      </Text>
      <YouTubeWrapper
        src={
          provider === 'google'
            ? 'https://www.youtube.com/embed/RPivqAyLfHM'
            : 'https://www.youtube.com/embed/GE-LiLoDj9g'
        }
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
      <Button target="_blank" href={link} style={SubmitButtonStyles}>
        Enable 2-Step Verification
      </Button>
      <FooterText onClick={handleButtonClick}>
        2-Step Verification Already Enabled
      </FooterText>
    </Wrapper>
  )
}
