import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { KeepLabel, RollupLabel, BlockedLabel } from './styled'

const Styled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .progress {
    width: 620px;
    height: 34px;
    border-radius: 17px;
    border: solid 1px rgba(60, 194, 200, 0.75);
    background-color: #fff;
  }

  .progress-bar {
    background-color: rgba(60, 194, 200, 0.75) !important;
    border-radius: 17px;
  }

  .progress-content {
    position: relative;
    margin-top: 44px;
  }

  .progress-content-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
    color: #00bcbb;
  }

  img {
    width: 450px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 40px;
  }
`
const Tutorial2 = ({ nextStep }) => {
  return (
    <>
      <Styled>
        <h3>
          <KeepLabel>Keep</KeepLabel>, <BlockedLabel>Block</BlockedLabel>,{' '}
          <RollupLabel>Rollup</RollupLabel>
        </h3>
        <p>
          Decluttering your inbox has never been so easy, or looked so good.
        </p>
        <p>
          With Unroll<KeepLabel>.</KeepLabel>Me, you can block unwanted emails,
        </p>
        <p>
          keep the ones you want, and rollup the rest into a single daily
          digest.
        </p>
        <img
          width="450px"
          src={`${process.env.PUBLIC_URL}/images/tutorial/tutorial21.png`}
          alt="tutorial"
        />
      </Styled>
      <Button
        onClick={() => {
          nextStep()
        }}>
        Got it!
      </Button>
    </>
  )
}

export default Tutorial2
