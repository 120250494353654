import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import UnrollApi from '../../../http/UnrollApi'
import {
  updateUserDeliveryTime,
  updateUserEmailNotification,
  updateUserOptOut
} from '../../../redux/slice/app.slice'
import './styles.css'
import DownloadMyData from './DownloadMyData'

const Styled = styled.div`
  font-size: 18px;

  .modal-title {
    font-size: 22px;
    font-weight: bold;
    color: #404040;
    padding-left: 40px;
    margin: auto;
  }

  .modal-header {
    height: 85px;
  }

  .modal-header button {
    margin: 0 !important;
    margin-right: 20px !important;
    font-size: 10px;
    padding: 10px;
    border: 1px solid rgb(0, 0, 0, 0.5);
    border-radius: 50%;
  }

  .modal-body {
    font-size: 18px;
  }

  .card-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold !important;
  }

  .card-subtitle {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    padding-top: 5px;
    color: #000 !important;
  }

  .card-text {
    padding: 5px;
    text-align: center;
  }

  .card {
    border: none;
    margin: 30px auto;
    width: 90%;
  }

  .receive-rollup .btn {
    font-size: 16px;
    font-weight: 700;
    border: 1px solid rgb(0, 0, 0, 0.6);
    margin: 15px auto;
    width: 50%;
    display: flex;
    color: rgb(0, 0, 0, 0.5);
    background: #fff;
    padding: 10px;
  }

  .receive-rollup .btn svg {
    margin-left: 33%;
    position: relative;
    margin-right: 15px;
    font-size: 22px;
  }

  .receive-rollup .btn:hover {
    opacity: 0.7;
  }

  .deactivate-btn {
    font-size: 16px;
    font-weight: 700;
    justify-content: center;
    border: 1px solid rgb(0, 0, 0, 0.6);
    margin: 15px auto;
    width: 50%;
    display: flex;
    color: rgb(0, 0, 0, 0.5);
    background: #fff;
    padding: 10px;
  }

  .deactivate-btn:hover {
    color: rgb(0, 0, 0, 0.5);
    background: white;
    border: 1px solid rgb(0, 0, 0, 0.7);
    opacity: 0.7;
  }

  .delete-account-btn {
    font-size: 16px;
    font-weight: 700;
    width: 50%;
    padding: 10px;
    margin: 15px auto;
  }

  .manage-data .card-subtitle {
    width: 90%;
    margin: auto;
  }

  .manage-data .card-subtitle a {
    text-decoration: none;
    color: #00bcbb;
  }

  .manage-data label {
    width: 100%;
    text-align: start;
    font-size: 16px;
    font-weight: bold;
  }

  .manage-data .form-check-input:checked {
    background-color: #4ab9ba;
    border: 1px solid rgb(0, 0, 0, 0.25);
  }

  .manage-data .form-check {
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
  }
  .receive-rollup label {
    width: 100%;
    text-align: start;
    font-size: 16px;
    font-weight: bold;
  }

  .receive-rollup .form-check-input:checked {
    background-color: #4ab9ba;
    border: 1px solid rgb(0, 0, 0, 0.25);
  }

  .receive-rollup .form-check {
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
  }

  .delivery-check label {
    width: 100%;
    text-align: start;
    font-size: 16px;
    font-weight: normal;
  }

  .delivery-check .form-check {
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
  }

  .delivery-check .selected label {
    font-weight: bold;
  }

  .delivery-check .selected input {
    background-image: none;
    background: #4ab9ba;
    border: none;
    font-weight: bold;
  }

  .account-delete .card-text {
    text-align: start;
  }

  .account-delete .delete-account-btn {
    width: auto;
    padding-left: 0;
    border: none;
    text-decoration: none;
    color: #c83c3c;
  }

  .account-delete .delete-account-btn:hover {
    opacity: 0.7;
  }
`

const SettingsModal = ({
  show,
  setShow,
  setShowDeleteModal,
  handleShowProceedAccountModal
}) => {
  const user = useSelector(state => state.app.user)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingOptOut, setIsLoadingOptOut] = useState(false)
  const [isLoadingEmailNotification, setIsLoadingEmailNotification] = useState(
    false
  )

  const deliveryTime = user.settings.rollups.delivery_time
  const isOptedOut = user.settings.is_opted_out_of_data_sharing
  const isEmailNotification = user.settings.rollups?.email
  const dispatch = useDispatch()

  const handleDeleteAccount = () => {
    setShowDeleteModal(true)
    setShow(false)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleChangeDeliveryTime = async deliveryTime => {
    setIsLoading(true)
    const result = await UnrollApi({
      url: '/users/me',
      method: 'PATCH',
      data: { settings: { rollups: { delivery_time: deliveryTime } } }
    })
    if (result.status === 200) {
      dispatch(updateUserDeliveryTime(deliveryTime))
    }
    setIsLoading(false)
  }

  const handleOptedOut = async event => {
    setIsLoadingOptOut(true)
    const isOptOut = !event.target.checked
    const result = await UnrollApi({
      url: '/users/me',
      method: 'PATCH',
      data: { settings: { is_opted_out_of_data_sharing: isOptOut } }
    })
    if (result.status === 200) {
      dispatch(updateUserOptOut(isOptOut))
    }
    setIsLoadingOptOut(false)
  }

  const handleChangeEmailNotification = async event => {
    setIsLoadingEmailNotification(true)
    const isEmailNotification = event.target.checked
    const result = await UnrollApi({
      url: '/users/me',
      method: 'PATCH',
      data: { settings: { rollups: { email: isEmailNotification } } }
    })
    if (result.status === 200) {
      dispatch(updateUserEmailNotification(isEmailNotification))
    }
    setIsLoadingEmailNotification(false)
  }

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Styled>
        <Modal.Header closeButton>
          <Modal.Title>Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="receive-rollup">
            <Card.Body>
              <Card.Title className="text-center">Rollup</Card.Title>
              <Card.Subtitle className="mb-2 text-muted text-center">
                When would you like to receive your daily rollup?
              </Card.Subtitle>
              <Card.Text className={`text-center ${deliveryTime}`}>
                <div className="delivery-check">
                  <Form.Check
                    reverse
                    label="Morning"
                    name="rollup"
                    type="radio"
                    id="morning"
                    className={deliveryTime === 'morning' ? 'selected' : ''}
                    disabled={isLoading}
                    checked={deliveryTime === 'morning'}
                    onClick={() =>
                      deliveryTime !== 'morning' &&
                      handleChangeDeliveryTime('morning')
                    }
                  />
                  <Form.Check
                    reverse
                    label="Afternoon"
                    name="rollup"
                    type="radio"
                    id="afternoon"
                    className={deliveryTime === 'afternoon' ? 'selected' : ''}
                    disabled={isLoading}
                    checked={deliveryTime === 'afternoon'}
                    onClick={() =>
                      deliveryTime !== 'afternoon' &&
                      handleChangeDeliveryTime('afternoon')
                    }
                  />
                  <Form.Check
                    reverse
                    label="Evening"
                    name="rollup"
                    type="radio"
                    id="evening"
                    className={deliveryTime === 'evening' ? 'selected' : ''}
                    disabled={isLoading}
                    checked={deliveryTime === 'evening'}
                    onClick={() =>
                      deliveryTime !== 'evening' &&
                      handleChangeDeliveryTime('evening')
                    }
                  />
                </div>
              </Card.Text>
              <Card.Subtitle
                className="mb-2 text-muted text-center"
                style={{ marginTop: 20, fontWeight: 700, fontSize: 20 }}>
                Email Notifications
              </Card.Subtitle>
              <Card.Text>
                <Form.Check
                  reverse
                  type="switch"
                  label="New Rollup"
                  checked={isEmailNotification}
                  disabled={isLoadingEmailNotification}
                  onClick={handleChangeEmailNotification}
                />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="manage-data">
            <Card.Body>
              <Card.Title>Manage My Data</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Your data makes Unroll.Me work better for you and supports our
                measurement panel. We respect and acknowledge your right to
                privacy. You have full control of your own data here.{' '}
                <a
                  href="https://unroll.me/your-data/"
                  target="_blank"
                  rel="noreferrer">
                  Learn more
                </a>
              </Card.Subtitle>
              <Card.Text>
                <Form.Check
                  reverse
                  type="switch"
                  label="Measurement Panel Opt-In"
                  checked={!isOptedOut}
                  disabled={isLoadingOptOut}
                  onClick={handleOptedOut}
                />
                <DownloadMyData
                  handleShowProceedAccountModal={handleShowProceedAccountModal}
                />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="account-delete">
            <Card.Body>
              <Card.Title>My Account</Card.Title>
              <Card.Text>
                <Button
                  onClick={handleDeleteAccount}
                  variant="link"
                  className="delete-account-btn">
                  Delete My Account
                </Button>
              </Card.Text>
            </Card.Body>
          </Card>
        </Modal.Body>
        {/*<Modal.Footer>*/}
        {/*  <Button variant="secondary" onClick={handleClose}>*/}
        {/*    Close*/}
        {/*  </Button>*/}
        {/*</Modal.Footer>*/}
      </Styled>
    </Modal>
  )
}

export default SettingsModal
