import React, { useMemo } from 'react'
import { Nav } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useSubscriptions } from '../context/SubscriptionsProvider'

const Styled = styled.div`
  padding-bottom: 20px;

  .nav {
    border: none;
    // justify-content:space-around;
  }

  .nav-item {
    padding-bottom: 15px;
    width: 33.3%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: rgb(0, 0, 0, 0.25) !important;
    transition: none !important;
    border-bottom: 2px solid rgb(40, 40, 40, 0.05) !important;
    text-decoration: none !important;
  }

  .nav-link:hover {
    border: none;
    color: rgb(0, 0, 0, 0.25) !important;
    transition: none !important;
  }

  .kept-item.active-item {
    color: #3cc2c8 !important;
    border: none;
    border-bottom: 2px solid #3cc2c8 !important;
  }

  .kept-item:hover {
    color: #4cd2d8 !important;
    border-bottom: 2px solid #4cd2d8 !important;
  }

  .blocked-item.active-item {
    color: #c83c3c !important;
    border: none;
    border-bottom: 2px solid #c83c3c !important;
  }

  .blocked-item:hover {
    color: #d84c4c !important;
    border-bottom: 2px solid #d84c4c !important;
  }

  .rolled-up-item.active-item {
    color: #f7931d !important;
    border: none;
    border-bottom: 2px solid #f7931d !important;
  }

  .rolled-up-item:hover {
    color: #ffa32d !important;
    border-bottom: 2px solid #ffa32d !important;
  }
`

const SubscriptionTypeSelector = () => {
  const context = useSubscriptions()
  const user = useSelector(state => state.app.user)

  const keptData = useSelector(
    state =>
      state.SubscriptionsApi.queries[
        'getSubscriptionsByState({"state":"inbox"})'
      ]
  )
  const blockedData = useSelector(
    state =>
      state.SubscriptionsApi.queries[
        'getSubscriptionsByState({"state":"unsubscribed"})'
      ]
  )
  const rolledupData = useSelector(
    state =>
      state.SubscriptionsApi.queries[
        'getSubscriptionsByState({"state":"rollup"})'
      ]
  )

  const isScanning = useMemo(() => {
    return user?.scan_status === 'in_progress'
  }, [user])

  const isLoading =
    !isScanning &&
    (context.isLoadingSubscriptions ||
      context.isFetchingSubscriptions ||
      !user.id)

  return (
    <Styled>
      <Nav>
        <NavLink
          to="/app/subscriptions/keep"
          end={false}
          className={({ isActive }) =>
            isActive ? 'active-item nav-item kept-item' : 'nav-item kept-item'
          }>
          {keptData?.data?.pagination?.total_count && !isLoading
            ? `Kept (${keptData?.data?.pagination?.total_count})`
            : 'Kept'}
        </NavLink>
        <NavLink
          to="/app/subscriptions/blocked"
          className={({ isActive }) =>
            isActive
              ? 'active-item nav-item blocked-item'
              : 'nav-item blocked-item'
          }>
          {blockedData?.data?.pagination?.total_count && !isLoading
            ? `Blocked (${blockedData?.data?.pagination?.total_count})`
            : 'Blocked'}
        </NavLink>
        <NavLink
          to="/app/subscriptions/rollup"
          className={({ isActive }) =>
            isActive
              ? 'active-item nav-item rolled-up-item'
              : 'nav-item rolled-up-item'
          }>
          {rolledupData?.data?.pagination?.total_count && !isLoading
            ? `Rolled Up (${rolledupData?.data?.pagination?.total_count})`
            : 'Rolled Up'}
        </NavLink>
      </Nav>
    </Styled>
  )
}

export default SubscriptionTypeSelector
