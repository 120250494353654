import styled from 'styled-components'
import {
  space,
  color,
  boxShadow,
  border,
  borderTop,
  borderRight,
  borderBottom,
  borderLeft,
  borderColor,
  borderRadius,
  textAlign,
  maxWidth,
  minWidth,
  width,
  height,
  minHeight,
  display,
  position,
  lineHeight,
  fontSize,
  top,
  bottom,
  left,
  right,
  alignItems,
  alignContent,
  alignSelf,
  justifyContent,
  flexWrap,
  flexDirection,
  zIndex,
  flex,
  overflow,
  opacity,
  verticalAlign,
  style
} from 'styled-system'

const borderTopLeftRadius = style({
  prop: 'borderTopLeftRadius',
  cssProperty: 'borderTopLeftRadius'
})
const borderTopRightRadius = style({
  prop: 'borderTopRightRadius',
  cssProperty: 'borderTopRightRadius'
})
const borderBottomLeftRadius = style({
  prop: 'borderBottomLeftRadius',
  cssProperty: 'borderBottomLeftRadius'
})
const borderBottomRightRadius = style({
  prop: 'borderBottomRightRadius',
  cssProperty: 'borderBottomRightRadius'
})

const Box = styled.div`
  ${flex} ${overflow} ${space} ${color} ${boxShadow} ${borderTop} ${borderRight} ${borderBottom} ${borderLeft} ${border} ${borderColor} ${borderRadius} ${textAlign} ${maxWidth} ${minWidth} ${display} ${width} ${height} ${minHeight} ${position} ${lineHeight} ${fontSize} ${top} ${bottom} ${left} ${right} ${alignItems} ${alignSelf} ${alignContent} ${justifyContent} ${flexWrap} ${flexDirection} ${zIndex} ${opacity}
  ${verticalAlign}
  ${borderTopLeftRadius}
  ${borderTopRightRadius}
  ${borderBottomLeftRadius}
  ${borderBottomRightRadius}


`
export default Box
