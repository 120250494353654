import React from 'react'
import { defineMessages } from 'react-intl.macro'
import { ReactComponent as SignupGoogleLogo } from './signUpLogos/SignupGoogleLogo.svg'
import { ReactComponent as SignupYahooLogo } from './signUpLogos/SignupYahooLogo.svg'
import { ReactComponent as SignupOutlookLogo } from './signUpLogos/SignupOutlookLogo.svg'
import { ReactComponent as SignupAolLogo } from './signUpLogos/SignupAolLogo.svg'
import { ReactComponent as SignupIcloudLogo } from './signUpLogos/SignupIcloudLogo.svg'
import theme from './theme'
import { formatMessages } from '../i18n/utils'

const messageDescriptors = defineMessages({
  signinWithGoogle: {
    id: 'containers.Signup.SignupForm.signinWithGoogle',
    defaultMessage: 'Sign in with Google'
  },
  signinWithYahoo: {
    id: 'containers.Signup.SignupForm.signinWithYahoo',
    defaultMessage: 'Sign in with Yahoo'
  },
  signinWithOutlook: {
    id: 'containers.Signup.SignupForm.signinWithOutlook',
    defaultMessage: 'Sign in with Outlook'
  },
  signinWithAOL: {
    id: 'containers.Signup.SignupForm.signinWithAOL',
    defaultMessage: 'Sign in with AOL'
  },
  signinWithIcloud: {
    id: 'containers.Signup.SignupForm.signinWithIcloud',
    defaultMessage: 'Sign in with iCloud'
  },
  loginWithGoogle: {
    id: 'containers.Signup.SignupForm.loginWithGoogle',
    defaultMessage: 'Log in with Google'
  },
  loginWithYahoo: {
    id: 'containers.Signup.SignupForm.loginWithYahoo',
    defaultMessage: 'Log in with Yahoo'
  },
  loginWithOutlook: {
    id: 'containers.Signup.SignupForm.loginWithOutlook',
    defaultMessage: 'Log in with Outlook'
  },
  loginWithAOL: {
    id: 'containers.Signup.SignupForm.loginWithAOL',
    defaultMessage: 'Log in with AOL'
  },
  loginWithIcloud: {
    id: 'containers.Signup.SignupForm.loginWithIcloud',
    defaultMessage: 'Log in with iCloud'
  }
})

const messages = formatMessages(messageDescriptors)
const protocol = 'https://'

export const providerData = {
  google: {
    type: 'GOOGLE',
    name: 'Google',
    label: messages.signinWithGoogle,
    loginLabel: messages.loginWithGoogle,
    color: theme.colors.provider.google,
    logo: <SignupGoogleLogo />,
    authUrl: {
      host: 'accounts.google.com/o/oauth2/v2/auth',
      query: {
        access_type: 'online',
        client_id: '135239314429.apps.googleusercontent.com',
        response_type: 'code',
        scope: 'openid profile email',
        redirect_uri: 'https://ios.unroll.me/yahoo'
      }
    },
    authorizeScope: 'https://mail.google.com/',
    appPassswordUrl:
      'https://accounts.google.com/signin/v2/challenge/pwd?continue=https://myaccount.google.com/apppasswords',
    twoStepVerificationUrl:
      'https://myaccount.google.com/signinoptions/two-step-verification',
    emailSelectorParam: 'login_hint'
  },
  yahoo: {
    type: 'YAHOO',
    name: 'Yahoo',
    label: messages.signinWithYahoo,
    loginLabel: messages.loginWithYahoo,
    color: theme.colors.provider.yahoo,
    logo: <SignupYahooLogo />,
    authUrl: {
      host: 'api.login.yahoo.com/oauth2/request_auth',
      query: {
        access_type: 'online',
        client_id:
          'dj0yJmk9c3R4ZmZQaTRkdXBOJmQ9WVdrOVVHaEVaMEZ2TnpJbWNHbzlNQS0tJnM9Y29uc3VtZXJzZWNyZXQmeD1hNQ--',
        response_type: 'code',
        prompt: 'select_account',
        scope: 'openid email',
        redirect_uri: 'https://ios.unroll.me/yahoo'
      }
    },
    authorizeScope: 'openid email mail-w',
    appPassswordUrl:
      'https://login.yahoo.com/account/security/app-passwords/add/confirm?src=noSrc',
    emailSelectorParam: 'login_hint'
  },
  icloud: {
    type: 'ICLOUD',
    name: 'iCloud',
    label: messages.signinWithIcloud,
    loginLabel: messages.loginWithIcloud,
    color: theme.colors.provider.icloud,
    logo: <SignupIcloudLogo />,
    appPassswordUrl: 'https://appleid.apple.com/account/manage',
    twoStepVerificationUrl: 'https://appleid.apple.com/account/manage'
  },
  aol: {
    type: 'AOL',
    name: 'AOL',
    label: messages.signinWithAOL,
    loginLabel: messages.loginWithAOL,
    color: theme.colors.provider.aol,
    logo: <SignupAolLogo />,
    client_id: '3Dde11gE8NUlDmwG9u',
    authUrl: {
      host: 'api.login.aol.com/oauth2/request_auth',
      query: {
        approval_prompt: 'auto',
        client_id: 'de11gE8NUlDmwG9u',
        prompt: 'select_account',
        response_type: 'code',
        scope: 'email+imap_smtp+offline_access',
        redirect_uri: 'https://ios.unroll.me/yahoo'
      }
    },
    emailSelectorParam: 'login_id'
  },
  outlook: {
    type: 'AZURE',
    name: 'Azure',
    label: messages.signinWithOutlook,
    loginLabel: messages.loginWithOutlook,
    color: theme.colors.provider.outlook,
    logo: <SignupOutlookLogo />,
    authUrl: {
      host: 'login.microsoftonline.com/common/oauth2/v2.0/authorize',
      query: {
        approval_prompt: 'auto',
        client_id: '8b471a6d-d44b-4a1d-960a-ab4c0edbc54e',
        prompt: 'select_account',
        response_type: 'code',
        scope:
          'openid profile email offline_access https://outlook.office.com/IMAP.AccessAsUser.All',
        redirect_uri: `${protocol}${process.env.REACT_APP_HOST}/a/oauth/outlook`
      }
    },
    emailSelectorParam: 'username'
  }
}

export const appStoreUrls = {
  ios:
    'https://apps.apple.com/app/id1028103039?_branch_match_id=976730947870405127',
  android:
    'https://play.google.com/store/apps/details?id=com.unrollme&_branch_match_id=976730947870405127'
}
