import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import moment from 'moment/moment'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  useGetSubscriptionsByStateQuery,
  useLoadMoreSubscriptionsMutation
} from '../../../redux/Api/SubscriptionsApi'

const SubscriptionsContext = createContext({})

const getStateByTypeAndSubtype = (type, subtype) => {
  if (type === 'new') {
    return 'new'
  }
  if (type === 'rolledup') {
    return 'rolledup'
  }
  if (subtype === 'keep') {
    return 'inbox'
  } else if (subtype === 'blocked') {
    return 'unsubscribed'
  } else if (subtype === 'rollup') {
    return 'rollup'
  }
}
export const SubscriptionsProvider = ({ children, type }) => {
  const dispatch = useDispatch()
  const [searchStr, setSearchStr] = useState('')
  const [showLoadMore, setShowLoadMore] = useState(false)
  const [hasNext, setHasNext] = useState(false)
  const [hasPrevious, setHasPrevious] = useState(false)
  const [isNeedUploadMore, setIsNeedUploadMore] = useState(false)
  const [
    loadMore,
    { isLoading: isLoadingMore }
  ] = useLoadMoreSubscriptionsMutation()

  const [order, setOrder] = useState(
    type === 'rolledup' ? 'created_at' : 'domain'
  )
  const [selectedSubscription, setSelectedSubscription] = useState()
  const [direction, setDirection] = useState(
    type === 'rolledup' ? 'desc' : 'asc'
  )
  const [showFullPreview, setShowFullPreview] = useState(false)
  const { subtype } = useParams()
  const user = useSelector(state => state.app.user)
  const state = getStateByTypeAndSubtype(type, subtype)
  const [searchParams] = useSearchParams()

  const {
    data = [],
    isLoading: isLoadingSubscriptions,
    isFetching: isFetchingSubscriptions
  } = useGetSubscriptionsByStateQuery(
    {
      state: state
    },
    { skip: !user.id }
  )

  const filteredData = useMemo(() => {
    if (!!searchStr && searchStr.trim().length > 0) {
      const searchLwr = searchStr.toLowerCase()
      return data.data?.filter(subscription => {
        const name =
          type === 'rolledup'
            ? subscription?.subscription?.name
            : subscription?.name
        const email =
          type === 'rolledup'
            ? subscription?.subscription?.email
            : subscription?.email
        const domain =
          type === 'rolledup'
            ? subscription?.subscription?.domain
            : subscription?.domain

        return (
          (name && name?.toLowerCase().indexOf(searchLwr) !== -1) ||
          (domain && domain?.toLowerCase().indexOf(searchLwr) !== -1) ||
          (email && email?.toLowerCase().indexOf(searchLwr) !== -1)
        )
      })
    } else {
      return data.data || []
    }
  }, [searchStr, data])

  useEffect(() => {
    // dispatch(updateTotalCount(data?.pagination?.total_count))

    if (selectedSubscription && data && type === 'rolledup') {
      const selectedRollup = data.data.find(
        rollup => rollup.id === selectedSubscription.id
      )
      setSelectedSubscription(selectedRollup)
    }

    if (
      data &&
      type === 'rolledup' &&
      data.pagination &&
      data.pagination?.total_count > data.data.length &&
      !isNeedUploadMore
    ) {
      setIsNeedUploadMore(true)
      for (let page = 2; page <= data.pagination.total_pages; page++) {
        loadMore({ state: 'rolledup', page: page })
      }
      setIsNeedUploadMore(true)
    }
    // if (
    //   data?.pagination?.total_count &&
    //   data.data.length < data.pagination.total_count
    // ) {
    //   setShowLoadMore(true)
    // } else {
    //   setShowLoadMore(false)
    // }
  }, [data, isNeedUploadMore])

  const getDomain = subscription => {
    return Array.isArray(subscription)
      ? subscription[0].domain
      : subscription.domain
  }

  const getDate = subscription => {
    return Array.isArray(subscription)
      ? subscription[0].created_at
      : subscription.created_at
  }

  const domainComparator = (sub1, sub2) => {
    if (type === 'rolledup') {
      return direction === 'asc'
        ? sub1.subscription?.domain?.localeCompare(sub2.subscription.domain)
        : sub2.subscription?.domain?.localeCompare(sub1.subscription.domain)
    }

    return direction === 'asc'
      ? getDomain(sub1)?.localeCompare(getDomain(sub2))
      : getDomain(sub2)?.localeCompare(getDomain(sub1))
  }

  const emailComparator = (sub1, sub2) => {
    return direction === 'asc'
      ? sub1?.email?.localeCompare(sub2?.email)
      : sub2?.email?.localeCompare(sub1?.email)
  }

  const dateComparator = (sub1, sub2) => {
    return direction === 'asc'
      ? moment(sub1?.created_at) - moment(sub2?.created_at)
      : moment(sub2?.created_at) - moment(sub1?.created_at)
  }

  const sortedGroups = useCallback(
    (data = []) => {
      const res = [...data]
      res.map(subscription => {
        if (Array.isArray(subscription)) {
          if (order === 'domain') {
            return subscription?.sort(emailComparator)
          }
          return subscription?.sort(dateComparator)
        }
        return subscription
      })

      return res
    },
    [filteredData]
  )

  const groupSubscriptions = (data = []) => {
    const grouped = data.reduce((acc, val) => {
      if (!acc[val.domain]) {
        acc[val.domain] = []
      }
      acc[val.domain].push(val)
      return acc
    }, {})

    return Object.values(grouped).reduce((acc, val) => {
      if (val.length > 1) {
        acc.push(val)
        return acc
      }
      acc.push(val[0])
      return acc
    }, [])
  }

  const sortData = useMemo(() => {
    const data =
      type !== 'rolledup'
        ? sortedGroups(groupSubscriptions(filteredData))
        : [...filteredData]

    return data?.sort((sub1, sub2) => {
      switch (order) {
        case 'domain':
          return domainComparator(sub1, sub2)
        case 'created_at':
          return dateComparator(sub1, sub2)
      }
      return true
    })
  }, [order, direction, data, searchStr, filteredData])

  useEffect(() => {
    if (selectedSubscription) {
      const selectedIndex = sortData.findIndex(
        el => el.id === selectedSubscription.id
      )
      setHasPrevious(true)
      setHasNext(true)
      if (selectedIndex === 0) {
        setHasPrevious(false)
      }
      if (selectedIndex === sortData.length - 1) {
        setHasNext(false)
      }
    } else {
      setHasNext(false)
      setHasPrevious(false)
    }
  }, [selectedSubscription, sortData])

  const changeSelectedSubscription = subscription => {
    if (!subscription) {
      const subscriptions = type === 'rolledup' ? sortData : sortData.flat(1)

      const selectedIndex = subscriptions.findIndex(
        el => el.id === selectedSubscription.id
      )
      if (subscriptions.length === 1) {
        setSelectedSubscription(undefined)
        return
      }
      if (subscriptions.length === selectedIndex + 1) {
        setSelectedSubscription(subscriptions[selectedIndex - 1])
        return
      }
      setSelectedSubscription(subscriptions[selectedIndex + 1])
    } else {
      setSelectedSubscription(subscription)
    }
  }

  const selectNextSubscription = () => {
    const selectedIndex = sortData.findIndex(
      el => el.id === selectedSubscription.id
    )
    if (selectedIndex !== -1) {
      setSelectedSubscription(sortData[selectedIndex + 1])
    }
  }

  const selectPreviousSubscription = () => {
    const selectedIndex = sortData.findIndex(
      el => el.id === selectedSubscription.id
    )
    if (selectedIndex !== -1) {
      setSelectedSubscription(sortData[selectedIndex - 1])
    }
  }

  const value = {
    selectedSubscription,
    setSelectedSubscription,
    searchStr,
    setSearchStr,
    showLoadMore,
    order,
    setOrder,
    direction,
    setDirection,
    sortedData: sortData,
    data,
    isLoadingSubscriptions,
    isFetchingSubscriptions,
    changeSelectedSubscription,
    type,
    subtype,
    showFullPreview,
    setShowFullPreview,
    selectPreviousSubscription,
    selectNextSubscription,
    hasNext,
    hasPrevious
  }

  return (
    <SubscriptionsContext.Provider value={value}>
      {children}
    </SubscriptionsContext.Provider>
  )
}

export const useSubscriptions = () => {
  return useContext(SubscriptionsContext)
}
